import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import FuelIndex from '@/enums/FuelIndex'
import MeasurementMoment from '@/enums/MeasurementMoment'
import AdditionCalculationMethod from '@/enums/AdditionCalculationMethod'
import AdditionOption from '@/enums/AdditionOption'

export class GasOilAdditions extends Model {
	protected jsonApiType = 'gasOilAdditions'

	@Property()
	public indexName!: FuelIndex | null

	@Property()
	public measurementMoment!: MeasurementMoment | null

	@Property()
	public specificDate!: string | null

	@Property()
	public threshold!: number | null

	@Property()
	public stepSize!: number | null

	@Property()
	public amountPerStep!: number | null

	@Property()
	public additionCalculationMethod!: AdditionCalculationMethod | null

	@Property()
	public additionOption!: AdditionOption | null
}
