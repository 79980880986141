export type V3State = {
	currentPage: 'capacity-radar' | null
	initialized: boolean
}

export default {
	namespaced: true,
	state: () =>
		({
			currentPage: null,
			initialized: false
		}) as V3State,

	mutations: {
		setInitialized(state: V3State) {
			state.initialized = true
		},
		setCurrentPage(state: V3State, path) {
			state.currentPage = path
		}
	},
	getters: {
		getCurrentPage: (state: V3State) => () => {
			return state.currentPage
		},
		getInitialized: (state: V3State) => () => {
			return state.initialized
		}
	},
	actions: {}
}
