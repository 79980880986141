import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Addition extends Model {
	protected jsonApiType = 'additions'

	@Property()
	public additionType!: string

	@Property()
	public title!: string

	@Property()
	public sequence!: number

	public static async fetchAllLazy(): Promise<Array<Addition>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['additions/hasAllFetched']()) {
			await store.dispatch('additions/findAll')
		}

		return store.getters['additions/peekAll']()
	}

	public static async fetchLazy(id): Promise<Addition> {
		const store = (await import('@/store/index')).store
		if (!store.getters['additions/hasCached'](id)) {
			await store.dispatch('additions/findRecord', id)
		}

		return store.getters['additions/peekRecord'](id)
	}
}
