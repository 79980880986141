import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Vessel } from '@/models/Vessel'
import { GasOilBunkered } from '@/models/GasOilBunkered'
import moment from 'moment'
import { Transport } from '@/models/Transport'

export class GasOilUsage extends Model {
	protected jsonApiType = 'gasOilUsages'
	protected static pageSize = 25

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.relation('vessel')
	}

	private transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport {
		return this.getRelation('transport')
	}

	private gasOilBunkered(): ToOneRelation {
		return this.hasOne(GasOilBunkered, 'gasOilBunkered')
	}

	public getGasOilBunkered(): GasOilBunkered {
		return this.getRelation('gasOilBunkered')
	}

	public get formattedTimePeriod(): string {
		return (
			moment(this.startDateOfGasOilUsage).format('DD/MM/YYYY') +
			' - ' +
			moment(this.endDateOfGasOilUsage).format('DD/MM/YYYY')
		)
	}

	public get formattedWeek(): string {
		return this.weekOfGasOilUsage + ' (' + this.yearOfGasOilUsage + ')'
	}

	public get formattedStartDate(): string {
		return moment(this.startDateOfGasOilUsage).week().toString()
	}

	public get formattedEndDate(): string {
		return moment(this.endDateOfGasOilUsage).week().toString()
	}

	@Property()
	public mmsi!: string

	@Property()
	public gasOilLevelStart!: number

	@Property()
	public gasOilLevelEnd!: number

	@Property()
	public gasOilUsage!: number

	@Property()
	public permissionsToIncrementSpeed!: number

	@Property('weekNumber')
	public weekOfGasOilUsage!: number

	@Property('year')
	public yearOfGasOilUsage!: number

	@Property()
	public hasBunkerReceipt!: boolean

	@Property('gasOilUsageFromDate')
	public startDateOfGasOilUsage!: string

	@Property('gasOilUsageUntilDate')
	public endDateOfGasOilUsage!: string

	public get gasOilUsageIcon(): string {
		return 'gas-pump'
	}

	public get gasOilUsageColor(): string {
		return '#4D4E4F'
	}
}
