enum AttributeGroup {
	GENERAL = 'general',
	HOLD = 'hold',
	SUSTAINABILITY = 'sustainability',
	CERTIFICATE = 'certificate',
	UNGROUPED = 'ungrouped',
	PREFERENCES = 'preferences'
}

export default AttributeGroup
