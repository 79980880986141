import { Model } from '@/models/Model'
import { GeoJSON } from 'geojson'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'

export class TerminalSrsLocation extends Model implements MappableModel {
	protected jsonApiType = 'terminalSrsLocations'

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoLocation')
	}

	public getName(): string {
		return this.getAttribute('company')
	}

	public get hasTransferDetails(): boolean {
		return (
			this.canTransferContainer !== null || this.canTransferBulk !== null || this.canTransferPieceGoods !== null
		)
	}

	public get hasStoreDetails(): boolean {
		return this.canStoreContainer !== null || this.canStoreBulk !== null || this.canStorePieceGoods !== null
	}

	@Property('company')
	public name?: string

	@Property()
	public address?: string

	@Property()
	public place?: string

	@Property()
	public countryCode!: string

	@Property()
	public postalCode?: string

	@Property()
	public phoneNumbers?: Array<any>

	@Property()
	public primaryEmail?: string

	@Property()
	public website?: string

	@Property()
	public harbour?: string

	@Property()
	public harbourNumberFrom?: string

	@Property()
	public harbourNumberUntil?: string

	@Property()
	public locationCode?: string

	@Property()
	public canTransferContainer?: boolean

	@Property()
	public canStoreContainer?: boolean

	@Property()
	public canTransferBulk?: boolean

	@Property()
	public canStoreBulk?: boolean

	@Property()
	public canTransferPieceGoods?: boolean

	@Property()
	public canStorePieceGoods?: boolean

	@Property()
	public quayLength?: number
}
