import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import { state } from '@/store/modules/notifications/state'

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
