import { PhoneCountryCode } from '@/models/PhoneCountryCode'

export function formatPhoneNumber(phoneNumber?: string | null, countryCode?: PhoneCountryCode | null): string {
	let phone = phoneNumber
	if (countryCode && phone) {
		if (phone.startsWith('0')) {
			phone = phone.substring(1)
		}
		return countryCode.countryCode + ' ' + phone
	}
	return phone || ''
}
