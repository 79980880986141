enum AttributeType {
	TEXT = 'text',
	NUMBER = 'number',
	DATETIME = 'datetime',
	// We currently only support datetime in the backend. But currently we only require date inputs for attribute items.
	// We add a DATE type with the same value as DATETIME to make the distinction in the code clearer.
	DATE = 'datetime',
	LONGTEXT = 'longtext',
	DROPDOWN = 'dropdown',
	UPLOAD = 'upload'
}

export default AttributeType
