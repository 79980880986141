import GeoDirection from '@/directions/types/GeoDirection'
import { GeographicAreas } from '@/models/GeographicAreas'

type EmptyVesselFilterAttributes = {
	location?: GeoDirection
	geographicArea?: GeographicAreas
	radius?: number
	filterOnTonnage: boolean
	tonnage?: [number, number]
	filterOnLength: boolean
	length?: [number, number]
	filterOnWidth: boolean
	width?: [number, number]
	hasCapacity: boolean
}

export default {
	namespaced: true,
	state: () => ({
		filterUpdated: 0,
		location: null,
		geographicArea: null,
		radius: null,
		filterOnTonnage: false,
		tonnage: null,
		filterOnLength: false,
		length: null,
		filterOnWidth: false,
		width: null,
		historic: false,
		hasCapacity: true
	}),

	mutations: {
		setHistoric(state, historic: boolean | null) {
			state.historic = historic
		},
		setEmptyVesselReportsFilter(state, filters: EmptyVesselFilterAttributes) {
			Object.assign(state, filters)
			state.filterUpdated++
		},
		notifyEmptyReportCreated(state) {
			state.filterUpdated++
		}
	},
	getters: {},
	actions: {}
}
