import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class VesselLogInputCategory extends Model {
	protected jsonApiType = 'vesselLogInputCategories'

	@Property()
	public description!: string

	@Property()
	public slug!: string
}
