import moment from 'moment'
import { Model } from '@/models/Model'

export default (options?: string | { name?: string; date?: boolean }) => {
	return (target: Model, propertyName: string) => {
		let name = ''
		if (typeof options === 'object' && options.name) {
			name = options.name
		} else {
			name = options as string
		}

		const isDate = typeof options === 'object' && options.date === true

		if (!delete target[propertyName]) {
			// eslint-disable-next-line
			console.error(`${propertyName} not found on target ${target}`)
		}

		Object.defineProperty(target, propertyName, {
			get() {
				const property = this.get(name || propertyName)

				if (isDate) {
					return moment(property)
				}

				return property
			},
			set(newVal: any) {
				this.set(name || propertyName, newVal)
			},
			enumerable: true,
			configurable: true
		})
	}
}
