import { Model as ColoquentModel, HttpClient, PaginationStrategy } from 'coloquent'
import { SaveResponse } from 'coloquent/dist/response/SaveResponse'
import { Query } from 'coloquent/dist/Query'
import { Option } from 'coloquent/dist/Option'
import { PageBasedPaginationSpec } from 'coloquent/dist/paginationspec/PageBasedPaginationSpec'

export type ResourceLinkObject = {
	id: string
	type: string
}

export type MetaObject = {
	current_page: number
	last_page: number
	per_page: string
	to: number
}

export abstract class Model extends ColoquentModel {
	protected static paginationStrategy = PaginationStrategy.PageBased
	protected static pageSize = 9999999

	public getJsonApiBaseUrl(): string {
		return Model.baseUrl()
	}

	public getJsonApiType(): string {
		return this.jsonApiType
	}

	public getModelUrl(): string {
		return `${this.getJsonApiBaseUrl()}${this.getJsonApiType()}/${this.getApiId()}`
	}

	public toResourceLinkObject(): { type: string; id: string | undefined } {
		return {
			type: this.jsonApiType,
			id: this.getApiId()
		}
	}

	public static baseUrl(): string {
		const base = process.env.VUE_APP_API_URL!

		if (base && base.slice(-1) !== '/') {
			return base + '/'
		}

		return base
	}

	public static hasNextPage(meta: MetaObject): boolean {
		return meta.current_page * parseInt(meta.per_page) === meta.to
	}

	public set(attribute: string, value: any): void {
		this.setAttribute(attribute, value)
	}

	public get(attribute: string): any {
		return this.getAttribute(attribute)
	}

	public relation(relation: string): any {
		return super.getRelation(relation)
	}

	public async doCallbackWithLanguage(
		callback: (httpClient: HttpClient) => any,
		AcceptLanguage: string | null = null
	): Promise<any> {
		const httpClient = Model.getHttpClient().getImplementingClient()
		const oldAcceptLanguage = httpClient.defaults.headers['Accept-Language']

		if (AcceptLanguage) {
			httpClient.defaults.headers['Accept-Language'] = AcceptLanguage
		}

		const result = await callback(httpClient)

		httpClient.defaults.headers['Accept-Language'] = oldAcceptLanguage

		return result
	}

	// override fresh method as the default method passes an include parameter to the backend with the relationships
	fresh(): Promise<this | null | undefined> {
		// @ts-ignore
		const model = new this.constructor()
		const builder = model.query()
		if (this.getApiId()) {
			return builder.find(this.getApiId()).then(
				(response) => {
					return response.getData()
				},
				(response) => {
					throw response
				}
			)
		} else {
			return Promise.resolve(undefined)
		}
	}

	// copy coloquent method, but adding the query params
	async saveWithQuery(params: Array<Option>): Promise<SaveResponse<this>> {
		// @ts-ignore
		if (!this.hasId) {
			return this.create()
		}

		const query = new Query(this.getJsonApiType(), undefined, this.getApiId())
		query.setIdToFind(this.getApiId()!)
		query.setPaginationSpec(new PageBasedPaginationSpec('page[number]', 'page[size]', 1))
		params.forEach((p) => query.addOption(p))

		// @ts-ignore
		const payload = this.serialize()
		const response = await Model.getHttpClient().getImplementingClient().patch(query.toString(), payload)
		const idFromJson = response.data.data.id
		this.setApiId(idFromJson)
		return new SaveResponse(response, Object.getPrototypeOf(this).constructor, response.data)
	}
}
