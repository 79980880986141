import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Sends extends Model {
	protected jsonApiType = 'sends'
	protected static pageSize = 10

	@Property()
	public uuid!: string | null

	@Property()
	public mailableName!: string | null

	@Property()
	public subject!: string | null

	@Property()
	public from!: string

	@Property()
	public to!: string

	@Property()
	public replyTo!: string

	@Property()
	public cc!: string

	@Property()
	public bcc!: string

	@Property()
	public sentAt!: string

	@Property()
	public attachments!: string
}
