import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ManifestTransportOrder } from '@/models/ManifestTransportOrder'

export class InterModalManifest extends Model {
	protected jsonApiType: string = 'interModalManifests'

	public manifestTransportOrders(): ToManyRelation {
		return this.hasMany(ManifestTransportOrder, 'manifestTransportOrders')
	}

	public getManifestTransportOrders(): Array<ManifestTransportOrder> {
		return this.getRelation('manifestTransportOrders') || []
	}

	@Property()
	public serviceName!: string

	@Property()
	public serviceInstance!: string

	@Property()
	public updateType!: string
}
