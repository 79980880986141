import { MessagesState } from '@/interfaces/State/MessagesState'
import { Message } from '@/models/Message'
import Vue from 'vue'
import { ResourceLinkObject } from '@/models/Model'

export const setAndCalculateNewMessages = (
	state: MessagesState,
	conversations: Array<{ amountUnreadMessages; data: ResourceLinkObject }>
): void => {
	state.newCount = (conversations || []).reduce((acc, conversation) => acc + conversation.amountUnreadMessages, 0)
}

export const setAndCalculateNewOffersMessages = (
	state: MessagesState,
	conversations: Array<{ amountUnreadMessages; data: ResourceLinkObject }>
): void => {
	state.newOffersMessagesCount = (conversations || []).reduce(
		(acc, conversation) => acc + conversation.amountUnreadMessages,
		0
	)
}

export const setAndCalculateNewTransportsMessages = (
	state: MessagesState,
	conversations: Array<{ amountUnreadMessages; data: ResourceLinkObject }>
): void => {
	state.newTransportsMessagesCount = (conversations || []).reduce(
		(acc, conversation) => acc + conversation.amountUnreadMessages,
		0
	)
}

export const setNewFleetInvitations = (state: MessagesState, newMessages: number): void => {
	state.newFleetInvitationsCount = newMessages
}

export const cacheMessage = (state: MessagesState, message: Message): void => {
	const foundIndex = state.messages.findIndex(
		(cached) => cached === message || cached.getApiId() === message.getApiId()
	)
	if (foundIndex > -1) {
		Vue.set(state.messages, foundIndex, message)
	} else {
		state.messages.push(message)
	}
}

export const cacheMessages = (state: MessagesState, messages: Array<Message>): void => {
	messages.forEach((message) => cacheMessage(state, message))
}
