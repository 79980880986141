import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

Vue.use(Vuex)

export const createStore = () =>
	new Vuex.Store({
		modules,
		plugins: [
			createPersistedState({
				paths: [
					'auth.vessels',
					'auth.permissions',
					'auth.emptyVessel',
					'auth.queuedEmptyVessel',
					'auth.memberships',
					'messages.newCount',
					'messages.newTransportsMessagesCount',
					'messages.newOffersMessagesCount'
				]
			})
		]
	})
export const store = createStore()
