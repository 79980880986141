import nl from './nl'
import en from './en'
import { LocaleMessages } from 'vue-i18n'

function parseMessage(obj: any): any {
	if (typeof obj === 'string') {
		return obj
	}

	const keys = Object.keys(obj)
	if (keys.length === 2 && keys.indexOf('context') !== -1 && keys.indexOf('value') !== -1) {
		return obj.value
	}

	keys.forEach((key) => {
		obj[key] = parseMessage(obj[key])
	})

	return obj
}

export default <LocaleMessages>{
	nl: parseMessage(nl),
	en: parseMessage(en)
}
