import { Model } from '@/models/Model'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { Port } from '@/models/Port'
import { AisVessel } from '@/models/AisVessel'

export class PortArea extends Model implements MappableModel {
	protected jsonApiType = 'portAreas'

	public getLocation(): GeoJSON.Geometry {
		return this.geometry
	}

	public getName(): string {
		return this.name
	}

	public port(): ToOneRelation {
		return this.hasOne(Port, 'port')
	}

	public getPort(): Port {
		return this.getRelation('port')
	}

	private aisVessels(): ToManyRelation {
		return this.hasMany(AisVessel, 'aisVessels')
	}

	public getAisVessels(): Array<AisVessel> {
		return this.relation('aisVessels') || []
	}

	@Property()
	public name!: string

	@Property()
	public geometry!: GeoJSON.Geometry
}
