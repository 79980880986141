import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class TransferTime extends Model {
	protected jsonApiType = 'transferTimes'

	@Property()
	public title!: string

	@Property()
	public shortTitle!: string

	@Property()
	public transferTime!: string

	@Property()
	public sequence!: number

	public static async fetchAllLazy(): Promise<Array<TransferTime>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transferTimes/hasAllFetched']()) {
			await store.dispatch('transferTimes/findAll')
		}

		return store.getters['transferTimes/peekAll']()
	}

	public static async fetchLazy(id): Promise<TransferTime> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transferTimes/hasCached'](id)) {
			await store.dispatch('transferTimes/findRecord', id)
		}

		return store.getters['transferTimes/peekRecord'](id)
	}
}
