import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Offer } from '@/models/Offer'
import { Message } from '@/models/Message'
import PriceUnit from '@/enums/PriceUnit'
import User from '@/models/User'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { BidAdditionalInfo } from '@/models/BidAdditionalInfo'
import { Vessel } from '@/models/Vessel'
import { ConversationContext } from '@/models/ConversationContext'
import { Conversation } from '@/models/Conversation'
import { Company } from '@/models/Company'

export class Bid extends Model {
	protected jsonApiType = 'bids'

	@Property()
	public bidByLogisticServiceProvider?: boolean

	@Property()
	public price!: number

	@Property()
	public priceUnit!: PriceUnit

	@Property()
	public comment!: string

	@Property()
	public userFullName!: string

	@Property()
	public companyName!: string

	@Property()
	public bidFullName!: string

	@Property()
	public vesselName!: string

	@Property()
	public vesselDimensionsInMeters!: number

	@Property('tonnage')
	public vesselTonnage!: number

	@Property()
	public dateTimeOfBid!: string

	@Property()
	public createdAt!: string

	@Property()
	public message!: string

	@Property()
	public newBidRelatedMessages!: number

	@Property()
	public skipperName?: string

	private offer(): ToOneRelation {
		return this.hasOne(Offer, 'offers')
	}

	public getOffer(): Offer {
		return this.getRelation('offers')
	}

	public setOffer(value: any): void {
		this.setRelation('offers', value)
	}

	private bidAdditionalInfo(): ToOneRelation {
		return this.hasOne(BidAdditionalInfo, 'bidAdditionalInfo')
	}

	public getBidAdditionalInfo(): BidAdditionalInfo {
		return this.getRelation('bidAdditionalInfo')
	}

	private user(): ToOneRelation {
		return this.hasOne(User, 'user')
	}

	public getUser(): User {
		return this.getRelation('user')
	}

	public messages(): ToManyRelation {
		return this.hasMany(Message, 'messages')
	}

	public vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.getRelation('vessel')
	}

	public unregisteredVessel(): ToOneRelation {
		return this.hasOne(Vessel, 'unregisteredVessel')
	}

	public getUnregisteredVessel(): Vessel {
		return this.getRelation('unregisteredVessel')
	}

	public viaBroker(): ToOneRelation {
		return this.hasOne(Company, 'viaBroker')
	}

	public getViaBroker(): Company | null {
		return this.getRelation('viaBroker')
	}

	public conversationContext(): ToOneRelation {
		return this.hasOne(ConversationContext, 'conversationContext')
	}

	public getConversationContext(): ConversationContext {
		return this.getRelation('conversationContext')
	}

	public conversations(): ToManyRelation {
		return this.hasMany(Conversation, 'conversations')
	}

	public getConversations(): Array<Conversation> {
		return this.getRelation('conversations') || []
	}
}
