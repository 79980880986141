import { Document } from '@/models/Document'
import { Vessel } from '@/models/Vessel'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'

export default {
	namespaced: true,
	state: () => ({
		vessel: null,
		documents: []
	}),

	mutations: {
		setVessel(state, vessel: Vessel) {
			state.documents = []
			state.vessel = vessel
		},
		setDocuments(state, documents: Array<Document>) {
			state.documents = documents
		},
		removeDocument(state, document: Document) {
			state.documents = state.documents.filter((doc) => doc !== document)
		},
		clear: (state) => () => {
			state.documents = []
			state.vessel = null
		}
	},
	getters: {
		getVessel: (state) => () => {
			return state.vessel
		},
		getDocuments: (state) => () => {
			return state.documents
		}
	},
	actions: {
		async fetchDocuments({ commit, getters }) {
			const vessel = getters.getVessel()
			if (!vessel) return
			const documents = ((await vessel.documents().get()) as PluralResponse)
				.getData()
				.reverse() as Array<Document>
			commit('setDocuments', documents)
		}
	}
}
