import { GeoJSON } from 'geojson'
import { Model } from '@/models/Model'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'

export class BunkerStation extends Model implements MappableModel {
	protected jsonApiType = 'bunkerStations'

	public getLocation(): GeoJSON.Geometry {
		return this.geoLocation
	}

	public getName(): string {
		return this.name
	}

	@Property()
	public geoLocation!: GeoJSON.Geometry

	@Property()
	public name!: string

	@Property()
	public place?: string

	@Property()
	public widthPosition?: string

	@Property()
	public hasBunkerBoat?: boolean
}
