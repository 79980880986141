import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import User from '@/models/User'
import Property from '@/decorators/Property'
import { Conversation } from '@/models/Conversation'

export class ConversationParticipant extends Model {
	protected jsonApiType = 'conversationParticipants'
	protected static pageSize = 25

	@Property()
	public lastReadAt!: string

	@Property()
	public joinedAt!: string

	@Property()
	public leftAt!: string

	public participant(): ToOneRelation {
		return this.hasOne(User, 'participant')
	}

	public getParticipant(): User {
		return this.getRelation('participant')
	}

	public setParticipant(user: User) {
		this.setRelation('participant', user)
	}

	public conversation(): ToOneRelation {
		return this.hasOne(Conversation, 'conversation')
	}

	public getConversation(): Conversation {
		return this.getRelation('conversation')
	}

	public setConversation(conversation: Conversation) {
		this.setRelation('conversation', conversation)
	}
}
