import { Logger } from 'cloudwatch-front-logger'
import Auth from '@aws-amplify/auth'
import StackTrace from 'stacktrace-js'
import moment from 'moment'

export default function (config) {
	if (!config.VUE_APP_AWS_CLOUDFRONT_KEY) return
	const logger = new Logger(
		config.VUE_APP_AWS_CLOUDFRONT_KEY!,
		config.VUE_APP_AWS_CLOUDFRONT_SECRET!,
		config.VUE_APP_AWS_CLOUDFRONT_REGION!,
		config.VUE_APP_AWS_CLOUDFRONT_LOG_GROUP!
	)
	// remove old value for logStreamName
	localStorage.removeItem('CloudWatchFrontLogger:logStreamName')
	logger.install({
		async messageFormatter(e, info = { type: 'unknown' }) {
			if (!e.message) {
				return null
			}
			let stack = null
			if (e.stack) {
				// @ts-ignore
				stack = e.stack
				try {
					// @ts-ignore
					stack = await StackTrace.fromError(e, { offline: true })
				} catch (_) {}
			}
			try {
				const data = await Auth.currentAuthenticatedUser()
				if (data && data.signInUserSession) {
					info.user = data.username
				}
			} catch (e) {
				if (e !== 'The user is not authenticated') {
					throw e
				}
			}
			if (process.env.BITBUCKET_BUILD_NUMBER) {
				info.build = process.env.BITBUCKET_BUILD_NUMBER
			}
			return JSON.stringify({
				...info,
				message: e.message,
				timestamp: new Date().toISOString(),
				userAgent: window.navigator.userAgent,
				stack
			})
		},
		async logStreamNameResolver() {
			return Promise.resolve('anonymous-' + moment().format('YYYY-MM-DD'))
		}
	})
	return logger
}
