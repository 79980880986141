import AuthState from '@/interfaces/State/AuthState'

export const state: AuthState = {
	initialized: false,
	user: {
		id: '',
		email: '',
		companyName: '',
		role: '',
		firstName: '',
		lastName: '',
		active: false,
		spoofed: false,
		localeFirstDayOfWeek: '0'
	},
	vessels: [],
	companies: [],
	permissions: [],
	emptyVessel: null,
	queuedEmptyVessel: null,
	financialConnection: null,
	memberships: [],
}
