import { Model } from '@/models/Model'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { Company } from '@/models/Company'
import { PortArea } from '@/models/PortArea'
import { AisVessel } from './AisVessel'

export class Port extends Model implements MappableModel {
	protected jsonApiType = 'ports'

	public getLocation(): GeoJSON.Geometry {
		return this.geometry
	}

	public getName(): string {
		return this.name
	}

	private company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company {
		return this.getRelation('company')
	}

	private portAreas(): ToManyRelation {
		return this.hasMany(PortArea, 'portAreas')
	}

	public getPortAreas(): Array<PortArea> {
		return this.relation('portAreas') || []
	}

	private aisVessels(): ToManyRelation {
		return this.hasMany(AisVessel, 'aisVessels')
	}

	public getAisVessels(): Array<AisVessel> {
		return this.relation('aisVessels') || []
	}

	@Property()
	public name!: string

	@Property()
	public isrs!: number

	@Property()
	public geometry!: GeoJSON.Geometry

	@Property()
	public address!: string

	@Property()
	public postalCode!: string

	@Property()
	public place!: string

	@Property()
	public logoUrl!: string

	@Property()
	public websiteUrl!: string

	@Property()
	public harbourDuesUrl!: string

	@Property()
	public harbourMasterPhoneNumber!: string

	@Property()
	public facilities!: string
}
