
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const Messages = namespace('messages')
const Viewport = namespace('viewport')

@Component
export default class NewMessagesBadge extends Vue {
	@Messages.State('newCount')
	allNewMessagesCount

	@Messages.State('newTransportsMessagesCount')
	newTransportsMessages

	@Messages.State('newOffersMessagesCount')
	newOffersMessages

	@Messages.State('newFleetInvitationsCount')
	newFleetInvitations

	@Viewport.Getter('isMobile')
	isMobile!: boolean

	@Prop({ default: '' })
	navigationItemSlug!: string

	@Prop({ default: 0 })
	newMessagesAmount!: number | null

	@Prop({ default: 'alertNavBar' })
	displayStyle!: string

	newMessages: number = 0

	async created() {
		this.setNewMessages()
	}

	setNewMessages() {
		if (this.navigationItemSlug === 'message') {
			this.newMessages = this.allNewMessagesCount
		} else if (this.navigationItemSlug === 'cargo') {
			this.newMessages = this.newOffersMessages
		} else if (this.navigationItemSlug === 'transport') {
			this.newMessages = this.newTransportsMessages
		} else if (this.navigationItemSlug === 'group') {
			this.newMessages = this.newFleetInvitations
		}

		if (!this.navigationItemSlug) {
			this.newMessages = this.newMessagesAmount ?? 0
		}
	}

	showBadgeAtNavigationItem() {
		return ['message', 'cargo', 'transport', 'group'].includes(this.navigationItemSlug)
	}

	@Watch('allNewMessagesCount')
	syncAllMessages() {
		this.setNewMessages()
	}

	@Watch('newOffersMessages')
	syncOfferMessages() {
		this.setNewMessages()
	}

	@Watch('newTransportsMessages')
	syncTransportMessages() {
		this.setNewMessages()
	}

	@Watch('newFleetInvitations')
	syncFleetInvitations() {
		this.setNewMessages()
	}
}
