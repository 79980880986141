import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Country extends Model {
	protected jsonApiType = 'countries'

	@Property()
	public name!: string

	@Property()
	public iso!: string

	public static async fetchAllLazy(): Promise<Array<Country>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['countries/hasAllFetched']()) {
			await store.dispatch('countries/findAll')
		}

		return store.getters['countries/peekAll']()
	}

	public static async fetchLazy(id): Promise<Country> {
		const store = (await import('@/store/index')).store
		if (!store.getters['countries/hasCached'](id)) {
			await store.dispatch('countries/findRecord', id)
		}

		return store.getters['countries/peekRecord'](id)
	}
}
