import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { Vessel } from '@/models/Vessel'
import { Company } from '@/models/Company'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'

export class FavouriteGroupMember extends Model {
	protected jsonApiType: string = 'favouriteGroupMembers'

	@Property()
	public tonnage!: number | undefined

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel | null {
		return this.relation('vessel')
	}

	private company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company | null {
		return this.relation('company') || null
	}
}
