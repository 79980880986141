import { FleetMember } from '@/models/FleetMember'
import Vue from 'vue'
import { Vessel } from '@/models/Vessel'
import User from '@/models/User'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'

export default {
	namespaced: true,
	state: () => ({
		allFleetMembers: new Map(),
		allVesselsChangeTracker: 1,
		allVessels: new Map(),
		user: null,
		vesselId: null,
		fleetMemberId: null,
		allSelected: false,
		originTerminalIds: null,
		destinationTerminalIds: null,
		vesselGroupId: null,
		openFilterDialog: false,
		filePath: null
	}),

	mutations: {
		cacheFleetMembers(state, fleetMembers: Array<FleetMember>) {
			fleetMembers.forEach((member) => {
				state.allFleetMembers.set(member.getApiId(), member)
			})
			Vue.set(state, 'fleetMembers', state.fleetMembers)
		},
		cacheVessels(state, vessels: Array<Vessel>) {
			vessels.forEach((member) => {
				state.allVessels.set(member.getApiId(), member)
			})
			state.allVesselsChangeTracker++ // this is needed because Vue doesn't support reactivity on a Map
		},
		setVesselId(state, vesselId: string) {
			state.vesselId = vesselId
		},
		setFleetMemberId(state, { fleetMemberId }: { fleetMemberId: string }) {
			state.fleetMemberId = fleetMemberId
		},
		setOpenFilterDialog(state, openFilterDialog: boolean) {
			state.openFilterDialog = openFilterDialog
		},
		setFilePath(state, filePath: string) {
			state.filePath = filePath
		},
		setUser(state, user) {
			state.user = user
		},
		fleetMemberSelected(state, queryParameters) {
			state.vesselId = queryParameters.fleetMember.getVessel().getApiId() as string
			state.fleetMemberId = queryParameters.fleetMember.getApiId() as string
			state.originTerminalIds = queryParameters.originFacilities.map((facility) => facility.facilityId).join()
			state.destinationTerminalIds = queryParameters.destinationFacilities
				.map((facility) => facility.facilityId)
				.join()
            state.vesselGroupId = queryParameters.vesselGroupId
			state.allSelected = false
			state.openFilterDialog = false
		}
	},
	getters: {
		getAllVessels: (state) => state.allVesselsChangeTracker && state.allVessels.values(),
		getVesselId: (state): string => state.vesselId,
		getFleetMemberId: (state): string => state.fleetMemberId,
		getAllSelected: (state): boolean => state.allSelected,
		getOriginTerminalIds: (state): string => state.originTerminalIds,
		getDestinationTerminalIds: (state): string => state.destinationTerminalIds,
        getVesselGroupId: (state): string => state.vesselGroupId,
		getOpenFilterDialog: (state): boolean => state.openFilterDialog,
		getFilePath: (state): string => state.filePath,
		getUser: (state): User => state.user,
		getFleetMember:
			(state) =>
			(id): FleetMember =>
				state.allFleetMembers.get(id),
		getVessel:
			(state) =>
			(id): Vessel =>
				state.allVessels.get(id)
	},
	actions: {
		async allMembersSelected({ commit, state }, queryParameters) {
			if (!state.user) {
				return
			}
			const fleetMembers = (
				(await state.user.insightsFleetMembers().get()) as PluralResponse
			).getData() as Array<FleetMember>
			commit('cacheFleetMembers', fleetMembers)
			commit(
				'cacheVessels',
				fleetMembers.map((member) => member.getVessel())
			)
			state.vesselId = fleetMembers.map((fleetMember) => fleetMember.getVessel().getApiId()).join()
			state.fleetMemberId = fleetMembers.map((fleetMember) => fleetMember.getApiId()).join()
			state.originTerminalIds = queryParameters.originFacilities.map((facility) => facility.facilityId).join()
			state.destinationTerminalIds = queryParameters.destinationFacilities
				.map((facility) => facility.facilityId)
				.join()
            state.vesselGroupId = queryParameters.vesselGroupId
			state.allSelected = true
			state.openFilterDialog = false
		}
	}
}
