import { ActionContext } from 'vuex'
import { RootState } from '@/store/RootState'
import NotificationState from '@/interfaces/State/NotificationState'
import Notification from '@/interfaces/Notification'

export const add = (context: ActionContext<NotificationState, RootState>, notification: Notification): void => {
	if (context.getters.withMessage(notification.message).length > 0) {
		return
	}

	context.commit('push', {
		// @ts-ignore
		id: context.getters.getNextId,
		// @ts-ignore
		timeout: 6000,
		...notification
	})
}

export const remove = (context: ActionContext<NotificationState, RootState>, notification: Notification): void => {
	context.commit('remove', notification)
}

export const toggleNotification = (context: ActionContext<NotificationState, RootState>, show: boolean): void => {
	context.commit('setNotificationShown', show)
}
