
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Testable from '@/mixins/Testable'
import { Transport } from '@/models/Transport'
import { TerminalVisit } from '@/models/TerminalVisit'
import { BillOfLading } from '@/models/BillOfLading'
import { namespace } from 'vuex-class'
import VesselContactCard from '@/components/Profile/VesselContactCard.vue'

const Viewport = namespace('viewport')

@Component({
	components: { VesselContactCard }
})
export default class V3Page extends Mixins(Testable) {
	@Viewport.Getter('isMobile')
	isMobile

	@Prop({ required: true })
	page!: string

	msgEventListener!: (evt: MessageEvent) => void

	initialPage: string = ''
	lastVisitedUrl: string = ''
	transportAndVisitLoading = false
	openBillOfLadingGenerator: null | {
		transport: Transport | null
		terminalVisit: TerminalVisit
		onSubmit: (bill: BillOfLading) => void
	} = null
	vesselId: null | string = null

	billOfLadingType: 'loading-bill' | 'unloading-bill' = 'unloading-bill'

	created() {
		this.initialPage = this.page
	}

	get localStorageKey() {
		return this.page + '_last_url'
	}

	get baseUrl(): string {
		return String(process.env.VUE_APP_TERMINAL_MODULE_URL).replace(/.*(\/)$/gm, '')
	}

	get spaURL(): string {
		if (this.lastVisitedUrl && this.lastVisitedUrl.startsWith(this.page)) return this.lastVisitedUrl
		return this.page
	}

	get iframeElement(): HTMLIFrameElement | null {
		return (this.$refs.iframe as HTMLIFrameElement) ?? null
	}
	get isVesselContactCardOpen(): boolean {
		return !!this.vesselId
	}

	handleRouteChanged(url: string) {
		if (url.startsWith(this.page)) window.localStorage.setItem(this.localStorageKey, url)
	}

	beforeCreate(): void {
		this.msgEventListener = (event: MessageEvent): void => {
			const data = String(event.data)

			if (!data.startsWith('xframe:')) {
				return
			}

			if (data.startsWith('xframe:fire:')) {
				const eventAndArgs = data.replace('xframe:fire:', '').split(':')
				const event = eventAndArgs[0]
				const args = eventAndArgs.slice(1).join(':')

				return this.handleEvent(event, JSON.parse(args))
			}

			if (data.startsWith('xframe:call:request:')) {
				const eventUuidAndArgs = data.replace('xframe:call:request:', '').split(':')
				const event = eventUuidAndArgs[0]
				const uuid = eventUuidAndArgs[1]

				const args = eventUuidAndArgs.slice(2).join(':')

				this.handleCall(event, JSON.parse(args), (response) => {
					const terminalIframeOrigin = this.spaURL.startsWith('http')
						? new URL(this.spaURL).origin
						: window.location.origin

					this.iframeElement?.contentWindow?.postMessage(
						`xframe:call:response:${event}:${uuid}:${JSON.stringify(response || null)}`,
						terminalIframeOrigin
					)
				})

				return
			}

			throw new Error(`Could not handle ${event.data}`)
		}

		window.addEventListener('message', this.msgEventListener)
	}

	handleEvent(event: string, args: any): void {
		switch (event) {
			case 'navigate':
				this.$router.push(args)
				break
			case 'routeChanged':
				this.handleRouteChanged(args)
				break
			default:
				throw new Error(`Unknown event ${event}`)
		}
	}

	async handleCall(event: string, args: any, cb: (response?: any) => void): Promise<any> {
		switch (event) {
			case 'show-loading-bill-modal':
				await this.handleLoadingBillModal(args, cb)
				break
			case 'show-vessel-contact-card-modal':
				await this.handleVesselContactCardModal(args, cb)
				break
			default:
				throw new Error(`Unknown event ${event}`)
		}
	}

	beforeDestroy(): void {
		window.removeEventListener('message', this.msgEventListener)
	}

	async navigateTo(page: string) {
		await this.$nextTick()
		this.lastVisitedUrl = window.localStorage.getItem(this.localStorageKey) ?? page
		this.iframeElement?.contentWindow?.postMessage(`navigate:${this.lastVisitedUrl}`, '*')
	}

	async handleLoadingBillModal(
		args: { transportId: string; terminalVisitId: string },
		callback: (response?: any) => void
	): Promise<void> {
		this.transportAndVisitLoading = true
		const transportQuery = args.transportId ? Transport.find(args.transportId) : Promise.resolve(null as any)
		const terminalVisitQuery = TerminalVisit.with([
			'product',
			'vessel',
			'terminal',
			'extractionArea',
			'vessel.company'
		]).find(args.terminalVisitId)

		const transport = (await transportQuery)?.getData() ?? null
		const terminalVisit = (await terminalVisitQuery).getData()

		this.billOfLadingType = terminalVisit?.transshipmentDirection === 'loading' ? 'loading-bill' : 'unloading-bill'
		this.transportAndVisitLoading = false
		if (!terminalVisit) {
			return
		}

		this.openBillOfLadingGenerator = {
			transport,
			terminalVisit,
			onSubmit: () => {
				callback()

				this.openBillOfLadingGenerator = null
			}
		}
	}

	async handleVesselContactCardModal(args: { vesselId: string }, callback: (response?: any) => void): Promise<void> {
		this.vesselId = args.vesselId
		callback()
	}
}
