
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
	@Prop({ required: true })
	readonly name!: string

	@Prop({ default: null })
	readonly title!: string

	@Prop({ default: false, required: false })
	small!: boolean

	get iconPath() {
		return require(`@/assets/icons/${this.name}.svg`).default?.url
	}

	get className() {
		return 'svg-icon svg-icon--' + this.name
	}
}
