import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export enum PercentageProducts {
	COMMISSION = 'commission'
}

export class Product extends Model {
	protected jsonApiType = 'accounting/products'
	protected static pageSize = 100

	@Property()
	public code!: number

	@Property()
	public description!: string

	@Property()
	public slug!: string

	get display() {
		return this.description + (this.code ? ` ${this.code}` : '')
	}

	get isPercentage() {
		return [PercentageProducts.COMMISSION as string].includes(this.slug)
	}

	public static async fetchAllLazy(): Promise<Array<Product>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['accountingProducts/hasAllFetched']()) {
			await store.dispatch('accountingProducts/findAll')
		}

		return store.getters['accountingProducts/peekAll']()
	}
}
