
import { Component, Prop, Vue } from 'vue-property-decorator'
import User from '@/models/User'
import { namespace } from 'vuex-class'
import Notification from '@/classes/Notification'
import i18n from '@/i18n'
import { AdminContactRequest } from '@/models/AdminContactRequest'
import AdminContactRequestType from '@/enums/AdminContactRequestType'
import DOMPurify from 'dompurify'

const Auth = namespace('auth')

@Component({
	computed: {
		AdminContactRequestType() {
			return AdminContactRequestType
		}
	}
})
export default class ContactDialog extends Vue {
	@Prop({
		type: Boolean,
		required: true,
		default: false
	})
	opened!: boolean

	@Prop({ default: AdminContactRequestType.IMPROVEMENT })
	defaultRequestType!: AdminContactRequestType

	@Prop({ default: false })
	dense!: boolean

	loading: boolean = false
	selectedRequestType: AdminContactRequestType = AdminContactRequestType.IMPROVEMENT

	@Auth.State('user')
	user!: any

	comment: string = ''

	created() {
		this.selectedRequestType = this.defaultRequestType
	}

	async onSave() {
		const valid = await this.$validator.validateAll()
		if (!valid) {
			return
		}

		this.loading = true

		const user = new User()
		user.setApiId(this.user.id)

		const adminContactRequest = new AdminContactRequest()
		adminContactRequest.comment = DOMPurify.sanitize(this.comment)
		adminContactRequest.requestType = this.selectedRequestType
		adminContactRequest.activeUrl = this.$router.currentRoute.fullPath

		try {
			await adminContactRequest.save()

			await Notification.Success(i18n.t('contact.success').toString())

			this.$emit('closing')
		} catch (e) {
			// eslint-disable-next-line
			console.error(e)
			await Notification.Error(i18n.t('contact.error').toString())
		} finally {
			this.loading = false
		}
	}
}
