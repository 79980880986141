import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { Store } from 'vuex'

export class VatCode extends Model {
	protected jsonApiType = 'accounting/vatCodes'

	@Property()
	public code!: number

	@Property()
	public description!: string

	@Property()
	public percentage!: number

	get display() {
		return `${this.percentage}% (${this.description})`
	}

	public static async fetchAllLazy(store?: Store<any>): Promise<Array<VatCode>> {
		if (!store) {
			store = (await import('@/store/index')).store
		}
		if (!store.getters['accountingVatCodes/hasAllFetched']()) {
			await store.dispatch('accountingVatCodes/findAll')
		}

		return store.getters['accountingVatCodes/peekAll']()
	}

	public static async fetchLazy(id): Promise<VatCode> {
		const store = (await import('@/store/index')).store
		if (!store.getters['accountingVatCodes/hasCached'](id)) {
			await store.dispatch('accountingVatCodes/findRecord', id)
		}

		return store.getters['accountingVatCodes/peekRecord'](id)
	}
}
