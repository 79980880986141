import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import User from '@/models/User'
import { Document } from '@/models/Document'
import Property from '@/decorators/Property'
import { Conversation } from '@/models/Conversation'

export class Message extends Model {
	protected jsonApiType = 'messages'
	protected static pageSize = 25

	public sentBy(): ToOneRelation {
		return this.hasOne(User, 'sentBy')
	}

	public getSentBy(): User {
		return this.getRelation('sentBy')
	}

	public setSentBy(user: User): void {
		this.setRelation('sentBy', user)
	}

	public document(): ToOneRelation {
		return this.hasOne(Document, 'document')
	}

	public getDocument(): Document {
		return this.getRelation('document')
	}

	public conversation(): ToOneRelation {
		return this.hasOne(Conversation, 'conversation')
	}

	public getConversation(): Conversation {
		return this.getRelation('conversation')
	}

	public setConversation(conversation: Conversation): void {
		this.setRelation('conversation', conversation)
	}

	@Property()
	public message!: string

	@Property()
	public sentAt!: string

	@Property('type')
	public messageType!: string

	public isLoading: boolean = false
}
