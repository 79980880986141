export default {
	validate: (value: string) => {
		if (value === null || value === undefined || value === '') {
			return { valid: false }
		}

		const expressionNoLeadingZero = '^[1-9]{1,1}\\d{6,6}$' // If someone enters 7 digits he forgets the leading 0
		const expressionWithLeadingZero = '^0[1-9]{1,1}\\d{6,6}$'

		const regex = new RegExp(`${expressionWithLeadingZero}|${expressionNoLeadingZero}`)

		return { valid: regex.test(value) }
	},
	getMessage: (field) => 'The ' + field + ' value is not a valid eni number.'
}
