import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import TransportStatus from '@/enums/TransportStatus'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'

export class TransportStatuses extends Model {
	protected jsonApiType = 'transportStatuses'

	@Property()
	public unloadingBillAttached!: boolean

	@Property()
	public externalInvoiceAttached!: boolean

	@Property()
	public trackTraceSent!: boolean

	@Property()
	public status!: TransportStatus | null

	public transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport {
		return this.getRelation('transport')
	}
}
