import NotificationState from '@/interfaces/State/NotificationState'

export const getNextId = (state: NotificationState): number => {
	const ids: Array<number> = state.notifications.map((notification) => notification.id)

	return ids.length > 0 ? Math.max(...ids) + 1 : 1
}

export const withMessage = (state: NotificationState): object => {
	return (message: string) => state.notifications.filter((notification) => notification.message === message)
}

export const isSpoofingNotificationShown = (state: NotificationState): boolean => {
	return state.showSpoofing
}
