import axios from 'axios'
import { Model } from '@/models/Model'

export default class Translation {
	public static async all(locale: string): Promise<object> {
		const translationResponse = await axios.get(`${Model.baseUrl()}translations.json`, {
			headers: {
				'Accept-Language': locale
			}
		})

		return translationResponse.data
	}
}
