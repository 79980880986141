import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class PortBaseVisit extends Model {
	protected jsonApiType: string = 'portBaseVisits'
	protected static pageSize = 10

	// only attributes which are currently used are implemented

	@Property()
	public portBaseVisitId!: string

	@Property()
	public portbaseTerminal!: string
}
