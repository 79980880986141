import * as mutations from './mutations'
import * as getters from './getters'
import { state } from './state'

export default {
	namespaced: true,
	state,
	mutations,
	getters
}
