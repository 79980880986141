import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import l18n from '@/i18n'

import positive from './customvalidators/positive'
import eni from './customvalidators/eni'

const config = {
	i18n: l18n,
	i18nRootKey: 'validation'
}

Validator.extend('positive', positive, {
	paramNames: ['decimals', 'separator']
})
Validator.extend('eni_number', eni)

Vue.use(VeeValidate, config)
