import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import Property from '@/decorators/Property'
import { Terminal } from '@/models/Terminal'

export class Bunker extends Model {
	protected jsonApiType = 'bunkers'

	public terminal(): ToOneRelation {
		return this.hasOne(Terminal, 'terminal')
	}

	@Property()
	public name!: string

	@Property()
	public isExtractionArea!: boolean

	@Property()
	public deedNumber!: string

	@Property()
	public certificationNumber!: string

	@Property()
	public certificationHolder!: string

	@Property()
	public licenseHolder!: string
}
