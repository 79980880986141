import { Model } from '@/models/Model'

export class Permission extends Model {
	protected jsonApiType = 'permissions'

	public static async sync(): Promise<void> {
		const store = (await import('@/store/index')).store
		// @ts-ignore
		if (store.state.auth.permissions.length === 0) {
			const permissions = await Permission.get()

			store.commit(
				'auth/setPermissions',
				permissions.getData().map((permission) => permission.getApiId())
			)
		}
	}
}
