import NotificationType from '@/enums/NotificationType'
import { LocaleMessage } from 'vue-i18n'

export default class Notification {
	public static async Error(message: string | LocaleMessage, timeout = 5000): Promise<void> {
		await Notification.Show(message, NotificationType.ERROR, timeout)
	}

	public static async Success(message: string | LocaleMessage, timeout = 1850): Promise<void> {
		await Notification.Show(message, NotificationType.SUCCESS, timeout)
	}

	public static async Info(message: string | LocaleMessage, timeout = 0): Promise<void> {
		await Notification.Show(message, NotificationType.INFO, timeout)
	}

	public static async Warning(message: string | LocaleMessage): Promise<void> {
		await Notification.Show(message, NotificationType.WARNING)
	}

	public static async Show(
		message: string | LocaleMessage,
		notificationType: NotificationType,
		timeout: number = 0
	): Promise<void> {
		const store = (await import('@/store/index')).store
		await store.dispatch('notifications/add', {
			type: notificationType,
			message: message,
			timeout: timeout
		})
	}
}
