
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Downloadable } from '@/interfaces/Downloadable'
import Notification from '@/classes/Notification'
import i18n from '@/i18n'

@Component
export default class FileDownloadLink extends Vue {
	@Prop({ required: true })
	downloadable!: Downloadable

	@Prop({ required: false, default: true })
	small!: boolean

	@Prop({ required: false, default: false })
	flat!: boolean

	@Prop({ required: false, default: true })
	icon!: boolean

	@Prop({ required: false, default: false })
	block!: boolean

	async downloadAndOpenDocument() {
		this.downloadable.setIsDownloading(true)

		try {
			await this.downloadable.triggerDownload()
		} catch (err) {
			// eslint-disable-next-line
			console.error('Error occurred while downloading the file', err)
			await Notification.Error(i18n.t('errors.download_errors').toString())
		}

		this.downloadable.setIsDownloading(false)
		await this.$nextTick()

		this.openDocument()
	}

	openDocument() {
		if (!this.downloadable.downloadedFile) return
		window.open(URL.createObjectURL(this.downloadable.downloadedFile), '_blank')
	}
}
