import Notification from '@/interfaces/Notification'
import NotificationState from '@/interfaces/State/NotificationState'

export const push = (state: NotificationState, notification: Notification): void => {
	state.notifications.push(notification)
}

export const remove = (state: NotificationState, notificationToRemove: Notification): void => {
	state.notifications = state.notifications.filter(
		(notification: Notification) => notification.id !== notificationToRemove.id
	)
}

export const setSpoofingNotificationShown = (state: NotificationState, show: boolean): void => {
	state.showSpoofing = show
}
