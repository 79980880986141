
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const Auth = namespace('auth')

@Component({})
export default class CompleteProfileBanner extends Vue {
	@Auth.Getter('pageWithMissingProfileData')
	pageWithMissingProfileData

	@Auth.Getter('profileCompletenessPercent')
	profileCompletenessPercent
}
