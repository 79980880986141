import Auth from '@aws-amplify/auth'
import { ActionContext } from 'vuex'
import { RootState } from '@/store/RootState'
import AuthState from '@/interfaces/State/AuthState'

export const logout = async (context: ActionContext<AuthState, RootState>): Promise<void> => {
	await Auth.signOut()
	await context.dispatch('clearCache')
	window.location.href = '/login'
}

export const clearCache = async (context: ActionContext<AuthState, RootState>): Promise<void> => {
	context.commit('setInitialized', false)
	context.commit('setUser', {})
	context.commit('setPermissions', [])
	context.commit('setVessels', [])
	context.commit('setEmptyVessel', null)
	context.commit('setQueuedEmptyVessel', null)
	context.commit('users/clearCache', null, { root: true })
	context.commit('vessels/clearCache', null, { root: true })
}
