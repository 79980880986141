type CurrencyFormatterOptions = {
	numberOfDecimals?: number
	minimumDecimalPlaces?: number
	locales?: string | Array<string>
	prefixWithCurrencySign?: boolean
}

const defaultCurrencyFormatterOptions = {
	numberOfDecimals: 2,
	minimumDecimalPlaces: 2,
	prefixWithCurrencySign: true
} as CurrencyFormatterOptions

export function formatCurrency(amount: number, options: CurrencyFormatterOptions = {}): string {
	if (amount === null || amount === undefined) {
		return ''
	}
	const returnValues = [] as Array<string>
	const optionsWithDefaults = {
		...defaultCurrencyFormatterOptions,
		...options
	}
	if (optionsWithDefaults.prefixWithCurrencySign) {
		returnValues.push('€')
	}
	returnValues.push(
		amount.toLocaleString(optionsWithDefaults.locales, {
			currency: 'EUR',
			minimumFractionDigits: optionsWithDefaults.minimumDecimalPlaces,
			maximumFractionDigits: optionsWithDefaults.numberOfDecimals
		})
	)
	return returnValues.join(' ')
}
