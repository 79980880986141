import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import User from '@/models/User'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Cargo } from '@/models/Cargo'
import { Offer } from '@/models/Offer'

export class OfferInterest extends Model {
	protected jsonApiType: string = 'offerInterests'

	@Property()
	public comments!: string

	@Property()
	public expired!: boolean

	@Property()
	public createdAt!: string

	private cargo(): ToOneRelation {
		return this.hasOne(Cargo, 'cargo')
	}

	public getCargo(): Cargo {
		return this.getRelation('cargo')
	}

	public setCargo(cargo: Cargo): void {
		this.setRelation('cargo', cargo)
	}

	private offer(): ToOneRelation {
		return this.hasOne(Offer, 'offer')
	}

	public getOffer(): Offer {
		return this.getRelation('offer')
	}

	public setOffer(offer: Offer): void {
		this.setRelation('offer', offer)
	}

	private user(): ToOneRelation {
		return this.hasOne(User, 'user')
	}

	public getUser(): User {
		return this.getRelation('user')
	}
}
