
import { Component, Vue, Watch } from 'vue-property-decorator'
import merge from 'deepmerge'
import Translation from '@/models/Translation'
import Snackbar from '@/components/Snackbar.vue'
import MobileNavigation from '@/components/Navigation/MobileNavigation.vue'
import DesktopNavigation from '@/components/Navigation/DesktopNavigation.vue'
import PusherListener from '@/components/PusherListener.vue'
import { namespace } from 'vuex-class'
import User from '@/models/User'
import { Capacitor } from '@capacitor/core'
import OneSignal from 'onesignal-cordova-plugin'
import Notification from '@/classes/Notification'
import V3Page from '@/components/V3Page.vue'
import CompleteProfileBanner from '@/components/Profile/CompleteProfileBanner.vue'

const Auth = namespace('auth')
const Notifications = namespace('notifications')
const V3 = namespace('v3')

const navRouteBlacklist: Array<string> = ['login', 'register', 'trackandtrace', 'forgot-password', 'reset-password']

Vue.component('BillOfLadingGenerator', () => import('@/components/DocumentGenerators/BillOfLading.vue'))
Vue.component('AddAttachment', () => import('@/components/Messages/AddAttachment.vue'))

@Component({
	components: {
		V3Page,
		Snackbar,
		DesktopNavigation,
		MobileNavigation,
		PusherListener,
		CompleteProfileBanner
	}
})
export default class App extends Vue {
	@Auth.Getter('loggedIn')
	loggedIn!: boolean

	@Auth.Getter('requiredProfileFieldsComplete')
	requiredProfileFieldsComplete

	@Notifications.Getter('isSpoofingNotificationShown')
	showSpoofingAlert!: boolean

	@Notifications.Mutation('setSpoofingNotificationShown')
	setNotificationShown

	@V3.State('initialized')
	v3initialized!: boolean

	@V3.State('currentPage')
	v3Page!: string | null

	@Watch('$store.state.auth.user.id', { immediate: true })
	showSpoofingWarning() {
		if (this.$store.state.auth.user.spoofed) {
			this.setNotificationShown(true)
		}
	}

	@Watch('$store.state.auth.user.id', { immediate: true })
	registerPushForWeb() {
		if (!this.$store.state.auth.user.id) {
			return
		}

		// @ts-ignore
		const OS = window.OneSignal || []

		// @ts-ignore
		OS.push(async () => {
			// @ts-ignore
			OS.showNativePrompt()

			const user = await User.getCurrentUser()

			OS.setExternalUserId(user.getApiId() || '')
			// @ts-ignore
			OS.sendTags({
				userRole: user.role,
				companyName: user.companyName
			})
		})
	}

	@Watch('$store.state.auth.user.id', { immediate: true })
	async registerPushForNativeApp() {
		if (Capacitor.getPlatform() !== 'ios') {
			return
		}
		// Uncomment to set OneSignal device logging to VERBOSE
		// OneSignal.setLogLevel(6, 0);

		OneSignal.setAppId(process.env.VUE_APP_ONESIGNAL_APP_ID!)
		OneSignal.setNotificationOpenedHandler(function (jsonData) {
			// console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
			// for now do nothing, could browse to particular view or so
		})
		OneSignal.setNotificationWillShowInForegroundHandler(function (event) {
			const { title, subtitle, body } = event.getNotification()
			Notification.Info([title, subtitle, body].filter(Boolean).join('\r\n'))
		})

		// iOS - Prompts the user for notification permissions.
		//    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for
		//    notification permission (See step 6) to better communicate to your users what notifications they will get.
		OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
			// eslint-disable-next-line
			console.log('User accepted notifications: ' + accepted)
		})

		const user = await User.getCurrentUser()

		OneSignal.setExternalUserId(user.getApiId() || '')
		// @ts-ignore
		OneSignal.sendTags({
			userRole: user.role,
			companyName: user.companyName
		})
	}

	@Watch('$i18n.locale', { immediate: true })
	async loadLanguage(locale) {
		try {
			let messages = await Translation.all(locale)
			if (Array.isArray(messages) && messages.length === 0) {
				messages = {}
			}
			this.$i18n.setLocaleMessage(locale, merge(this.$i18n.messages[locale], messages))
		} catch (err: any) {
			if (err?.message !== 'Refresh Token has expired') {
				throw err
			}
		}
	}

	get showNavigation() {
		return !navRouteBlacklist.includes(this.$route.name!)
	}

	@Watch('v3Page')
	async onPageChange() {
		if (this.v3Page) {
			;(this.$refs.v3Page as V3Page)?.navigateTo(this.v3Page)
		}
	}
}
