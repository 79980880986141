import { Bid } from '@/models/Bid'
import { BillOfLading } from '@/models/BillOfLading'
import { Cargo } from '@/models/Cargo'
import { CargoProject } from '@/models/CargoProject'
import { Charter } from '@/models/Charter'
import { Company } from '@/models/Company'
import { ConversationContext } from '@/models/ConversationContext'
import { Document } from '@/models/Document'
import { GasOilBunkered } from '@/models/GasOilBunkered'
import { GasOilUsage } from '@/models/GasOilUsage'
import { InterModalManifest } from '@/models/InterModalManifest'
import { Invoice } from '@/models/accounting/Invoice'
import { InvoiceLine } from '@/models/accounting/InvoiceLine'
import { Message } from '@/models/Message'
import { Model } from '@/models/Model'
import { Offer } from '@/models/Offer'
import { PluralResponse } from 'coloquent'
import { PortBaseVoyage } from '@/models/PortBaseVoyage'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { TransportFinancial } from '@/models/TransportFinancial'
import { TransportLocation } from '@/models/TransportLocation'
import TransportStatus from '@/enums/TransportStatus'
import { TransportStatuses } from '@/models/TransportStatuses'
import User from '@/models/User'
import { Vessel } from '@/models/Vessel'
import { VesselPosition } from '@/models/VesselPosition'
import { FlatTransport } from '@/models/FlatTransport'

export class Transport extends Model {
	protected jsonApiType: string = 'transports'
	protected static pageSize = 10

	public cargo(): ToOneRelation {
		return this.hasOne(Cargo, 'cargo')
	}

	public getCargo(): Cargo {
		return this.getRelation('cargo')
	}

	private cargoProject(): ToOneRelation {
		return this.hasOne(CargoProject, 'cargoProject')
	}

	public getCargoProject(): CargoProject {
		return this.getRelation('cargoProject')
	}

	private bid(): ToOneRelation {
		return this.hasOne(Bid, 'bid')
	}

	public getBid(): Bid {
		return this.getRelation('bid')
	}

	private externalTransport(): ToOneRelation {
		return this.hasOne(FlatTransport, 'externalTransport')
	}

	public getExternalTransport(): FlatTransport {
		return this.getRelation('externalTransport')
	}

	private ownTransport(): ToOneRelation {
		return this.hasOne(FlatTransport, 'ownTransport')
	}

	public getOwnTransport(): FlatTransport {
		return this.getRelation('ownTransport')
	}

	private offer(): ToOneRelation {
		return this.hasOne(Offer, 'offer')
	}

	public getOffer(): Offer {
		return this.getRelation('offer')
	}

	private setOffer(offer: Offer) {
		this.setRelation('offer', offer)
	}

	private shipper(): ToOneRelation {
		return this.hasOne(User, 'shipper')
	}

	public getShipper(): User {
		return this.getRelation('shipper')
	}

	private skipper(): ToOneRelation {
		return this.hasOne(User, 'skipper')
	}

	public getSkipper(): User {
		return this.getRelation('skipper')
	}

	public company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company {
		return this.getRelation('company')
	}

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.getRelation('vessel')
	}

	public setVessel(vessel: Vessel) {
		this.setRelation('vessel', vessel)
	}

	private unregisteredVessel(): ToOneRelation {
		return this.hasOne(Vessel, 'unregisteredVessel')
	}

	public getUnregisteredVessel(): Vessel {
		return this.getRelation('unregisteredVessel')
	}

	public setUnregisteredVessel(vessel: Vessel) {
		this.setRelation('unregisteredVessel', vessel)
	}

	public getActiveVessel(): Vessel {
		if (this.getVessel()) return this.getVessel()
		return this.getUnregisteredVessel()
	}

	private charter(): ToOneRelation {
		return this.hasOne(Charter, 'charter')
	}

	public getCharter(): Charter {
		return this.getRelation('charter')
	}

	public interModalManifest(): ToOneRelation {
		return this.hasOne(InterModalManifest, 'interModalManifest')
	}

	public getInterModalManifest(): InterModalManifest {
		return this.getRelation('interModalManifest')
	}

	public documents(): ToManyRelation {
		return this.hasMany(Document, 'documents')
	}

	public getDocuments(): Array<Document> {
		return this.getRelation('documents') || []
	}

	public setDocuments(documents: Array<Document>): void {
		return this.setRelation('documents', documents)
	}

	public async fetchDocuments() {
		const documents = ((await this.documents().get()) as PluralResponse).getData().reverse() as Array<Document>
		this.setDocuments(documents)
	}

	public messages(): ToManyRelation {
		return this.hasMany(Message, 'messages')
	}

	public gasOilUsage(): ToOneRelation {
		return this.hasOne(GasOilUsage, 'gasOilUsage')
	}

	public getGasOilUsage(): GasOilUsage {
		return this.getRelation('gasOilUsage')
	}

	private gasOilBunkered(): ToOneRelation {
		return this.hasOne(GasOilBunkered, 'gasOilBunkered')
	}

	public getGasOilBunkered(): GasOilBunkered {
		return this.getRelation('gasOilBunkered')
	}

	private portBaseVoyages(): ToManyRelation {
		return this.hasMany(PortBaseVoyage, 'portBaseVoyages')
	}

	public getPortBaseVoyages(): Array<PortBaseVoyage> {
		return this.getRelation('portBaseVoyages')
	}

	private transportStatuses(): ToOneRelation {
		return this.hasOne(TransportStatuses, 'transportStatuses')
	}

	public getStatuses(): TransportStatuses {
		return this.getRelation('transportStatuses')
	}

	public setStatuses(statuses: TransportStatuses) {
		this.setRelation('transportStatuses', statuses)
	}

	public locations(): ToManyRelation {
		return this.hasMany(TransportLocation, 'locations')
	}

	public getLocations(): Array<TransportLocation> {
		return [...(this.getRelation('locations') || [])].sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
	}

	public getActiveLocation(): TransportLocation {
		const locations = this.getLocations()
		const filledStatuses = locations.filter((location) => location.status !== null)
		return filledStatuses[filledStatuses.length - 1] || locations[0]
	}

	public getNextLocation(): TransportLocation | null {
		const active = this.getActiveLocation()
		return this.getLocations().find((loc) => loc.sequence === active.sequence + 1) || null
	}

	public vesselPositions(): ToManyRelation {
		return this.hasMany(VesselPosition, 'vesselPositions')
	}

	public async getVesselPositions(): Promise<Array<VesselPosition>> {
		return ((await this.vesselPositions().get()) as PluralResponse).getData() as Array<VesselPosition>
	}

	public invoices(): ToManyRelation {
		return this.hasMany(Invoice, 'invoices')
	}

	public getInvoices(): Array<Invoice> {
		return this.getRelation('invoices') || []
	}

	public financialRegistrations(): ToManyRelation {
		return this.hasMany(InvoiceLine, 'financialRegistrations')
	}

	public getFinancialRegistrations(): Array<InvoiceLine> {
		return this.getRelation('financialRegistrations') || []
	}

	public financial(): ToOneRelation {
		return this.hasOne(TransportFinancial, 'financial')
	}

	public getFinancial(): TransportFinancial {
		return this.getRelation('financial')
	}

	public latestLoadingBill(): ToOneRelation {
		return this.hasOne(BillOfLading, 'latestLoadingBill')
	}

	public getLatestLoadingBill(): BillOfLading {
		return this.getRelation('latestLoadingBill')
	}

	public conversationContext(): ToOneRelation {
		return this.hasOne(ConversationContext, 'conversationContext')
	}

	public getConversationContext(): ConversationContext {
		return this.getRelation('conversationContext')
	}

	@Property()
	public belongsToProject!: boolean

	@Property()
	public overtime!: string

	@Property()
	public overtimeQuantityUnit!: string

	@Property()
	public comment!: string

	@Property()
	public attachedFilesAmount!: number

	@Property()
	public status!: TransportStatus | null

	@Property()
	public generateBillOfLading!: boolean

	@Property()
	public generateHopperMeasurementsReport!: boolean

	@Property()
	public unloadingBillAttached!: boolean

	@Property()
	public trackTraceSent!: boolean

	@Property()
	public title!: string | null

	@Property()
	public subTitle!: string | null

	@Property()
	public clientName!: string | null

	@Property()
	public dossierNumber!: string

	// only filled via the /accounting/invoices endpoint
	@Property()
	public vesselName!: string | null

	// only filled via the /accounting/invoices endpoint
	@Property()
	public unloadingDate!: string | null

	public get productExcerpt() {
		return this.getCargo()?.getCargoType()?.description ?? ''
	}

	public get clientId() {
		return this.getCargo()?.getClient()?.getApiId()
	}

	public static async fetchLazy(id: string): Promise<Transport> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transports/hasCached'](id)) {
			await store.dispatch('transports/findRecord', id)
		}

		return store.getters['transports/peekRecord'](id)
	}

	public async fetchCargoLazy(): Promise<Cargo> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transports/hasRelationCached'](this.getApiId(), 'cargo')) {
			const cargo = (await this.cargo().get()).getData() as Cargo
			store.commit('transports/cacheRelationship', {
				id: this.getApiId(),
				relationName: 'cargo',
				values: cargo
			})
		}

		return store.getters['transports/peekRelation'](this.getApiId(), 'cargo')
	}

	public async fetchRegistrationsLazy(): Promise<Array<InvoiceLine>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transports/hasRelationCached'](this.getApiId(), 'registrations')) {
			const registrations = (await this.financialRegistrations().get()).getData() as Array<InvoiceLine>
			store.commit('transports/cacheRelationship', {
				id: this.getApiId(),
				relationName: 'registrations',
				values: registrations
			})
		}

		return store.getters['transports/peekRelation'](this.getApiId(), 'registrations')
	}

	public async fetchInvoicesLazy(): Promise<Array<Invoice>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transports/hasRelationCached'](this.getApiId(), 'invoices')) {
			const invoices = (await this.invoices().get()).getData() as Array<Invoice>
			store.commit('transports/cacheRelationship', {
				id: this.getApiId(),
				relationName: 'invoices',
				values: invoices
			})
		}

		return store.getters['transports/peekRelation'](this.getApiId(), 'invoices')
	}
}
