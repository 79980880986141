import { Model } from 'coloquent/dist/Model'
import Vue from 'vue'

const getRelationCacheKey = (id, relationName) => `${id}-${relationName}`

export default class CachedResourceStore<M extends Model = Model> {
	namespaced: boolean = false
	allFetched: boolean = false

	constructor(
		private classRef: typeof Model,
		props
	) {
		if (!classRef) {
			// eslint-disable-next-line
			console.error(new Error('Cannot create a CachedResourceStore without a Model').stack)
		}
		this.namespaced = props.namespaced

		Object.assign(this, {
			actions: {
				findRecord: this.findRecord.bind(this),
				findAll: this.findAll.bind(this),
				...props.actions
			},
			mutations: {
				cacheOne(state, { record, cacheKey }: { record: M; cacheKey: string }) {
					if (!record) return
					const foundIndex = state.cache.findIndex((cached) => cached === record || cached.id === cacheKey)
					if (foundIndex > -1) {
						Vue.set(state.cache, foundIndex, {
							id: cacheKey,
							record
						})
					} else {
						state.cache.push({ id: cacheKey, record })
					}
				},
				cache(state, records: Array<M>) {
					records.filter(Boolean).forEach((record) => {
						const cacheKey = String(record.getApiId())
						const foundIndex = state.cache.findIndex(
							(cached) => cached.record === record || cached.id === cacheKey
						)
						if (foundIndex > -1) {
							Vue.set(state.cache, foundIndex, {
								id: cacheKey,
								record
							})
						} else {
							state.cache.push({ id: cacheKey, record })
						}
					})
				},
				cacheRelationship(state, { id, relationName, values }) {
					state.relationCache[getRelationCacheKey(id, relationName)] = values
				},
				removeFromCache(state, id: string) {
					const foundIndex = state.cache.findIndex((cached) => cached.id === id)
					if (foundIndex > -1) {
						state.cache.splice(foundIndex, 1)
					}
				},
				clearCache(state) {
					state.cache = []
					state.relationCache = {}
					state.allFetched = false
					state.allQuery = null
				},
				allFetched(state, fetched) {
					state.allFetched = fetched
				},
				queryAll(state, query) {
					state.allQuery = query
				},
				...props.mutations
			}
		})
	}

	state = () =>
		({
			cache: [],
			relationCache: {},
			allQuery: null,
			allFetched: false
		}) as {
			cache: Array<{ id: string; record: M }>
			allFetched: boolean
			allQuery: Promise<Array<M>> | null
		}

	getters = {
		hasColdCache: (state) => () => {
			return state.cache.length === 0
		},
		hasAllFetched: (state) => () => state.allFetched,
		hasCached: (state) => (id) => {
			const foundIndex = state.cache.findIndex((cached) => cached.id === String(id))
			return foundIndex > -1 && state.cache[foundIndex].record
		},
		peekRecord:
			(state) =>
			(id): M => {
				return state.cache.find((cached) => cached.id === String(id))?.record
			},
		peekAll: (state) => () => {
			return state.cache.map((cached) => cached.record).filter(Boolean)
		},
		hasRelationCached: (state) => (id, relationName) => {
			return Object.keys(state.relationCache).includes(getRelationCacheKey(id, relationName))
		},
		peekRelation: (state) => (id, relationName) => {
			return state.relationCache[getRelationCacheKey(id, relationName)]
		}
	}

	async findRecord({ commit, state, getters }, id) {
		let query
		const foundIndex = state.cache.findIndex((cached) => cached.id === String(id))
		if (state.cache[foundIndex] > -1) {
			query = getters.peekRecord(id)
		} else {
			// @ts-ignore
			query = this.classRef.find(id)
		}
		const record = (await query).getData()
		commit('cache', [record])
		return record
	}

	async findAll({ commit, state }) {
		let allQuery
		if (state.allQuery) {
			allQuery = state.allQuery
		} else {
			// @ts-ignore
			allQuery = this.classRef.get()
			commit('queryAll', allQuery)
		}
		const records = (await allQuery).getData()
		commit('cache', records)
		commit('allFetched', true)
	}
}
