import { GeoJSON } from 'geojson'
import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Country } from '@/models/Country'
import { NetworkInformationLocks } from '@/models/NetworkInformationLocks'
import { FacilityAdministration } from '@/models/FacilityAdministration'
import { MappableModel } from '@/interfaces/MappableModel'
import { Isrs } from '@/models/Isrs'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { LockChamber } from '@/models/LockChamber'
import { LockSubChamber } from '@/models/LockSubChamber'
import { OperatingTime } from '@/models/OperatingTime'
import { DelayPrediction } from '@/models/DelayPrediction'
import { VhfChannel } from '@/models/VhfChannel'

export class Lock extends Model implements MappableModel {
	protected jsonApiType = 'locks'

	private country(): ToOneRelation {
		return this.hasOne(Country, 'country')
	}

	private networkInformation(): ToOneRelation {
		return this.hasOne(NetworkInformationLocks, 'networkInformation')
	}

	private facilityAdministration(): ToOneRelation {
		return this.hasOne(FacilityAdministration, 'facilityAdministration')
	}

	private isrs(): ToOneRelation {
		return this.hasOne(Isrs, 'isrs')
	}

	private lockChambers(): ToManyRelation {
		return this.hasMany(LockChamber, 'lockChambers')
	}

	private lockSubChambers(): ToManyRelation {
		return this.hasMany(LockSubChamber, 'lockSubChambers')
	}

	public getFacilityAdministration(): FacilityAdministration {
		return this.getRelation('facilityAdministration')
	}

	public getLockChambers(): Array<LockChamber> {
		return this.getRelation('lockChambers')
	}

	public getNetworkInformation(): NetworkInformationLocks {
		return this.getRelation('networkInformation')
	}

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoLocation')
	}

	public getName(): string {
		return this.getAttribute('name')
	}

	private vhfChannel(): ToOneRelation {
		return this.hasOne(VhfChannel, 'vhfChannel')
	}

	public getVhfChannel(): VhfChannel {
		return this.getRelation('vhfChannel')
	}

	@Property('name')
	public name!: string

	@Property('address')
	public address!: string

	@Property('place')
	public place!: string

	@Property('numberOfChambers')
	public numberOfChambers!: string

	@Property('phoneNumber')
	public phoneNumber!: string

	@Property()
	public thisWeekOperatingTimes!: OperatingTime

	@Property()
	public delayPredictions!: Array<{
		north: Array<DelayPrediction>
		east: Array<DelayPrediction>
		south: Array<DelayPrediction>
		west: Array<DelayPrediction>
	}>
}
