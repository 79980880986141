import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { VesselLog } from '@/models/VesselLog'

export class TerminalVisitAdditionalInfo extends Model {
	protected jsonApiType = 'terminalVisitAdditionalInfos'

	private vesselLog(): ToOneRelation {
		return this.hasOne(VesselLog, 'vesselLog')
	}

	public getVesselLog(): VesselLog {
		return this.getRelation('vesselLog')
	}

	@Property()
	public startTimeTransShipment!: string

	@Property()
	public endTimeTransShipment!: string

	@Property()
	public loadedContainerQuantity!: number

	@Property()
	public dischargedContainerQuantity!: number

	@Property()
	public rearrangedContainerQuantity!: number
}
