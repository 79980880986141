import { GeoJSON } from 'geojson'
import { Model } from '@/models/Model'
import { Country } from '@/models/Country'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { NetworkInformation } from '@/models/NetworkInformation'
import { FacilityAdministration } from '@/models/FacilityAdministration'
import { MappableModel } from '@/interfaces/MappableModel'
import { Isrs } from '@/models/Isrs'
import Property from '@/decorators/Property'
import { BerthCurrentStatus } from '@/interfaces/BerthCurrentStatus'

export class Berth extends Model implements MappableModel {
	protected jsonApiType = 'berths'

	private country(): ToOneRelation {
		return this.hasOne(Country, 'country')
	}

	private networkInformation(): ToOneRelation {
		return this.hasOne(NetworkInformation, 'networkInformation')
	}

	private facilityAdministration(): ToOneRelation {
		return this.hasOne(FacilityAdministration, 'facilityAdministration')
	}

	public getCountry(): Country {
		return this.getRelation('country')
	}

	public getNetworkInformation(): Country {
		return this.getRelation('networkInformation')
	}

	public getFacilityAdministration(): Country {
		return this.getRelation('facilityAdministration')
	}

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoLocation')
	}

	public getName(): string {
		return this.title || ''
	}

	private isrs(): ToOneRelation {
		return this.hasOne(Isrs, 'isrs')
	}

	@Property('name')
	public title?: string

	@Property('length')
	public length?: string

	@Property('place')
	public place?: string

	@Property('maximalShipLength')
	public maximalShipLength?: string

	@Property('minimalShipLength')
	public minimalShipLength?: string

	@Property('mooredShipsLength')
	public mooredShipsLength?: string

	@Property('condition')
	public condition?: string

	@Property('note')
	public note?: string

	@Property('width')
	public width?: string

	@Property('current')
	public currentStatus!: BerthCurrentStatus
}
