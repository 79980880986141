import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import AttributeType from '@/enums/AttributeType'

export type AttributeOption = { slug: string; value: string }

export class Attribute extends Model {
	protected jsonApiType = 'attributeTypes'

	public get unit() {
		const title = '' + this.title
		const first = title.lastIndexOf('(')
		const second = title.lastIndexOf(')')
		if (first > 0 && first < second) {
			return title.substring(first + 1, second)
		}
		return null
	}

	public get titleWithoutUnit() {
		if (this.unit) {
			return this.title.replace(`(${this.unit})`, '')
		}
		return this.title
	}

	@Property()
	public slug!: string

	@Property()
	public title!: string

	@Property('type')
	public attributeType!: AttributeType

	@Property('subcategory')
	public subCategory!: string

	@Property()
	public group!: string | null

	@Property()
	public attributeOptions!: Array<AttributeOption> | null

	public getAttributeOptions(): Array<AttributeOption> {
		return this.attributeOptions ?? []
	}
}
