import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import moment, { Moment } from 'moment'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Vessel } from '@/models/Vessel'
import axios from 'axios'
import { Company } from '@/models/Company'
import { Mailable } from '@/interfaces/Mailable'

export class Charter extends Model implements Mailable {
	protected jsonApiType: string = 'charters'

	@Property()
	public price!: string

	@Property()
	public priceUnit!: string

	@Property()
	public status!: string

	@Property()
	public pdfPath!: string

	@Property()
	public previewPath!: string

	@Property()
	public fileName!: string

	@Property()
	public generatedAt!: string

	@Property()
	public acceptedAt!: string

	@Property()
	public declinedAt!: string

	@Property()
	public sendEmail: boolean | undefined

	@Property()
	public sendEmailCopy: boolean | undefined

	@Property()
	public emailBody: string | null | undefined

	@Property()
	public emailSubject: string | null | undefined

	@Property()
	public receiverEmails: string | null | undefined

	public get formattedGeneratedAt(): Moment {
		return moment(this.generatedAt)
	}

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel | null {
		return this.relation('vessel') || null
	}

	private unregisteredVessel(): ToOneRelation {
		return this.hasOne(Vessel, 'unregisteredVessel')
	}

	public getUnregisteredVessel(): Vessel | null {
		return this.relation('unregisteredVessel') || null
	}

	private viaBroker(): ToOneRelation {
		return this.hasOne(Company, 'viaBroker')
	}

	public getViaBroker(): Company | null {
		return this.relation('viaBroker') || null
	}

	public async getPreview(): Promise<string> {
		const response = await axios.get(this.previewPath)
		return response.data
	}

	public get sanitizedFilename() {
		const splitted = (this.fileName || '').split('/')
		return splitted[splitted.length - 1] + '.pdf'
	}
}
