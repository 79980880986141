import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'

export class Category extends Model {
	protected jsonApiType = 'cargoCategories'

	@Property()
	public description!: string

	@Property()
	public cdniNumber!: string

	private category(): ToOneRelation {
		return this.hasOne(Category, 'category')
	}

	public getCategory(): Category {
		return this.getRelation('category')
	}

	public static async fetchAllLazy(): Promise<Array<Category>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['cargoCategories/hasAllFetched']()) {
			await store.dispatch('cargoCategories/findAll')
		}
		return store.getters['cargoCategories/peekAll']()
	}
}
