import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
import '@/assets/stylus/main.styl'
import Ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {
	// @ts-ignore
	cspNonce: document.querySelector('meta[name="csp-nonce"]').content,
	iconfont: 'md',
	theme: {
		primary: '#E6007E',
		secondary: '#13235b'
	},
	directives: {
		Ripple
	}
})
