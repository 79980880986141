import { BerthCurrentStatus } from '@/interfaces/BerthCurrentStatus'
import { BridgeCurrentStatus } from '@/interfaces/BridgeCurrentStatus'
import { MappableModel } from '@/interfaces/MappableModel'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import i18n from '@/i18n'

export class Facility extends Model implements MappableModel {
	protected jsonApiType: string = 'facilities'

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoLocation')
	}

	public getName(): string {
		return this.name
	}

	public getTooltipContent(): string {
		if (
			this.facilityType !== 'berths' ||
			!this.currentStatus ||
			// @ts-ignore
			!this.currentStatus.percentage
		) {
			return this.getName()
		}

		return `${this.getName()} (<strong style="color: ${this.getStyleObject().color}">${i18n.t(
			'berths.tooltip_status',
			{
				// @ts-ignore
				currentStatus: Math.round(this.currentStatus.percentage)
			}
		)}</strong>)`
	}

	public getStyleObject(): { fillColor: string; color: string } {
		if (!this.currentStatus) {
			return {
				fillColor: '#333333',
				color: '#003366'
			}
		}

		if (
			// @ts-ignore
			Math.round(this.currentStatus.percentage) > 0 &&
			// @ts-ignore
			Math.round(this.currentStatus.percentage) < 81
		) {
			return {
				fillColor: '#333333',
				color: '#FF9900'
			}
			// @ts-ignore
		} else if (Math.round(this.currentStatus.percentage) > 80) {
			return {
				fillColor: '#333333',
				color: '#ff0000'
			}
		} else {
			return {
				fillColor: '#333333',
				color: '#7ED321'
			}
		}
	}

	@Property('name')
	public name!: string

	@Property('place')
	public place!: string

	@Property('facilityType')
	public facilityType!: string

	@Property('current')
	public currentStatus!: BridgeCurrentStatus | BerthCurrentStatus

	@Property('geoLocation')
	public geoLocation!: GeoJSON.Geometry

	@Property('isUserManaged')
	public isUserManaged!: boolean

	public get color(): string {
		switch (this.facilityType) {
			case 'locks':
				return '#F5A623'
			case 'berths':
				return '#7ED321'
			case 'bridges':
				return '#F5A623'
			case 'terminals':
				if (this.isUserManaged) {
					return '#6B0000'
				}
				return '#D0021B'
			case 'terminalSrsLocations':
				return '#D0021B'
			case 'waterLevelsNap':
				return '#4A90E2'
			case 'carScaffoldings':
				return '#4A90E2'
			case 'bunkerStations':
				return '#4D4E4F'
		}
		return 'grey'
	}

	public get icon(): string {
		switch (this.facilityType) {
			case 'locks':
				return 'angle-double-up'
			case 'berths':
			case 'ports':
				return 'anchor'
			case 'bridges':
				return 'archway'
			case 'terminals':
			case 'terminalSrsLocations':
				return 'industry'
			case 'carScaffoldings':
				return 'car'
			case 'waterLevelsNap':
				return 'ruler-vertical'
			case 'bunkerStations':
				return 'gas-pump'
		}
		return 'ship'
	}

	public get pin(): string {
		switch (this.facilityType) {
			case 'locks':
				return '/img/icons/markers/locks.svg'
			case 'berths':
				if (
					// @ts-ignore
					Math.round(this.currentStatus.percentage) > 0 &&
					// @ts-ignore
					Math.round(this.currentStatus.percentage) < 81
				) {
					return '/img/icons/markers/berth-orange.svg'
					// @ts-ignore
				} else if (Math.round(this.currentStatus.percentage) > 80) {
					return '/img/icons/markers/berth-red.svg'
				}
				return '/img/icons/markers/berth.svg'
			case 'bridges':
				return '/img/icons/markers/bridge.svg'
			case 'terminals':
			case 'terminalSrsLocations':
				if (this.isUserManaged) {
					return '/img/icons/markers/terminals-user-managed.svg'
				}
				return '/img/icons/markers/terminals.svg'
			case 'carScaffoldings':
				return '/img/icons/markers/car.svg'
			case 'waterLevelsNap':
				return '/img/icons/markers/waterlevel.svg'
			case 'bunkerStations':
				return '/img/icons/markers/fuel.svg'
			case 'ports':
				return '/img/icons/markers/port.svg'
		}
		return '/img/icons/markers/terminals.svg'
	}

	public get fullName(): string {
		if (!this.name) {
			return `${this.place}`
		}
		return `${this.name}, ${this.place}`
	}
}
