
import { Component, Vue } from 'vue-property-decorator'
import Notification from '@/interfaces/Notification'
import { mapActions, mapState } from 'vuex'
import { namespace } from 'vuex-class'
import DOMPurify from 'dompurify'

const Notifications = namespace('notifications')

@Component({
	computed: mapState('notifications', ['notifications']),
	methods: mapActions('notifications', ['remove'])
})
export default class Snackbar extends Vue {
	@Notifications.Action('remove') removeNotification
	notifications!: Array<Notification>
	timeoutId: number = 0

	get sanitizedMessage() {
		return DOMPurify.sanitize(this.notification?.message ?? '')
	}

	get notification(): Notification | null {
		const notification = this.notifications.length > 0 ? this.notifications[0] : null

		if (notification?.timeout) {
			this.timeoutId = window.setTimeout(() => {
				this.removeNotification(notification)
			}, notification.timeout)
		}

		return notification
	}

	close() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId)
		}

		this.removeNotification(this.notifications[0])
	}
}
