import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class ManifestTransportOrder extends Model {
	protected jsonApiType: string = 'manifestTransportOrders'

	@Property()
	public equipmentNumber!: string

	@Property()
	public equipmentType!: string

	@Property()
	public weight!: number

	@Property()
	public statuses!: Array<{ type: string; ok: boolean }>

	@Property()
	public isCancelled!: boolean

	@Property()
	public shippingCompany!: string

	public isLoading: boolean = false
}
