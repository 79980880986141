export default {
	validate: (value: string, { decimals = '2', separator = '.' }: any = {}) => {
		const isFalsyInput = value === null || value === undefined || value === ''
		const validateAsInteger = Number(decimals) === 0

		if (isFalsyInput) {
			return { valid: false }
		}

		if (validateAsInteger) {
			return { valid: /^\d*$/.test(value) }
		}

		const regexPart = validateAsInteger ? '+' : `{1,${decimals}}`

		const expressionAboveOne = `^[1-9]{1,1}\\d*?(\\d*\\${separator}\\d${regexPart})?$`
		const expressionFromZeroToOne = `^0\\${separator}\\d${regexPart}$`

		const regex = new RegExp(`${expressionFromZeroToOne}|${expressionAboveOne}`)

		return { valid: regex.test(value) }
	},
	getMessage: (field) => 'The ' + field + ' value is not a valid positive number.'
}
