import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'
import { GeoJSON } from 'geojson'

export class VesselPosition extends Model {
	protected jsonApiType: string = 'vesselPosition'

	@Property()
	public locationDescription!: string

	@Property()
	public locationCountryCode!: string

	@Property()
	public locationGeo!: GeoJSON.GeoJSON

	@Property()
	public dateTime!: string

	private transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport {
		return this.getRelation('transport')
	}

	public setTransport(transport: Transport | null): void {
		this.setRelation('transport', transport)
	}

	public getLocation(): GeoJSON.GeoJSON {
		return this.locationGeo
	}
}
