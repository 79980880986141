import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import ClaimType from '@/enums/ClaimType'

export class CertificationClaimType extends Model {
	protected jsonApiType = 'certificationClaimTypes'

	@Property()
	public title!: string

	@Property()
	public slug!: string

	@Property()
	public claimType!: ClaimType

	@Property()
	public sequence!: number

	public static async fetchAllLazy(): Promise<Array<CertificationClaimType>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['certificationClaimTypes/hasAllFetched']()) {
			await store.dispatch('certificationClaimTypes/findAll')
		}

		return store.getters['certificationClaimTypes/peekAll']()
	}

	public static async fetchLazy(id): Promise<CertificationClaimType> {
		const store = (await import('@/store/index')).store
		if (!store.getters['certificationClaimTypes/hasCached'](id)) {
			await store.dispatch('certificationClaimTypes/findRecord', id)
		}

		return store.getters['certificationClaimTypes/peekRecord'](id)
	}
}
