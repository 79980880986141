import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { FleetMember } from '@/models/FleetMember'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import User from '@/models/User'

export class Fleet extends Model {
	protected jsonApiType: string = 'fleets'

	public members(): ToManyRelation {
		return this.hasMany(FleetMember, 'members')
	}

	public getMembers(): Array<FleetMember> {
		return this.getRelation('members') || []
	}

	public pendingMembers(): ToManyRelation {
		return this.hasMany(FleetMember, 'pendingMembers')
	}

	public getPendingMembers(): Array<FleetMember> {
		return this.getRelation('pendingMembers') || []
	}

	public getOwner(): User {
		return this.getRelation('owner')
	}

	public owner(): ToOneRelation {
		return this.hasOne(User, 'owner')
	}

	@Property('fleetName')
	public name!: string

	@Property()
	public memberCount!: string

	@Property()
	public pendingMemberCount!: string
}
