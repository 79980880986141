import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { Lock } from '@/models/Lock'

export type WarningRoute = {
	warningContent: string
	dimensionalDifference: number | undefined
	geometry: GeoJSON.GeoJSON
}
export class RoutePlanner extends Model {
	protected jsonApiType = 'routeplanner'

	private locks(): ToManyRelation {
		return this.hasMany(Lock, 'locks')
	}

	public getLocks(): Array<Lock> {
		return this.getRelation('locks')
	}

	@Property()
	public routeType!: string

	@Property()
	public costPerTon!: number

	@Property()
	public duration!: {
		min: number
		max: number
		estimated: number
	}

	@Property()
	public distance!: number

	@Property()
	public ETA!: string

	@Property()
	public co2!: number

	@Property()
	public nox!: number

	@Property()
	public route!: GeoJSON.GeoJSON

	@Property()
	public warningRoutes?: Array<WarningRoute>

	@Property()
	public progress!: GeoJSON.GeoJSON

	@Property()
	public progressStatus!: string

	@Property()
	public pm!: number

	@Property()
	public fuel!: number

	@Property()
	public locksAmount!: number | null

	@Property()
	public sailTime!: number | null

	@Property()
	public waitTime!: number | null

	@Property()
	public restTime!: number | null

	@Property()
	public cost!: {
		unit: string
		price: number
		total: number
		port: number
		material: number
		fuel: number
		personnel: number
	}

	@Property()
	public dimensions!: {
		kw: { length: number; width: number; height: number }
		ww: { length: number; width: number; draught: number }
	}

	@Property()
	public locations!: Array<{ name: string }>

	public get color(): string {
		switch (this.routeType) {
			case 'vessel':
				return '#4A90E2'
			case 'truck':
				return '#9B9B9B'
			case 'train':
				return '#F5A623'
			default:
				return 'black'
		}
	}

	public colorForAlternativeRoute?: string

	public get icon(): string {
		if (this.routeType === 'vessel') {
			return 'ship'
		}

		return this.routeType
	}
}
