import { Transport } from '@/models/Transport'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'
import { Document } from '@/models/Document'
import { Cargo } from '@/models/Cargo'

export default {
	namespaced: true,
	state: () => ({
		transport: null,
		documents: [],
		cargo: null,
		cargoQuery: null
	}),

	mutations: {
		setTransport(state, transport: Transport) {
			state.cargo = null
			state.cargoQuery = null
			state.documents = []
			state.transport = transport
		},
		setDocuments(state, documents: Array<Document>) {
			state.documents = documents
		},
		setCargo(state, cargo: Cargo) {
			state.cargo = cargo
		},
		setCargoQuery(state, cargoQuery: Promise<any>) {
			state.cargoQuery = cargoQuery
		},
		removeDocument(state, document: Document) {
			state.documents = state.documents.filter((doc) => doc !== document)
		},
		clear: (state) => () => {
			state.documents = []
			state.transport = null
			state.cargo = null
			state.cargoQuery = null
		}
	},
	getters: {
		getTransport: (state) => () => {
			return state.transport
		},
		getDocuments: (state) => () => {
			return state.documents
		},
		getCargo: (state) => () => {
			return state.cargo
		}
	},
	actions: {
		async fetchDocuments({ commit, getters }) {
			const transport = getters.getTransport()
			if (!transport) return
			const documents = ((await transport.documents().get()) as PluralResponse)
				.getData()
				.reverse() as Array<Document>
			commit('setDocuments', documents)
			commit('documents/cache', documents, { root: true })
		},
		async fetchCargo({ commit, getters }) {
			const transport = getters.getTransport()
			if (!transport) return
			const cargoQuery = transport.cargo().get()
			commit('setCargoQuery', cargoQuery)
			const cargo = (await cargoQuery).getData() as Cargo
			commit('setCargo', cargo)
			return cargo
		},
		async fetchCargoLazy({ getters, dispatch, state }) {
			const transport = getters.getTransport()
			if (!transport) return
			const cargo = getters.getCargo()
			if (cargo) return cargo
			const cargoQuery = state.cargoQuery
			if (cargoQuery) {
				return (await cargoQuery).getData() as Cargo
			}
			await dispatch('fetchCargo')
		}
	}
}
