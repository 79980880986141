import AuthState from '@/interfaces/State/AuthState'
import { Company } from '@/models/Company'
import { NavigationItem } from '@/interfaces/NavigationItem'
import UserRole from '@/enums/UserRole'
import i18n from '@/i18n'
import {
	attributesIncludedInProfileCalculationShip,
	attributesIncludedInProfileCalculationPreferences,
	attributesIncludedInProfileCalculationDocuments,
	attributesRequiredInProfileCalculationShip,
	Vessel
} from '@/models/Vessel'

export const hasPermission = (state: AuthState): any => {
	return (permission: string) => {
		return state.permissions.includes(permission)
	}
}

const slugExists = (state: AuthState, slugName: string): boolean => {
	return state.memberships.some((m) => m === slugName)
}

export const firstDayOfWeek = (state: AuthState): any => {
	return state.user.localeFirstDayOfWeek
}

export const hasDashboardMembership = (state: AuthState): boolean => slugExists(state, 'DBI')
export const hasDashboardTransportMembership = (state: AuthState): boolean => slugExists(state, 'DBT')
export const hasPortManagementMembership = (state: AuthState): boolean => slugExists(state, 'PMD')
export const hasHasInventoryManagementMembership = (state: AuthState): boolean => slugExists(state, 'VM')
export const hasLoadingBillMembership = (state: AuthState): boolean => slugExists(state, 'BLBL')
export const hasPrivateClientCommunicationMembership = (state: AuthState): boolean => slugExists(state, 'PCC')
export const hasAccountingMembership = (state: AuthState): boolean => slugExists(state, 'ACC')
export const hasThirdPartyCollaborationsMembership = (state: AuthState): boolean => slugExists(state, 'TPC')
export const hasFinancialRegistrationMembership = (state: AuthState): boolean => slugExists(state, 'TFR')
export const hasLegacyTransportFinancialsMembership = (state: AuthState): boolean => slugExists(state, 'LTF')
export const hasBillOfLadingTransportMembership = (state: AuthState): boolean => slugExists(state, 'BLT')
export const hasAdvisingDocumentMembership = (state: AuthState): boolean => slugExists(state, 'AD')
export const hasLCIMembership = (state: AuthState): boolean => slugExists(state, 'LCI')
export const hasEMLMembership = (state: AuthState): boolean => slugExists(state, 'EML')
export const hasOBMembership = (state: AuthState): boolean => slugExists(state, 'OB')
export const hasCCRMembership = (state: AuthState): boolean => slugExists(state, 'CCR')
export const hasDPTMembership = (state: AuthState): boolean => slugExists(state, 'DPT')
export const hasChlorideMembership = (state: AuthState): boolean => slugExists(state, 'CHR')
export const hasQuotationMembership = (state: AuthState): boolean => slugExists(state, 'quotation')
export const hasRoutePROMembership = (state: AuthState): boolean => slugExists(state, 'routeplanner_pro')
export const hasRouteCostQuotationMembership = (state: AuthState): boolean =>
	slugExists(state, 'routeplanner_cost_quotation')

export const hasMapPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PM')
export const hasCargoPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PC')
export const hasTransportPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PT')
export const hasMessageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PMSG')
export const hasGroupsPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PG')
export const hasInsightPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PI')
export const hasCapacityRadarMembership = (state: AuthState): boolean => slugExists(state, 'CR')
export const hasRelationManagementMembership = (state: AuthState): boolean => slugExists(state, 'RM')
export const hasTerminalPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'TM')

export const isInitialized = (state: AuthState): boolean => !!state.initialized
export const loggedIn = (state: AuthState): boolean => !!state.user.id
export const spoofed = (state: AuthState): boolean => !!state.user.spoofed
export const currentRole = (state: AuthState): string => state.user.role
export const isSkipper = (state: AuthState): boolean => state.user.role === UserRole.SKIPPER
export const isPortManager = (state: AuthState): boolean => state.user.role === UserRole.PORTMANAGER
export const isBroker = (state: AuthState): boolean => state.user.role === UserRole.LOGISTICSERVICEPROVIDER
export const isShipper = (state: AuthState): boolean => state.user.role === UserRole.SHIPPER
export const hasInternalFinancialConnection = (state: AuthState): boolean =>
	!!(state.financialConnection && state.financialConnection.connection === 'internal')
export const hasExactFinancialConnection = (state: AuthState): boolean =>
	!!(state.financialConnection && state.financialConnection.connection === 'exact-online')

export const userShouldRegisterVessel = (state: AuthState): boolean =>
	state.user.role === UserRole.SKIPPER && (!state.user.active || state.vessels.length === 0)

export const isActivePending = (state: AuthState): boolean => {
	if (!state.companies || state.companies.length === 0 || state.user.active) {
		return false
	}

	return (
		state.companies.filter((company) => {
			return Number(company.cocNumber) > 0
		}).length > 0
	)
}

export const ownVessel = (state: AuthState, getters, rootState, rootGetters): Vessel | null => {
	const vesselId = state.vessels[0]
	if (!vesselId) return null
	return rootGetters['vessels/peekRecord'](vesselId)
}

export const userDetailsCriteria = (state: AuthState): Array<boolean> => {
	return [!!state.user.firstName, !!state.user.lastName]
}

export const companyDetailsCriteria = (state: AuthState, getters): Array<boolean> => {
	const company = getters.currentCompany
	if (!company) return []
	return [!!company.name, !!company.vatNumber, !!company.cocNumber]
}

export const additionalCompanyDetailsCriteria = (state: AuthState, getters): Array<boolean> => {
	const company = getters.currentCompany
	if (!company) return []
	return [!!company.address, !!company.postalCode, !!company.place, !!company.getCountry(), !!company.phoneNumber]
}

export const vesselDetailsCriteria = (state: AuthState, getters): Array<boolean> => {
	const vessel = getters.ownVessel as Vessel | null
	if (!vessel) return []
	return [!!vessel.skipperName, !!vessel.tonnage, !!vessel.length, !!vessel.euNumber, !!vessel.name, !!vessel.email]
}

export const additionalVesselDetailsCriteria = (state: AuthState, getters): Array<boolean> => {
	const vessel = getters.ownVessel as Vessel | null
	if (!vessel) return []
	return [
		!!vessel.width,
		!!vessel.draught,
		!!vessel.phoneNumber,
		!!vessel.emptyWeight,
		!!vessel.insurance,
		!!vessel.mmsi
	]
}

export const vesselAttributesCriteria = (state: AuthState, getters): Array<boolean> => {
	const vessel = getters.ownVessel as Vessel | null
	if (!vessel) return []
	return attributesRequiredInProfileCalculationShip.map((attr) => !!vessel.getVesselAttribute(attr)?.value)
}

export const additionalVesselAttributesCriteria = (state: AuthState, getters): Array<boolean> => {
	const vessel = getters.ownVessel as Vessel | null
	if (!vessel) return []
	return attributesIncludedInProfileCalculationShip.map((attr) => !!vessel.getVesselAttribute(attr)?.value)
}

export const vesselPreferencesCriteria = (state: AuthState, getters): Array<boolean> => {
	const vessel = getters.ownVessel as Vessel | null
	if (!vessel) return []
	return attributesIncludedInProfileCalculationPreferences.map((attr) => !!vessel.getVesselAttribute(attr)?.value)
}

export const vesselDocumentsCriteria = (state: AuthState, getters): Array<boolean> => {
	const vessel = getters.ownVessel as Vessel | null
	if (!vessel) return []
	return attributesIncludedInProfileCalculationDocuments.map((attr) => !!vessel.getVesselAttribute(attr)?.value)
}

export const profileMinimumCompletenessCriteria = (state: AuthState, getters): Array<boolean> => {
	return [
		...getters.userDetailsCriteria,
		...getters.companyDetailsCriteria,
		...getters.vesselDetailsCriteria,
		...getters.vesselAttributesCriteria
	]
}

export const profileAdditionalCompletenessCriteria = (state: AuthState, getters): Array<boolean> => {
	return [
		...getters.additionalCompanyDetailsCriteria,
		...getters.additionalVesselDetailsCriteria,
		...getters.additionalVesselAttributesCriteria,
		...getters.vesselPreferencesCriteria,
		...getters.vesselDocumentsCriteria
	]
}

export const requiredProfileFieldsComplete = (state: AuthState, getters): boolean => {
	return !getters.isSkipper || getters.profileMinimumCompletenessCriteria.every(Boolean)
}

export const profileComplete = (state: AuthState, getters): boolean => {
	return (
		!getters.isSkipper ||
		(getters.profileMinimumCompletenessCriteria.every(Boolean) &&
			getters.profileAdditionalCompletenessCriteria.every(Boolean))
	)
}

export const profileCompletenessPercent = (state: AuthState, getters): number => {
	const criteria = [...getters.profileMinimumCompletenessCriteria, ...getters.profileAdditionalCompletenessCriteria]
	return (criteria.reduce((acc, cur) => acc + (cur ? 1 : 0), 0) / criteria.length) * 100
}

export const pageWithMissingProfileData = (state: AuthState, getters): string => {
	if (!getters.userDetailsCriteria.every(Boolean)) return 'profile.user'
	if (!getters.companyDetailsCriteria.every(Boolean)) return 'profile.company.index'
	if (!getters.vesselDetailsCriteria.every(Boolean)) return 'profile.vessel.index'
	if (!getters.vesselDocumentsCriteria.every(Boolean)) return 'profile.vessel.documents'
	if (!getters.vesselAttributesCriteria.every(Boolean)) return 'profile.vessel.preferences'
	if (!getters.vesselPreferencesCriteria.every(Boolean)) return 'profile.vessel.general'
	return 'profile.user'
}

export const currentCompany = (state: AuthState): Company => {
	return state.companies[0]
}

export const navigationItems = (state: AuthState, getters): Array<NavigationItem> => {
	return [
		{
			title: i18n.t('navigation.map').toString(),
			icon: 'map-signs',
			routeName: 'map',
			slug: 'map',
			hasPermission: getters.hasPermission('maps.index'),
			hasMembership: getters.hasMapPageAccessMembership
		},
		{
			title: i18n.t('navigation.cargo').toString(),
			icon: 'cubes',
			routeName: 'cargo',
			slug: 'cargo',
			hasPermission: getters.hasPermission('offers.index'),
			hasMembership: getters.hasCargoPageAccessMembership
		},
		{
			title: i18n.t('navigation.capacityRadar').toString(),
			icon: 'radar-solid',
			routeName: 'capacity-radar',
			slug: 'capacity-radar',
			hasPermission: true,
			hasMembership: getters.hasCapacityRadarMembership
		},
		{
			title: i18n.t('navigation.transport').toString(),
			icon: 'ship',
			routeName: 'transports',
			slug: 'transport',
			hasPermission: getters.hasPermission('transports.index'),
			hasMembership: getters.hasTransportPageAccessMembership
		},
		{
			title: i18n.t('navigation.loading_bill').toString(),
			icon: 'receipt-long',
			routeName: 'loading-bill',
			slug: 'loading-bill',
			hasPermission: true,
			hasMembership: getters.hasLoadingBillMembership
		},
		{
			title: i18n.t('navigation.terminals').toString(),
			icon: 'tour',
			routeName: 'terminals',
			slug: 'terminals',
			hasPermission: true,
			hasMembership: getters.hasTerminalPageAccessMembership
		},
		{
			title: i18n.t('navigation.depot').toString(),
			icon: 'warehouse',
			routeName: 'depot',
			slug: 'depot',
			hasPermission: true,
			hasMembership: getters.hasDPTMembership
		},
		{
			title: i18n.t('navigation.messages').toString(),
			icon: 'message-bubble',
			routeName: 'messages',
			slug: 'message',
			hasPermission: getters.hasPermission('messages.index'),
			hasMembership: getters.hasMessageAccessMembership
		},
		{
			title: i18n.t('navigation.relation').toString(),
			icon: 'address-book',
			routeName: 'relation-management',
			slug: 'relation-management',
			hasPermission: true,
			hasMembership: getters.hasRelationManagementMembership
		},
		{
			title: i18n.t('navigation.groups').toString(),
			icon: 'star',
			routeName: 'groups',
			slug: 'group',
			hasPermission: getters.hasPermission('fleetMembers.index'),
			hasMembership: getters.hasGroupsPageAccessMembership
		},
		{
			title: i18n.t('navigation.dashboardInsights').toString(),
			icon: 'dashboard',
			routeName: 'dashboard-selection',
			slug: 'dashboard',
			hasPermission: getters.hasPermission('insights.index'),
			hasMembership: getters.hasInsightPageAccessMembership
		},
		{
			title: i18n.t('navigation.financial').toString(),
			icon: 'euro-sign',
			routeName: 'invoicing',
			slug: 'invoicing',
			hasPermission: true,
			hasMembership: getters.hasAccountingMembership,
			additionalFilter: () => getters.hasInternalFinancialConnection
		}
	].filter(
		(navigationItem) =>
			navigationItem.hasPermission &&
			navigationItem.hasMembership &&
			(navigationItem.additionalFilter ? navigationItem.additionalFilter() : true)
	)
}
