import { Alert } from './Alert'
import { BillOfLading } from '@/models/BillOfLading'
import { CargoProject } from '@/models/CargoProject'
import CargoSizeQuantityUnit from '@/enums/CargoSizeQuantityUnit'
import { CargoTerms } from '@/models/CargoTerms'
import { CargoType } from '@/models/CargoType'
import { Company } from '@/models/Company'
import ContainerStatus from '@/enums/ContainerStatus'
import { CustomLocation } from '@/models/CustomLocation'
import { DeliveryTerms } from '@/models/DeliveryTerms'
import { GeoJSON } from 'geojson'
import { Model } from '@/models/Model'
import { Offer } from '@/models/Offer'
import { Order } from '@/models/Order'
import Property from '@/decorators/Property'
import { Terminal } from '@/models/Terminal'
import { TerminalSrsLocation } from '@/models/TerminalSrsLocation'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import TransportType from '@/enums/TransportType'
import TransportationType from '@/enums/TransportationType'
import User from '@/models/User'

export class Cargo extends Model {
	protected jsonApiType = 'cargos'

	@Property()
	public transport!: TransportType

	@Property()
	public transportationType!: TransportationType

	@Property()
	public clientOrderReference: string = ''

	@Property()
	public clientReference: string = ''

	@Property()
	public belongsToProject!: boolean

	@Property('isBluePrint')
	public isBlueprint?: boolean

	@Property('isCreatedFromBlueprint')
	public isCreatedFromBlueprint?: boolean

	@Property()
	public loadPeriodUntilDate!: string | null

	@Property()
	public loadPeriodUntilTime!: string | null

	@Property()
	public loadPeriodFromDate!: string | null

	@Property()
	public loadPeriodFromTime!: string | null

	@Property()
	public unloadPeriodUntilDate!: string | null

	@Property()
	public unloadPeriodUntilTime!: string | null

	@Property()
	public unloadPeriodFromDate!: string | null

	@Property()
	public unloadPeriodFromTime!: string | null

	@Property()
	public requiredCertificationTypeId!: Array<string>

	@Property()
	public approximateCargoSize!: boolean | null

	@Property()
	public cargoSizeUnit!: CargoSizeQuantityUnit

	@Property()
	public cargoSizeEndingAt!: string

	@Property()
	public cargoSizeStartingAt!: string

	@Property()
	public specificWeight!: number

	@Property()
	public specificWeightTypeId!: number

	@Property()
	public isCustomProduct!: number

	@Property()
	public customProduct!: string | null

	@Property()
	public isConcept!: boolean

	@Property()
	public teuQuantity!: number | null

	@Property()
	public teuQuantityMax!: number | null

	@Property()
	public containerStatus!: ContainerStatus | null

	@Property()
	public priceExtraHours!: number

	@Property()
	public maxExtraHoursEqualsDayRental!: number

	@Property()
	public maxBerthFeeEqualsDayRental!: number

	@Property()
	public extendedRentalExpected!: number

	@Property()
	public requiredTransferTimeId!: number

	@Property()
	public customStartLocationId!: number | null

	@Property()
	public customStartLocationCompanyName!: string

	@Property()
	public customStartLocationGeo!: string | GeoJSON.Point | null

	@Property()
	public customStartLocationPort!: string

	@Property()
	public customStartLocationCountryCode!: string | null

	@Property()
	public customStartLocationPlace!: string | null

	@Property()
	public customStartLocationPhoneNumber!: string

	@Property()
	public loadOptions!: string

	@Property()
	public loadDeadline!: string

	@Property()
	public unloadDeadline!: string

	@Property()
	public customDestinationLocationId!: number | null

	@Property()
	public customDestinationCompanyName!: string

	@Property()
	public customDestinationGeo!: string | GeoJSON.Point | null

	@Property()
	public customDestinationPort!: string

	@Property()
	public customDestinationCountryCode!: string | null

	@Property()
	public customDestinationPlace!: string | null

	@Property()
	public customDestinationPhoneNumber!: string

	@Property()
	public unloadOptions!: string

	@Property()
	public reportDay!: string

	@Property()
	public quantity!: string

	@Property()
	public product!: string

	@Property()
	public startPlace!: string

	@Property()
	public destinationPlace!: string

	@Property()
	public distance!: number

	@Property()
	public loadTimeFrame!: string

	@Property()
	public loadTimeFrameStart!: string

	@Property()
	public loadTimeFrameEnd!: string

	@Property()
	public loadingIsReportingDate!: boolean

	@Property()
	public unloadTimeFrame!: string

	@Property()
	public unloadTimeFrameStart!: string

	@Property()
	public unloadTimeFrameEnd!: string

	@Property()
	public unloadingIsReportingDate!: boolean

	@Property()
	public reportUponArrival!: boolean

	@Property()
	public dossierNumber!: string

	@Property()
	public productRemark!: string

	@Property()
	public loadingPlaceRemark!: string

	@Property()
	public unloadingPlaceRemark!: string

	public creator(): ToOneRelation {
		return this.hasOne(User, 'creator')
	}

	public getCreator(): User {
		return this.getRelation('creator')
	}

	public alerts(): ToManyRelation {
		return this.hasMany(Alert, 'alerts')
	}

	public getAlerts(): Array<Alert> {
		return this.getRelation('alerts') || []
	}

	private cargoType(): ToOneRelation {
		return this.hasOne(CargoType, 'cargoType')
	}

	public getCargoType(): CargoType {
		return this.getRelation('cargoType')
	}

	public setCargoType(type: CargoType | null) {
		this.setRelation('cargoType', type)
	}

	private cargoProject(): ToOneRelation {
		return this.hasOne(CargoProject, 'cargoProject')
	}

	public getCargoProject(): CargoProject {
		return this.getRelation('cargoProject')
	}

	public setCargoProject(project: CargoProject | null): void {
		this.setRelation('cargoProject', project)
	}

	private offer(): ToOneRelation {
		return this.hasOne(Offer, 'offer')
	}

	public getOffer(): Offer {
		return this.getRelation('offer')
	}

	private company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company {
		return this.getRelation('company')
	}

	private client(): ToOneRelation {
		return this.hasOne(Company, 'client')
	}

	public getClient(): Company | null {
		return this.getRelation('client') || null
	}

	public setClient(company: Company): void {
		this.setRelation('client', company)
	}

	public charterTerms(): ToOneRelation {
		return this.hasOne(CargoTerms, 'charterTerms')
	}

	public getCharterTerms(): CargoTerms {
		return this.getRelation('charterTerms')
	}

	public setCharterTerms(terms: CargoTerms): void {
		return this.setRelation('charterTerms', terms)
	}

	private orderTerms(): ToOneRelation {
		return this.hasOne(CargoTerms, 'orderTerms')
	}

	public getOrderTerms(): CargoTerms {
		return this.getRelation('orderTerms')
	}

	public setOrderTerms(terms: CargoTerms | null): void {
		return this.setRelation('orderTerms', terms)
	}

	public deliveryTerms(): ToOneRelation {
		return this.hasOne(DeliveryTerms, 'deliveryTerms')
	}

	public getDeliveryTerms(): DeliveryTerms | null {
		return this.getRelation('deliveryTerms') ?? null
	}

	public setDeliveryTerms(terms: DeliveryTerms | null): void {
		return this.setRelation('deliveryTerms', terms)
	}

	public frequentlyUsedExtractionAreas(): ToManyRelation {
		return this.hasMany(BillOfLading, 'frequentlyUsedExtractionAreas')
	}

	public getFrequentlyUsedExtractionAreas(): BillOfLading {
		return this.getRelation('frequentlyUsedExtractionAreas')
	}

	public frequentlyUsedExtractors(): ToManyRelation {
		return this.hasMany(BillOfLading, 'frequentlyUsedExtractors')
	}

	public getFrequentlyUsedExtractors(): BillOfLading {
		return this.getRelation('frequentlyUsedExtractors')
	}

	public startTerminal(): ToOneRelation {
		return this.hasOne(Terminal, 'startTerminal')
	}

	public getStartTerminal(): Terminal {
		return this.getRelation('startTerminal')
	}

	public setStartTerminal(terminal: Terminal | null): void {
		return this.setRelation('startTerminal', terminal)
	}

	public startTerminalSrsLocation(): ToOneRelation {
		return this.hasOne(TerminalSrsLocation, 'startTerminalSrsLocation')
	}

	public getStartTerminalSrsLocation(): TerminalSrsLocation {
		return this.getRelation('startTerminalSrsLocation')
	}

	public setStartTerminalSrsLocation(terminalSrsLocation: TerminalSrsLocation | null): void {
		return this.setRelation('startTerminalSrsLocation', terminalSrsLocation)
	}

	public destinationTerminal(): ToOneRelation {
		return this.hasOne(Terminal, 'destinationTerminal')
	}

	public getDestinationTerminal(): Terminal {
		return this.getRelation('destinationTerminal')
	}

	public setDestinationTerminal(terminal: Terminal | null): void {
		return this.setRelation('destinationTerminal', terminal)
	}

	private destinationTerminalSrsLocation(): ToOneRelation {
		return this.hasOne(TerminalSrsLocation, 'destinationTerminalSrsLocation')
	}

	public getDestinationTerminalSrsLocation(): TerminalSrsLocation {
		return this.getRelation('destinationTerminalSrsLocation')
	}

	public setDestinationTerminalSrsLocation(terminalSrsLocation: TerminalSrsLocation | null): void {
		return this.setRelation('destinationTerminalSrsLocation', terminalSrsLocation)
	}

	private customStartLocation(): ToOneRelation {
		return this.hasOne(CustomLocation, 'customStartLocation')
	}

	public getCustomStartLocation(): CustomLocation {
		return this.getRelation('customStartLocation')
	}

	public setCustomStartLocation(customLocation: CustomLocation | null): void {
		return this.setRelation('customStartLocation', customLocation)
	}

	private customDestinationLocation(): ToOneRelation {
		return this.hasOne(CustomLocation, 'customDestinationLocation')
	}

	public getCustomDestinationLocation(): CustomLocation {
		return this.getRelation('customDestinationLocation')
	}

	public setCustomDestinationLocation(customLocation: CustomLocation | null): void {
		return this.setRelation('customDestinationLocation', customLocation)
	}

	private order(): ToOneRelation {
		return this.hasOne(Order, 'order')
	}

	public startLocationCity() {
		if (this.getCustomStartLocation()) {
			return this.getCustomStartLocation().city
		} else if (this.getStartTerminal()) {
			return this.getStartTerminal().place
		} else if (this.getStartTerminalSrsLocation()) {
			return this.getStartTerminalSrsLocation().place
		}
	}

	public destinationLocationCity() {
		if (this.getCustomDestinationLocation()) {
			return this.getCustomDestinationLocation().city
		} else if (this.getDestinationTerminal()) {
			return this.getDestinationTerminal().place
		} else if (this.getDestinationTerminalSrsLocation()) {
			return this.getDestinationTerminalSrsLocation().place
		}
	}

	public static async fetchLazy(id): Promise<Cargo> {
		const store = (await import('@/store/index')).store
		if (!store.getters['cargos/hasCached'](id)) {
			await store.dispatch('cargos/findRecord', id)
		}

		return store.getters['cargos/peekRecord'](id)
	}
}
