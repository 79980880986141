import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import ConditionType from '@/enums/ConditionType'

export class CargoCondition extends Model {
	protected jsonApiType = 'cargoConditions'

	@Property()
	public title!: string

	@Property()
	public slug!: string

	@Property()
	public sequence!: number

	@Property()
	public category!: ConditionType

	public static async fetchAllLazy(): Promise<Array<CargoCondition>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['berthFees/hasAllFetched']()) {
			await store.dispatch('berthFees/findAll')
		}

		return store.getters['berthFees/peekAll']()
	}

	public static async fetchLazy(id): Promise<CargoCondition> {
		const store = (await import('@/store/index')).store
		if (!store.getters['berthFees/hasCached'](id)) {
			await store.dispatch('berthFees/findRecord', id)
		}

		return store.getters['berthFees/peekRecord'](id)
	}
}
