import { Bunker } from '@/models/Bunker'
import { Company } from '@/models/Company'
import { Country } from '@/models/Country'
import { GeoJSON } from 'geojson'
import { MappableModel } from '@/interfaces/MappableModel'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'

export class Terminal extends Model implements MappableModel {
	protected jsonApiType = 'terminals'

	private country(): ToOneRelation {
		return this.hasOne(Country, 'country')
	}

	public getCountry(): Country {
		return this.getRelation('country')
	}

	public setCountry(country: Country): void {
		this.setRelation('country', country)
	}

	private company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company {
		return this.getRelation('company')
	}

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoLocation')
	}

	public getName(): string {
		return this.getAttribute('name')
	}

	public extractionAreas(): ToManyRelation {
		return this.hasMany(Bunker, 'extractionAreas')
	}

	@Property()
	public name?: string

	@Property()
	public address?: string

	@Property()
	public place?: string

	@Property()
	public postalCode?: string

	@Property()
	public geoCoordinates!: string | GeoJSON.Point

	@Property()
	public phoneNumbers?: Array<any>

	@Property()
	public primaryEmail?: string

	@Property()
	public website?: string

	@Property()
	public comments?: string

	@Property()
	public harbour?: string

	@Property()
	public harbourNumberFrom?: string

	@Property()
	public harbourNumberUntil?: string

	@Property()
	public waterwayName?: string

	@Property('kmNumber')
	public waterwayKmNumber?: string

	@Property()
	public locationCode?: string

	@Property()
	public canTransferContainer?: boolean

	@Property()
	public canStoreContainer?: boolean

	@Property()
	public canTransferBulk?: boolean

	@Property()
	public canStoreBulk?: boolean

	@Property()
	public quayLength?: number

	@Property()
	public isManaged?: boolean

	@Property()
	public isPublic?: boolean

	public static async fetchLazy(id): Promise<Terminal | null> {
		if (!id) {
			return Promise.resolve(null)
		}
		const store = (await import('@/store/index')).store
		if (!store.getters['terminals/hasCached'](id)) {
			await store.dispatch('terminals/findRecord', id)
		}

		return store.getters['terminals/peekRecord'](id)
	}
}
