import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { VesselLog } from '@/models/VesselLog'
import { Vessel } from '@/models/Vessel'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { Document } from '@/models/Document'
import { Transport } from '@/models/Transport'

export class GasOilBunkered extends Model {
	protected jsonApiType = 'gasOilsBunkered'

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.relation('vessel')
	}

	private transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	private vesselLog(): ToOneRelation {
		return this.hasOne(VesselLog, 'vesselLog')
	}

	public getVesselLog(): VesselLog {
		return this.relation('vesselLog')
	}

	public documents(): ToManyRelation {
		return this.hasMany(Document, 'documents')
	}

	public getDocuments(): Array<Document> {
		return this.getRelation('documents') || []
	}

	@Property('gasOilBunkered')
	public gasOilBunkeredQuantity!: number

	@Property('pricePerCubic')
	public gasOilPricePerCubic!: number

	@Property('dateOfBunker')
	public dateOfBunkering!: string
}
