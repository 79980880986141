import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { NetworkInformationIsrses } from '@/models/NetworkInformationIsrses'

export class Isrs extends Model {
	protected jsonApiType = 'isrses'

	private networkInformation(): ToOneRelation {
		return this.hasOne(NetworkInformationIsrses, 'networkInformation')
	}
}
