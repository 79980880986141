import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { FavouriteGroupMember } from '@/models/FavouriteGroupMember'

export class FavouriteGroup extends Model {
	protected jsonApiType: string = 'favouriteGroups'

	public members(): ToManyRelation {
		return this.hasMany(FavouriteGroupMember, 'members')
	}

	public getMembers(): Array<FavouriteGroupMember> {
		return this.getRelation('members') || []
	}

	@Property('groupName')
	public name!: string

	@Property()
	public groupType!: string

	@Property()
	public memberCount!: number

    public static async fetchAllLazy(): Promise<Array<FavouriteGroup>> {
        const store = (await import('@/store/index')).store
        if (!store.getters['favouriteGroups/hasAllFetched']()) {
            await store.dispatch('favouriteGroups/findAll')
        }

        return store.getters['favouriteGroups/peekAll']()
    }

    public static async fetchLazy(id): Promise<FavouriteGroup> {
        const store = (await import('@/store/index')).store
        if (!store.getters['favouriteGroups/hasCached'](id)) {
            await store.dispatch('favouriteGroups/findRecord', id)
        }

        return store.getters['favouriteGroups/peekRecord'](id)
    }
}
