import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { GeoJSON } from 'geojson'

export class GeographicAreas extends Model {
	protected jsonApiType = 'geographicAreas'

	@Property()
	public name!: string

	@Property()
	public location!: GeoJSON.GeoJSON
}
