import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Cargo } from '@/models/Cargo'
import { Offer } from '@/models/Offer'
import { Bid } from '@/models/Bid'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { Conversation } from '@/models/Conversation'
import Property from '@/decorators/Property'

export class ConversationContext extends Model {
	protected jsonApiType = 'conversationContexts'
	protected static pageSize = 25

	@Property()
	public readonly title!: string

	public conversations(): ToManyRelation {
		return this.hasMany(Conversation, 'conversations')
	}

	public getConversations(): Array<Conversation> {
		return this.getRelation('conversations') || []
	}

	public addConversation(conversation: Conversation) {
		this.setRelation('conversations', [...this.getConversations(), conversation])
	}

	public cargo(): ToOneRelation {
		return this.hasOne(Cargo, 'cargo')
	}

	public getCargo(): Cargo {
		return this.getRelation('cargo')
	}

	public setCargo(cargo: Cargo): void {
		this.setRelation('cargo', cargo)
	}

	public offer(): ToOneRelation {
		return this.hasOne(Offer, 'offer')
	}

	public getOffer(): Offer {
		return this.getRelation('offer')
	}

	public setOffer(offer: Offer): void {
		this.setRelation('offer', offer)
	}

	public bid(): ToOneRelation {
		return this.hasOne(Bid, 'bid')
	}

	public getBid(): Bid {
		return this.getRelation('bid')
	}

	public setBid(bid: Bid): void {
		this.setRelation('bid', bid)
	}
}
