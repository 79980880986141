import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { CargoType } from '@/models/CargoType'
import { Vessel } from '@/models/Vessel'
import { Terminal } from '@/models/Terminal'
import Property from '@/decorators/Property'
import DeliveryMethod from '@/enums/DeliveryMethod'
import TransshipmentDirection from '@/types/TransshipmentDirection'
import { Bunker } from '@/models/Bunker'
import TransportLocationQuantityUnit from '@/enums/TransportLocationQuantityUnit'

export class TerminalVisit extends Model {
	protected jsonApiType = 'terminalVisits'

	@Property()
	public transshipmentDirection!: TransshipmentDirection

	@Property()
	public deliveryMethod!: DeliveryMethod

	@Property()
	public quantity!: number

	@Property()
	public quantityUnit!: TransportLocationQuantityUnit

	@Property()
	public license!: string

	public product(): ToOneRelation<CargoType> {
		return this.hasOne(CargoType, 'product')
	}

	public getProduct(): CargoType {
		return this.getRelation('product')
	}

	public vessel(): ToOneRelation<Vessel> {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.getRelation('vessel')
	}

	public terminal(): ToOneRelation<Terminal> {
		return this.hasOne(Terminal, 'terminal')
	}

	public getTerminal(): Terminal {
		return this.getRelation('terminal')
	}

	public extractionArea(): ToOneRelation<Bunker> {
		return this.hasOne(Bunker, 'extractionArea')
	}

	public getExtractionArea(): Bunker | null {
		return this.getRelation('extractionArea') ?? null
	}
}
