import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import PriceUnit from '@/enums/PriceUnit'
import OfferReplyType from '@/enums/OfferReplyType'

export class OfferReply extends Model {
	protected jsonApiType = 'offerReplies'

	@Property()
	public replyType!: OfferReplyType

	@Property()
	public userFullName!: string

	@Property()
	public vesselName!: string

	@Property()
	public companyName!: string

	@Property()
	public price!: number | null

	@Property()
	public priceUnit!: PriceUnit | null

	@Property('newMessages')
	public newOfferReplyRelatedMessages!: number | null
}
