import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { CargoType } from '@/models/CargoType'
import Property from '@/decorators/Property'
import BillOfLadingQuantityUnit from '@/enums/BillOfLadingQuantityUnit'

export class BillOfLadingProduct extends Model {
	protected jsonApiType = 'billOfLadingProducts'

	@Property()
	public quantity!: number

	@Property()
	public quantityUnit!: BillOfLadingQuantityUnit

	private cargoType(): ToOneRelation {
		return this.hasOne(CargoType, 'cargoType')
	}

	public getCargoType(): CargoType {
		return this.getRelation('cargoType')
	}
}
