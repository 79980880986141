import { ViewportState } from '@/interfaces/ViewportState'

export const isMobile = (state: ViewportState): boolean => {
	return state.width < Number(process.env.VUE_APP_BREAKPOINT_DESKTOP)
}

// for breakpoint values see _breakpoints.scss
export const isLg = (state: ViewportState): boolean => {
	return state.width >= 1264
}
export const isMd = (state: ViewportState): boolean => {
	return state.width >= 960 && state.width < 1264
}
