import { GeoJSON } from 'geojson'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Lock } from '@/models/Lock'
import { CustomLocation } from '@/models/CustomLocation'
import { Berth } from '@/models/Berth'
import { GasOilBunkered } from '@/models/GasOilBunkered'
import { Terminal } from '@/models/Terminal'
import { BunkerStation } from '@/models/BunkerStation'
import { Vessel } from '@/models/Vessel'
import { TerminalVisitAdditionalInfo } from '@/models/TerminalVisitAdditionalInfo'
import moment from 'moment'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { VesselLogInput } from '@/models/VesselLogInput'

export class VesselLog extends Model {
	protected jsonApiType = 'vesselLogs'
	protected static pageSize = 25

	private lock(): ToOneRelation {
		return this.hasOne(Lock, 'lock')
	}

	public getLock(): Lock {
		return this.getRelation('lock')
	}

	private terminal(): ToOneRelation {
		return this.hasOne(Terminal, 'terminal')
	}

	public getTerminal(): Terminal {
		return this.getRelation('terminal')
	}

	private berth(): ToOneRelation {
		return this.hasOne(Berth, 'berth')
	}

	public getBerth(): Berth {
		return this.getRelation('berth')
	}

	private bunkerStation(): ToOneRelation {
		return this.hasOne(BunkerStation, 'bunkerStation')
	}

	public getBunkerStation(): BunkerStation {
		return this.getRelation('bunkerStation')
	}

	private customLocation(): ToOneRelation {
		return this.hasOne(CustomLocation, 'customLocation')
	}

	public getCustomLocation(): CustomLocation {
		return this.getRelation('customLocation')
	}

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.relation('vessel')
	}

	private terminalVisitAdditionalInfo(): ToOneRelation {
		return this.hasOne(TerminalVisitAdditionalInfo, 'terminalVisitAdditionalInfo')
	}

	public getTerminalVisitAdditionalInfo(): TerminalVisitAdditionalInfo {
		return this.getRelation('terminalVisitAdditionalInfo')
	}

	public vesselLogInputs(): ToManyRelation {
		return this.hasMany(VesselLogInput, 'vesselLogInputs')
	}

	public getVesselLogInputs(): Array<VesselLogInput> {
		return this.getRelation('vesselLogInputs') || []
	}

	private gasOilBunkered(): ToOneRelation {
		return this.hasOne(GasOilBunkered, 'gasOilBunkered')
	}

	public getGasOilBunkered(): GasOilBunkered {
		return this.getRelation('gasOilBunkered')
	}

	public get formattedTimePeriod(): string {
		return moment(this.arrivalTime).format('HH:mm') + ' - ' + moment(this.departureTime).format('HH:mm')
	}

	public get formattedDepartureDate(): string {
		return moment(this.departureTime).format('ll')
	}

	public get formattedDateNextVesselLog(): string {
		return moment(this.dateOfNextVesselLog).format('ll')
	}

	@Property('actionLog')
	public facilityType!: string

	@Property()
	public mmsi!: string

	@Property()
	public arrivalTime!: string

	@Property()
	public departureTime!: string

	@Property()
	public dateOfNextVesselLog!: string

	@Property()
	public durationInHours!: number

	@Property()
	public arrivalLocationGeo!: string | GeoJSON.Point

	@Property()
	public departureLocationGeo!: string | GeoJSON.Point

	public getFacilityName(): string {
		switch (this.facilityType) {
			case 'lock':
				return this.getLock().getName()
			case 'berth':
				return this.getBerth().getName()
			case 'terminal':
				return this.getTerminal().getName()
			case 'bunker_station':
				return this.getBunkerStation().getName()
			case 'custom_location':
				return this.getCustomLocation().getName()
		}
		return ''
	}

	public getFacilityType(): string {
		switch (this.facilityType) {
			case 'lock':
				return 'locks'
			case 'berth':
				return 'berths'
			case 'terminal':
				return 'terminals'
			case 'bunker_station':
				return 'bunkerStations'
			case 'custom_location':
				return 'customLocations'
		}
		return ''
	}

	public getFacilityApiId(): string {
		switch (this.facilityType) {
			case 'lock':
				return this.getLock().getApiId() as string
			case 'berth':
				return this.getBerth().getApiId() as string
			case 'terminal':
				return this.getTerminal().getApiId() as string
			case 'bunker_station':
				return this.getBunkerStation().getApiId() as string
			case 'custom_location':
				return this.getCustomLocation().getApiId() as string
		}
		return ''
	}

	public get facilityIcon(): string {
		switch (this.facilityType) {
			case 'lock':
				return 'angle-double-up'
			case 'berth':
				return 'anchor'
			case 'terminal':
				return 'industry'
			case 'bunker_station':
				return 'gas-pump'
			case 'custom_location':
				return 'industry'
		}
		return 'ship'
	}

	public get facilityColor(): string {
		switch (this.facilityType) {
			case 'lock':
				return '#F5A623'
			case 'berth':
				return '#7ED321'
			case 'terminal':
				return '#D0021B'
			case 'bunker_station':
				return '#4D4E4F'
			case 'custom_location':
				return '#D0021B'
		}
		return 'grey'
	}
}
