import { Model } from '@/models/Model'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'
import moment from 'moment'

export class AisPosition extends Model implements MappableModel {
	protected jsonApiType = 'aisPositions'

	public getLocation(): GeoJSON.Geometry {
		return this.geometry
	}

	public getName(): string {
		return this.mmsi
	}

	public getLastSeen(): string {
		return moment(this.positionDateTime).format('dd DD MMM - HH:mm')
	}

	public getLastSeenFormatted(): string {
		const [datepart, timepart] = this.getLastSeen().split(' - ')
		const dayofweek = datepart.split(' ')[0]

		const date = datepart.replace(dayofweek, `<b>${dayofweek.toUpperCase()}</b>`)
		const time = timepart.replace(':', '.')

		return `${date} - <b>${time}</b>`
	}

	@Property()
	public mmsi!: string

	@Property()
	public geometry!: GeoJSON.Geometry

	@Property()
	public courseOverGround!: number

	@Property()
	public speedOverGround!: number

	@Property()
	public positionDateTime!: string
}
