import { ActionContext } from 'vuex'
import { DirectionPayload } from '@/directions/DirectionPayload'
import { Facility } from '@/models/Facility'
import { LatLngBounds } from 'leaflet'
import { MapState } from '@/interfaces/MapState'
import Notification from '@/classes/Notification'
import { PluralResponse } from 'coloquent'
import { RootState } from '@/store/RootState'
import { RoutePlanner } from '@/models/RoutePlanner'
import { Vessel } from '@/models/Vessel'
import i18n from '@/i18n/index'
import { Builder } from 'coloquent/dist/Builder'

export const setFacilities = async (
	context: ActionContext<MapState, RootState>,
	facilities: Array<Facility>
): Promise<void> => {
	context.commit('setFacilities', facilities)
}

export const setVessels = async (
	context: ActionContext<MapState, RootState>,
	vessels: Array<Vessel>
): Promise<void> => {
	context.commit('setVessels', vessels)
}

export const setQuery = async (context: ActionContext<MapState, RootState>, query: string | null): Promise<void> => {
	context.commit('setQuery', query)
}

export const setBoundingBox = async (
	context: ActionContext<MapState, RootState>,
	boundingBox: LatLngBounds | null
): Promise<void> => {
	context.commit('setBoundingBox', boundingBox)
}

export const calculateDirections = async (
	context: ActionContext<MapState, RootState>,
	directionPayload: DirectionPayload
): Promise<void> => {
	context.commit('setRoutesLoading', true)
	context.commit('setVesselDimensions', null)

	const { origin, destination } = directionPayload

	let builder = RoutePlanner.option('type', 'cost').option('transportType', 'vessel')

	builder = addQueryParameters(builder, origin, destination)
	builder = addVesselProperties(builder, directionPayload)
	builder = addMembershipOptions(builder, context, directionPayload)
	builder = addExploitationOption(builder, directionPayload)
	builder = addVesselIdOption(builder, context)

	try {
		const routes = await fetchRoutes(builder)
		const shipRoutes = routes.filter((route) => route.routeType === 'vessel')
		context.commit('setRoutes', shipRoutes)
		context.commit('setSelectedRoute', shipRoutes[0])
		context.commit('setVesselDimensions', {
			width: directionPayload.width,
			length: directionPayload.length,
			height: directionPayload.height,
			draught: directionPayload.draught
		})
	} catch {
		Notification.Error(i18n.t('map.directions.no_route_found').toString())
	}

	context.commit('setRoutesLoading', false)
}

const addQueryParameters = (builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>, origin, destination) => {
	Object.keys([origin, destination]).forEach((key, index) => {
		;[origin, destination][key].getQueryParameters(index).forEach((parameter) => {
			builder = builder.option(parameter.key, parameter.value)
		})
	})
	return builder
}

const addVesselProperties = (builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>, directionPayload) => {
	const properties = ['width', 'length', 'height', 'draught', 'maxLoadCapacity', 'loadedQuantity']
	properties.forEach((prop) => {
		if (directionPayload[prop]) {
			builder = builder.option(`vesselProperties[${prop}]`, String(directionPayload[prop]))
		}
	})
	if (directionPayload.startDate) {
		builder = builder.option('startDateTime', directionPayload.startDate.toISOString())
	}
	return builder
}

const addMembershipOptions = (
	builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>,
	context,
	directionPayload
) => {
	if (context.rootGetters['auth/hasRoutePROMembership']) {
		builder = addPROMembershipOptions(builder, directionPayload)
	}
	if (context.rootGetters['auth/hasRouteCostQuotationMembership']) {
		builder = addCostQuotationMembershipOptions(builder, directionPayload)
	}
	return builder
}

const addPROMembershipOptions = (builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>, directionPayload) => {
	const options = ['withMultipleRoutes', 'optimizeBy', 'withOperatingTimes', 'withClosures', 'withTides']
	options.forEach((option) => {
		if (directionPayload[option]) {
			builder = builder.option(option, String(directionPayload[option]))
		}
	})
	return builder
}

const addCostQuotationMembershipOptions = (
	builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>,
	directionPayload
) => {
	const options = ['withEmptyReturn', 'loadingTimeHours', 'unloadingTimeHours']
	options.forEach((option) => {
		if (directionPayload[option]) {
			builder = builder.option(option, String(directionPayload[option]))
		}
	})
	return builder
}

const addExploitationOption = (builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>, directionPayload) => {
	if (directionPayload.exploitation) {
		builder = builder.option('exploitation', String(directionPayload.exploitation))
	}
	return builder
}

const addVesselIdOption = (builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>, context) => {
	const vessels = context.rootState.auth.vessels
	if (vessels.length) {
		builder = builder.option('vesselId', vessels[0])
	}
	return builder
}

const fetchRoutes = async (builder: Builder<RoutePlanner, PluralResponse<RoutePlanner>>) => {
	const response = (await builder.get()) as PluralResponse
	return response.getData() as Array<RoutePlanner>
}

export const setRoutes = async (
	context: ActionContext<MapState, RootState>,
	routes: Array<RoutePlanner>
): Promise<void> => {
	context.commit('setRoutes', routes)
}
