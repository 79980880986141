
import { Component, Vue } from 'vue-property-decorator'
import Authentication from '@aws-amplify/auth'
import { namespace } from 'vuex-class'
import { Message } from '@/models/Message'
import { BillOfLading } from '@/models/BillOfLading'
import { JsonApiResponseBody } from 'coloquent/dist/JsonApiResponseBody'
import { ResourceLinkObject } from '@/models/Model'
import { Document } from '@/models/Document'
import { Transport } from '@/models/Transport'
import { Vessel } from '@/models/Vessel'
import { Company } from '@/models/Company'
import { Offer } from '@/models/Offer'
import { Invoice } from '@/models/accounting/Invoice'
import { CargoProject } from '@/models/CargoProject'

const Pusher = require('pusher-js')
const Messages = namespace('messages')
const Conversations = namespace('conversations')
const Auth = namespace('auth')
const BillOfLadingStore = namespace('billOfLadings')
const DocumentStore = namespace('documents')
const TransportStore = namespace('transports')
const FlatTransportStore = namespace('flatTransports')
const VesselStore = namespace('vessels')
const ClientStore = namespace('clients')
const CompanyStore = namespace('companies')
const BrokerStore = namespace('brokers')
const OfferStore = namespace('offers')
const InvoiceStore = namespace('accountingInvoices')
const ProjectStore = namespace('cargoProjects')

@Component
export default class PusherListener extends Vue {
	@Auth.Getter('currentCompany')
	userCompany

	@Messages.Action('syncBadges')
	syncBadges

	@Messages.Action('fetchAndUpdateLastMessage')
	fetchAndUpdateLastMessage

	@Messages.Mutation('setAndCalculateNewMessages')
	setAndCalculateNewMessages

	@Messages.Mutation('setAndCalculateNewOffersMessages')
	setAndCalculateNewOffersMessages

	@Messages.Mutation('setAndCalculateNewTransportsMessages')
	setAndCalculateNewTransportsMessages

	@Conversations.Mutation('updateUnreadMessages')
	updateUnreadMessages

	@Auth.State('user')
	user

	@BillOfLadingStore.Mutation('cacheOne')
	cacheBillOfLading!: (payload: { record: BillOfLading; cacheKey: string }) => void

	@BillOfLadingStore.Mutation('removeFromCache')
	removeBillOfLadingFromCache!: (id: string) => void

	@DocumentStore.Mutation('cacheOne')
	cacheDocument!: (payload: { record: Document; cacheKey: string }) => void

	@TransportStore.Mutation('removeFromCache')
	removeTransportFromCache!: (id: string) => void

	@FlatTransportStore.Mutation('removeFromCache')
	removeFlatTransportFromCache!: (id: string) => void

	@VesselStore.Mutation('removeFromCache')
	removeVesselFromCache!: (id: string) => void

	@OfferStore.Mutation('removeFromCache')
	removeOfferFromCache!: (id: string) => void

	@ClientStore.Mutation('removeFromCache')
	removeClientFromCache!: (id: string) => void

	@BrokerStore.Mutation('removeFromCache')
	removeBrokerFromCache!: (id: string) => void

	@CompanyStore.Mutation('removeFromCache')
	removeCompanyFromCache!: (id: string) => void

	@InvoiceStore.Mutation('removeFromCache')
	removeInvoiceFromCache!: (id: string) => void

	@ProjectStore.Mutation('removeFromCache')
	removeProjectFromCache!: (id: string) => void

	async mounted() {
		if (localStorage.getItem('forceLogin')) {
			return
		}

		const getToken = async () => {
			try {
				const data = await Authentication.currentAuthenticatedUser()
				if (data?.signInUserSession) {
					return data.signInUserSession.accessToken.jwtToken
				}
			} catch (e) {
				if (e !== 'The user is not authenticated') {
					// eslint-disable-next-line
					console.error(e)
				}
			}

			return null
		}

		const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
			cluster: process.env.VUE_APP_PUSHER_CLUSTER,
			authEndpoint: process.env.VUE_APP_PUSHER_AUTH_URL,
			auth: {
				headers: {
					Authorization: 'Bearer ' + (await getToken())
				}
			}
		})

		pusher.subscribe('private-user.' + this.user.id)

		const companyChannel = pusher.subscribe('private-companies.' + this.userCompany.id)
		companyChannel.bind('resource.createdOrUpdated', (payload: JsonApiResponseBody) => {
			const linkObject = payload.data as ResourceLinkObject
			if (this.shouldFetchResource(linkObject)) {
				switch (linkObject.type) {
					case new BillOfLading().getJsonApiType():
						this.fetchBillOfLading(linkObject)
						break
					case new Document().getJsonApiType():
						this.fetchDocument(linkObject)
						break
					default:
						break
				}
			}
		})
		companyChannel.bind('resource.deleted', (payload: JsonApiResponseBody) => {
			const linkObject = payload.data as ResourceLinkObject
			switch (linkObject.type) {
				case new Transport().getJsonApiType():
					this.removeTransportFromCache(linkObject.id)
					this.removeFlatTransportFromCache(linkObject.id)
					break
				case new Vessel().getJsonApiType():
					this.removeVesselFromCache(linkObject.id)
					break
				case new Offer().getJsonApiType():
					this.removeOfferFromCache(linkObject.id)
					break
				case new Company().getJsonApiType():
					this.removeClientFromCache(linkObject.id)
					this.removeCompanyFromCache(linkObject.id)
					this.removeBrokerFromCache(linkObject.id)
					break
				case new Invoice().getJsonApiType():
					this.removeInvoiceFromCache(linkObject.id)
					break
				case new CargoProject().getJsonApiType():
					this.removeProjectFromCache(linkObject.id)
					break
				case new BillOfLading().getJsonApiType():
					this.removeBillOfLadingFromCache(linkObject.id)
					break
				default:
					break
			}
		})

		pusher.bind('new-message', (data) => {
			const skeleton = new Message()
			skeleton.setApiId(data.data.id)
			this.fetchAndUpdateLastMessage(skeleton)
		})
		pusher.bind('unread-messages-amount-updated', (data) => {
			this.setAndCalculateNewMessages(data.data.newMessages)
			this.setAndCalculateNewOffersMessages(data.data.newOffersRelatedMessages)
			this.setAndCalculateNewTransportsMessages(data.data.newTransportsRelatedMessages)
			this.updateUnreadMessages(data.data.newMessages)
		})
		pusher.bind('new-fleet-invite', (data) => {
			// TODO: take from payload instead of users/me request
			this.syncBadges(true)
		})
	}

	shouldFetchResource(linkObject: ResourceLinkObject | null) {
		return (this.$route.meta?.fetchResources ?? []).includes(linkObject?.type)
	}

	async fetchBillOfLading(linkObject: ResourceLinkObject) {
		const record = (await BillOfLading.find(linkObject.id)).getData()
		if (record) {
			this.cacheBillOfLading({ record: record, cacheKey: record.getApiId() as string })
		}
	}

	async fetchDocument(linkObject: ResourceLinkObject) {
		const record = (await Document.find(linkObject.id)).getData()
		if (record) {
			this.cacheDocument({ record: record, cacheKey: record.getApiId() as string })
		}
	}

	render() {
		return null
	}
}
