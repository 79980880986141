import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class PhoneCountryCode extends Model {
	protected jsonApiType = 'phoneCountryCodes'

	@Property()
	public countryCode!: string

	@Property()
	public countryName!: string

	public get title() {
		return this.countryCode + '(' + this.countryName + ')'
	}

	public static async fetchAllLazy(): Promise<Array<PhoneCountryCode>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['phoneCountryCodes/hasAllFetched']()) {
			await store.dispatch('phoneCountryCodes/findAll')
		}

		return store.getters['phoneCountryCodes/peekAll']()
	}

	public static async fetchLazy(id): Promise<PhoneCountryCode> {
		const store = (await import('@/store/index')).store
		if (!store.getters['phoneCountryCodes/hasCached'](id)) {
			await store.dispatch('phoneCountryCodes/findRecord', id)
		}

		return store.getters['phoneCountryCodes/peekRecord'](id)
	}
}
