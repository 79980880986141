import { GeoJSON } from 'geojson'
import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Country } from '@/models/Country'

import Property from '@/decorators/Property'

export class CustomLocation extends Model {
	protected jsonApiType = 'customLocations'

	@Property()
	public companyName!: string

	@Property()
	public city!: string

	@Property()
	public geoCoordinates!: string | GeoJSON.Point

	@Property()
	public countryId!: string

	@Property()
	public harbourName!: string

	@Property()
	public harbourNumber!: string

	@Property()
	public waterwayName!: string

	@Property('kmNumber')
	public waterwayKmNumber!: string

	@Property()
	public phoneNumber!: string

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoCoordinates')
	}

	public getName(): string {
		return this.getAttribute('companyName')
	}

	private country(): ToOneRelation {
		return this.hasOne(Country, 'country')
	}

	public getCountry(): Country {
		return this.getRelation('country')
	}
}
