import { ActionContext } from 'vuex'
import { RootState } from '@/store/RootState'
import { MessagesState } from '@/interfaces/State/MessagesState'
import User from '@/models/User'
import { Message } from '@/models/Message'
import { ResourceLinkObject } from '@/models/Model'
import { Conversation } from '@/models/Conversation'

export const syncBadges = async (context: ActionContext<MessagesState, RootState>, force: boolean): Promise<User> => {
	const store = (await import('@/store/index')).store
	if (force) {
		await store.dispatch('users/findMe')
	}
	const user = await User.fetchMeLazy()

	context.commit('setAndCalculateNewMessages', user.newMessages)
	context.commit('setAndCalculateNewOffersMessages', user.newOffersRelatedMessages)
	context.commit('setAndCalculateNewTransportsMessages', user.newTransportsRelatedMessages)
	context.commit('setNewFleetInvitations', user.newFleetInvitations)

	return user
}

export const fetchAndUpdateLastMessage = async (
	context: ActionContext<MessagesState, RootState>,
	message: Message
): Promise<Message> => {
	await import('@/store/index') // trigger loading store file

	let messageResource

	if (!context.getters.hasCached(message)) {
		const fetched = await message.fresh()
		context.commit('cacheMessage', fetched)
		messageResource = fetched!
	} else {
		messageResource = context.getters.peekRecord((message as unknown as ResourceLinkObject).id)
	}

	const conversation = await Conversation.fetchLazy(messageResource.getConversation().getApiId())
	conversation.setLastMessage(messageResource)
	return messageResource
}
