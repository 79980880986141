import { MessagesState } from '@/interfaces/State/MessagesState'
import { Message } from '@/models/Message'
import moment from 'moment'

export const newMessages = (state: MessagesState): number => {
	return state.newCount
}

export const newOffersMessages = (state: MessagesState): number => {
	return state.newOffersMessagesCount
}

export const newTransportsMessages = (state: MessagesState): number => {
	return state.newTransportsMessagesCount
}

export const newFleetInvitations = (state: MessagesState): number => {
	return state.newFleetInvitationsCount
}

export const allMessages = (state: MessagesState): Array<Message> => {
	return state.messages.sort((a, b) => (moment(a.sentAt).isAfter(b.sentAt) ? 1 : -1))
}

export const hasCached =
	(state: MessagesState, { peekRecord }) =>
	(message: Message): boolean => {
		return !!peekRecord(message.getApiId())
	}

export const peekRecord =
	(state: MessagesState) =>
	(id: string): Message | undefined => {
		return state.messages.find((m) => m.getApiId() === id)
	}
