import { Attribute } from '@/models/Attribute'
import { ActionContext } from 'vuex'
import { RootState } from '@/store/RootState'
import axios from 'axios'

interface AttributesState {
	attributes: Array<Attribute>
	loading: boolean
	loaded: boolean
}

export const state: AttributesState = {
	attributes: [],
	loading: false,
	loaded: false
}

export default {
	namespaced: true,
	state,
	mutations: {
		setLoading(state: AttributesState, loading: boolean) {
			state.loading = loading
		},
		setLoaded(state: AttributesState, loaded: boolean) {
			state.loaded = loaded
		},
		setAttributes(state: AttributesState, attributes: Array<{ slug: string; value: string | unknown }>) {
			state.attributes = attributes.map((a) => {
				const created = new Attribute()
				created.populateFromResource({
					id: a.slug,
					type: 'attributeTypes',
					attributes: a,
					relationships: {}
				})
				return created
			})
		}
	},
	actions: {
		loadAttributes: async (context: ActionContext<AttributesState, RootState>): Promise<void> => {
			if (state.loading || state.loaded) return
			context.commit('setLoading', true)
			const result = await axios.get(`${process.env.VUE_APP_API_URL}attributes`)

			context.commit('setAttributes', result.data)
			context.commit('setLoading', false)
			context.commit('setLoaded', true)
		}
	}
}
