import User from '@/models/User'
import CachedResourceStore from '@/store/modules/CachedResourceStore'

export default new CachedResourceStore<User>(User, {
	namespaced: true,
	actions: {
		async findMe({ commit }) {
			const userQuery = User.query().with(['vesselAttributes', 'users'])
			const user = (await userQuery.find('me')).getData() as User
			commit('cacheOne', { record: user, cacheKey: 'me' })
		}
	}
})
