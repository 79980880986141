import { Cargo } from '@/models/Cargo'
import { FlatTransport } from '@/models/FlatTransport'
import { InvoiceLine } from './accounting/InvoiceLine'
import { Model } from '@/models/Model'
import { Offer } from '@/models/Offer'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import TransportType from '@/enums/TransportType'
import TransportationType from '@/enums/TransportationType'
import User from '@/models/User'

export class CargoProject extends Model {
	protected jsonApiType: string = 'cargoProjects'
	protected static pageSize = 15

	public cargoBluePrint(): ToOneRelation {
		return this.hasOne(Cargo, 'cargoBluePrint')
	}

	public getCargoBluePrint(): Cargo {
		return this.getRelation('cargoBluePrint')
	}

	public projectOwner(): ToOneRelation {
		return this.hasOne(User, 'projectOwner')
	}

	public getProjectOwner(): User {
		return this.getRelation('projectOwner')
	}

	public currentOffers(): ToManyRelation {
		return this.hasMany(Offer, 'currentOffers')
	}

	public currentTransports(): ToManyRelation {
		return this.hasMany(FlatTransport, 'currentTransports')
	}

	public historicTransports(): ToManyRelation {
		return this.hasMany(FlatTransport, 'historicTransports')
	}

	@Property()
	public projectName!: string

	@Property()
	public clientReference!: string

	@Property()
	public transportationType!: TransportationType

	@Property()
	public transportType!: TransportType

	@Property()
	public startDate!: string

	@Property()
	public endDate!: string

	@Property()
	public totalTeuMin!: number | null

	@Property()
	public totalTeuMax!: number | null

	@Property()
	public totalTeuShipped!: number | null

	@Property()
	public totalTonnageMin!: number | null

	@Property()
	public totalTonnageMax!: number | null

	@Property()
	public totalTonnageShipped!: number | null

	@Property()
	public hasBluePrint!: boolean

	@Property()
	public newProjectRelatedMessages!: number

	@Property()
	public uniqueViews!: number

	@Property()
	public uniqueUserReplies!: number

	public get isContainer(): boolean {
		return this.transportType === TransportType.CONTAINER
	}

	public get isDryBulk(): boolean {
		return this.transportType === TransportType.BULK
	}

	public financialRegistrations(): ToManyRelation {
		return this.hasMany(InvoiceLine, 'financialRegistrations')
	}

	public getFinancialRegistrations(): Array<InvoiceLine> {
		return this.getRelation('financialRegistrations') || []
	}

	public isLoading: boolean = false
}
