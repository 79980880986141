import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Membership extends Model {
	protected jsonApiType = 'memberships'

	@Property()
	public description!: string

	@Property()
	public slug!: string
}
