import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'

Vue.use(VueI18n)

let locale = window.localStorage.getItem('locale')

const urlParams = new URLSearchParams(window.location.search)
if (urlParams.get('lang')) {
	locale = urlParams.get('lang')
}

if (!locale) {
	locale = process.env.VUE_APP_I18N_LOCALE || 'nl'
}

const l18n = new VueI18n({
	locale: locale,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages
})

export default l18n

if (module.hot) {
	module.hot.accept(['./messages'], () => {
		const messages = require('./messages').default
		Object.keys(messages).forEach((locale) => {
			l18n.setLocaleMessage(locale, messages[locale])
		})
	})
}
