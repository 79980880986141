import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Fleet } from '@/models/Fleet'
import moment from 'moment'
import { Vessel } from '@/models/Vessel'

export class FleetMember extends Model {
	protected jsonApiType: string = 'fleetMembers'

	public getFleet(): Fleet {
		return this.getRelation('fleet')
	}

	private fleet(): ToOneRelation {
		return this.hasOne(Fleet, 'fleet')
	}

	public getVessel(): Vessel {
		return this.getRelation('vessel')
	}

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	@Property()
	public vesselName!: string

	@Property('vesselDimensionsInMeters')
	public vesselDimensions!: number

	@Property()
	public tonnage!: number

	@Property()
	public rentalPricePerDay!: number | null

	@Property()
	public rentalDaysPerWeek!: number | null

	@Property()
	public dailyShippingTimeInHours!: number | null

	@Property()
	public fromDate!: string

	@Property()
	public untilDate!: string | null

	@Property('isAccepted')
	public accepted!: boolean

	@Property()
	public companyName!: string

	public get fullName(): string {
		return this.vesselName
	}

	public get formattedFromDate(): string {
		return moment(this.fromDate).format('ll')
	}

	public get formattedUntilDate(): string | null {
		if (this.untilDate !== null) {
			return moment(this.untilDate).format('ll')
		}
		return null
	}

	public get formattedTimePeriod(): string | null {
		if (this.fromDate !== null && this.untilDate !== null) {
			return this.formattedFromDate + ' - ' + this.formattedUntilDate
		} else if (this.fromDate !== null && this.untilDate === null) {
			return this.formattedFromDate
		} else {
			return null
		}
	}
}
