import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { VesselLogInputCategory } from '@/models/VesselLogInputCategory'

export class VesselLogInput extends Model {
	protected jsonApiType = 'vesselLogInputs'

	private vesselLogInputCategory(): ToOneRelation {
		return this.hasOne(VesselLogInputCategory, 'vesselLogInputCategory')
	}

	public getVesselLogInputCategory(): VesselLogInputCategory {
		return this.getRelation('vesselLogInputCategory')
	}

	@Property()
	public comment!: string

	@Property()
	public categoryDescription!: string
}
