import { Model } from '@/models/Model'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { PortBaseVisit } from '@/models/PortBaseVisit'

export class PortBaseVoyage extends Model {
	protected jsonApiType: string = 'portBaseVoyages'
	protected static pageSize = 10

	// only attributes which are currently used are implemented
	private plannedPortBaseVisits(): ToManyRelation {
		return this.hasMany(PortBaseVisit, 'plannedPortBaseVisits')
	}

	public getPlannedPortBaseVisits(): Array<PortBaseVisit> {
		return this.getRelation('plannedPortBaseVisits')
	}
}
