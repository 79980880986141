import { Cargo } from '@/models/Cargo'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import TransportLocationQuantityUnit from '@/enums/TransportLocationQuantityUnit'
import TransportLocationStatus from '@/enums/TransportLocationStatus'

export class TransportLocation extends Model {
	protected jsonApiType: string = 'transportLocations'
	protected static pageSize = 10

	@Property('ETA')
	public eta!: string

	@Property('ETD')
	public etd!: string

	@Property('ETB')
	public etb!: string | null

	@Property('ETB_comment')
	public etbComment!: string | null

	@Property()
	public headingToAt!: string | null

	@Property()
	public arrivedAt!: string | null

	@Property()
	public transshippingAt!: string | null

	@Property()
	public finishedAt!: string | null

	@Property()
	public loadedQuantity!: number

	@Property()
	public loadedQuantityUnit!: TransportLocationQuantityUnit

	@Property()
	public dischargedQuantity!: number

	@Property()
	public dischargedQuantityUnit!: TransportLocationQuantityUnit

	@Property()
	public draught!: number

	@Property()
	public status!: TransportLocationStatus | null

	@Property()
	public sequence!: number

	private cargo(): ToOneRelation {
		return this.hasOne(Cargo, 'cargo')
	}

	public getCargo(): Cargo {
		return this.getRelation('cargo')
	}

	get isNotStarted() {
		return this.status === null
	}

	get isHeadingTo() {
		return (this.status as TransportLocationStatus) === TransportLocationStatus.HEADING_TO
	}

	get isArrived() {
		return this.status === TransportLocationStatus.ARRIVED
	}

	get isTransshipping() {
		return this.status === TransportLocationStatus.TRANSSHIPPING
	}

	get isFinished() {
		return this.status === TransportLocationStatus.FINISHED
	}

	get isLoadingLocation() {
		return this.sequence === 0
	}
}
