enum OfferType {
	MARKET_WIDE = 'market_wide',
	ALL = 'all',
	FLEET = 'fleet',
	FAVOURITE_SKIPPERS = 'favourite_skippers',
	LOGISTIC_SERVICE_PROVIDERS = 'logistic_service_providers',
	SPECIFIC_VESSEL = 'specific_vessel',
	FAVOURITE_BROKERS = 'favourite_brokers'
}

export default OfferType
