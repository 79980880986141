import Component from "vue-class-component"
import { Downloadable } from "./Downloadable"
import { Vessel } from "@/models/Vessel"
import Vue from "vue"
import axios from "axios"
import i18n from '@/i18n'

@Component
export class VesselDocument extends Vue implements Downloadable {
	vessel: Vessel | null = null
 	language: string | null = null

	setLanguage(value: string)  {
		this.language = value
	}

	getApiId(): string | undefined {
		// based on vessel id for now
		return this.vessel?.getApiId()
	}

	public isDownloading: boolean = false
	public downloadedFile: Blob | null = null

	setIsDownloading(value: boolean) {
		this.isDownloading = value
	}

	get downloadName() {
		return `${i18n.t('vesselContactCard.fileName', { vesselName: this.vessel?.getName() })}-${this.language}.pdf`
	}

  public async triggerDownload() {
		this.isDownloading = true
		const fauxVessel = new Vessel()
		fauxVessel.setApiId(this.vessel?.getApiId())

		const response = await axios.get(`${fauxVessel.getModelUrl()}/download/contact-card`, {
				responseType: 'blob',
				headers: {
						'Accept-Language': this.language
				}
		})

		this.downloadedFile = response.data as Blob
		this.isDownloading = false
	}
}
