import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class TransportFinancial extends Model {
	protected jsonApiType: string = 'transportFinancials'
	protected static pageSize = 10

	@Property()
	public revenue!: number | null

	@Property()
	public costs!: number | null
}
