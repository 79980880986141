import CachedResourceStore from '@/store/modules/CachedResourceStore'
import { CargoType } from '@/models/CargoType'
import { Category } from '@/models/Category'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'

export default new CachedResourceStore<Category>(Category, {
	namespaced: true,
	actions: {
		async findAll({ commit, state }) {
			let allQuery
			if (state.allQuery) {
				allQuery = state.allQuery
			} else {
				allQuery = CargoType.query().with('category').get()
				commit('queryAll', allQuery)
			}

			const cargoTypes = (await allQuery as PluralResponse).getData() as Array<CargoType>

			// Recursive function to extract all nested categories
			const getAllNestedCategories = (categories: Array<Category>): Array<Category> => {
				const directSubcategories = categories
						.map(category => category.getCategory())
						.filter(Boolean)

				if (directSubcategories.length === 0) {
						return []
				}

				return directSubcategories.concat(getAllNestedCategories(directSubcategories))
			}
			const categories = cargoTypes.map(ct => ct.getCategory()).filter(Boolean)
			const allNestedCategories = getAllNestedCategories(categories)
			const records = categories.concat(allNestedCategories)

			commit('cache', records)
			commit('allFetched', true)
		}
	}
})
