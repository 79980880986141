import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Addition } from '@/models/Addition'

export class AdditionRow extends Model {
	protected jsonApiType = 'additionRows'

	@Property()
	public height!: number | null

	@Property()
	public percentage!: number | null

	private addition(): ToOneRelation {
		return this.hasOne(Addition, 'addition')
	}

	public getAddition(): Addition | null {
		return this.getRelation('addition')
	}

	public setAddition(addition: Addition | null) {
		this.setRelation('addition', addition)
	}
}
