import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Alert extends Model {
	protected jsonApiType = 'alerts'

	@Property()
	public isActive!: boolean

	@Property()
	public message!: string | null

	@Property()
	public createdAt!: string

	@Property()
	public updatedAt!: string

	@Property('type')
	public alertType!: string
}
