import { Addition } from '@/models/Addition'
import { AdditionRow } from '@/models/AdditionRow'
import { CargoCondition } from '@/models/CargoCondition'
import { CargoHoldFloor } from '@/models/CargoHoldFloor'
import { CargoType } from '@/models/CargoType'
import { CertificationClaimType } from '@/models/CertificationClaimType'
import DemurrageTime from '@/enums/DemurrageTime'
import { GasOilAdditions } from '@/models/GasOilAdditions'
import HoldCleaningCode from '@/enums/HoldCleaningCode'
import { Model } from '@/models/Model'
import NoOrMandatoryOrPrefered from '@/enums/NoOrMandatoryOrPrefered'
import PaymentTermCondition from '@/enums/PaymentTermCondition'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { TransferTime } from '@/models/TransferTime'
import TransportLocationQuantityUnit from '@/enums/TransportLocationQuantityUnit'
import PriceUnit from '@/enums/PriceUnit'

export class CargoTerms extends Model {
	protected jsonApiType = 'cargoTerms'

	// necessary for now, should be removed when we save the cargoTerm via a relationship endpoint on a cargo
	@Property()
	public cargoId!: string

	@Property()
	public loadOnWaterLevel!: boolean

	@Property()
	public loadUpToAmount!: boolean

	@Property()
	public cargoHoldFloor!: boolean

	@Property()
	public isCertificationRequired!: boolean

	@Property()
	public requiredCertificationTypeId!: Array<string>

	@Property()
	public mustHaveReeferConnection!: NoOrMandatoryOrPrefered | null

	@Property()
	public paymentTermCondition!: PaymentTermCondition | null

	@Property()
	public reeferNumber!: number | null

	@Property()
	public mustHaveAdn!: NoOrMandatoryOrPrefered | null

	@Property()
	public requiredAmountOfCones!: number | null

	@Property()
	public startTerminalsQuantity!: number | null

	@Property()
	public destinationTerminalsQuantity!: number | null

	@Property()
	public charterAs!: string

	@Property()
	public endDateRentalPeriod!: string

	@Property()
	public endDateTravelPeriod!: string

	@Property()
	public priceUnit!: PriceUnit

	@Property()
	public loadedCargoInsuredBy!: string | null

	@Property()
	public fuelCostsPaidBy!: string | null

	@Property()
	public coverCargoWhileShipping!: boolean

	@Property()
	public portCostsPaidBy!: string | null

	@Property()
	public isSubjectedToStability!: boolean

	@Property()
	public showUnloadOnSundays!: boolean

	@Property()
	public unloadOnSundays!: boolean

	@Property()
	public hasCargoHoldFloorRequirement!: boolean

	@Property()
	public isInsuranceRequired!: boolean

	@Property()
	public highTideCostsApply!: boolean

	@Property()
	public highTideCostsId!: number

	@Property()
	public lowTideCostsApply!: boolean

	@Property()
	public iceCostsApply!: boolean

	@Property()
	public iceCostsId!: number

	@Property()
	public isSubjectedToEmptyVessel!: boolean

	@Property()
	public hasGasOilSurcharge!: boolean

	@Property()
	public conditionPreference!: string

	@Property()
	public loadTimeQuantity!: string | null

	@Property()
	public loadTimeQuantityUnit!: string | null

	@Property()
	public loadTimeGuaranteedAt!: string

	@Property()
	public unloadTimeQuantity!: string | null

	@Property()
	public unloadTimeQuantityUnit!: string | null

	@Property()
	public maxAmountOfLayers!: number | null

	@Property()
	public price!: string

	@Property()
	public commissionPercentage!: number | null

	@Property()
	public comments!: string

	@Property()
	public priceComments!: string

	@Property()
	public payableWeight!: string

	@Property()
	public payableQuantity!: string

	@Property()
	public termOfPaymentDays!: number | null

	@Property()
	public customProductLastTransported!: string | null

	@Property()
	public customProductSecondLastTransported!: string | null

	@Property()
	public customProductThirdLastTransported!: string | null

	@Property()
	public holdCleaningCode!: HoldCleaningCode | null

	@Property()
	public requiredDraughts!: Array<any>

	@Property()
	public hasLegalBasis!: boolean

	@Property()
	public loadBerthFeePrice!: number | null

	@Property()
	public loadBerthFeeQuantityUnit!: TransportLocationQuantityUnit | null

	@Property()
	public loadBerthFeeTime!: DemurrageTime | null

	@Property()
	public unloadBerthFeePrice!: number | null

	@Property()
	public unloadBerthFeeQuantityUnit!: TransportLocationQuantityUnit | null

	@Property()
	public unloadBerthFeeTime!: DemurrageTime | null

	@Property()
	public singleBerthFeePrice!: number | null

	@Property()
	public singleBerthFeeQuantityUnit!: TransportLocationQuantityUnit | null

	@Property()
	public singleBerthFeeTime!: DemurrageTime | null

	private gasOilAddition(): ToOneRelation {
		return this.hasOne(GasOilAdditions, 'gasOilAddition')
	}

	public getGasOilAddition(): GasOilAdditions | null {
		return this.getRelation('gasOilAddition') ?? null
	}

	public setGasOilAddition(addition: GasOilAdditions | null): void {
		return this.setRelation('gasOilAddition', addition)
	}

	private singleBerthFee(): ToOneRelation {
		return this.hasOne(CargoCondition, 'singleBerthFee')
	}

	public getSingleBerthFee(): CargoCondition | null {
		return this.getRelation('singleBerthFee')
	}

	public setSingleBerthFee(fee: CargoCondition | null): void {
		this.setRelation('singleBerthFee', fee)
	}

	private loadUnloadTimeCondition(): ToOneRelation {
		return this.hasOne(CargoCondition, 'loadUnloadTimeCondition')
	}

	public getLoadUnloadTimeCondition(): CargoCondition {
		return this.getRelation('loadUnloadTimeCondition')
	}

	public setLoadUnloadTimeCondition(condition: CargoCondition | null): void {
		this.setRelation('loadUnloadTimeCondition', condition)
	}

	private loadBerthFee(): ToOneRelation {
		return this.hasOne(CargoCondition, 'loadBerthFee')
	}

	public getLoadBerthFee(): CargoCondition {
		return this.getRelation('loadBerthFee')
	}

	public setLoadBerthFee(fee: CargoCondition | null): void {
		this.setRelation('loadBerthFee', fee)
	}

	private unloadBerthFee(): ToOneRelation {
		return this.hasOne(CargoCondition, 'unloadBerthFee')
	}

	public getUnloadBerthFee(): CargoCondition | null {
		return this.getRelation('unloadBerthFee')
	}

	public setUnloadBerthFee(fee: CargoCondition | null): void {
		this.setRelation('unloadBerthFee', fee)
	}

	private loadTimeCondition(): ToOneRelation {
		return this.hasOne(CargoCondition, 'loadTimeCondition')
	}

	public getLoadTimeCondition(): CargoCondition | null {
		return this.getRelation('loadTimeCondition')
	}

	public setLoadTimeCondition(condition: CargoCondition | null): void {
		this.setRelation('loadTimeCondition', condition)
	}

	private unloadTimeCondition(): ToOneRelation {
		return this.hasOne(CargoCondition, 'unloadTimeCondition')
	}

	public getUnloadTimeCondition(): CargoCondition | null {
		return this.getRelation('unloadTimeCondition')
	}

	public setUnloadTimeCondition(condition: CargoCondition | null): void {
		this.setRelation('unloadTimeCondition', condition)
	}

	private transferTime(): ToOneRelation {
		return this.hasOne(TransferTime, 'transferTime')
	}

	public getTransferTime(): TransferTime | null {
		return this.getRelation('transferTime')
	}

	public setTransferTime(time: TransferTime | null): void {
		this.setRelation('transferTime', time)
	}

	private hwzHighWaterAddition(): ToOneRelation {
		return this.hasOne(Addition, 'hwzHighWaterAddition')
	}

	public getHwzHighWaterAddition(): Addition | null {
		return this.getRelation('hwzHighWaterAddition')
	}

	public setHwzHighWaterAddition(addition: Addition | null): void {
		this.setRelation('hwzHighWaterAddition', addition)
	}

	private iceAddition(): ToOneRelation {
		return this.hasOne(Addition, 'iceAddition')
	}

	public getIceAddition(): Addition | null {
		return this.getRelation('iceAddition')
	}

	public setIceAddition(addition: Addition | null): void {
		this.setRelation('iceAddition', addition)
	}

	private kwzLowWaterAddition(): ToOneRelation {
		return this.hasOne(Addition, 'kwzLowWaterAddition')
	}

	public getKwzLowWaterAddition(): Addition | null {
		return this.getRelation('kwzLowWaterAddition')
	}

	public setKwzLowWaterAddition(addition: Addition | null): void {
		this.setRelation('kwzLowWaterAddition', addition)
	}

	private certificationClaimTypes(): ToManyRelation {
		return this.hasMany(CertificationClaimType, 'certificationClaimTypes')
	}

	public getCertificationClaimTypes(): Array<CertificationClaimType> {
		return this.getRelation('certificationClaimTypes')
	}

	private cargoHoldFloorType(): ToOneRelation {
		return this.hasOne(CargoHoldFloor, 'cargoHoldFloorType')
	}

	public getCargoHoldFloorType(): CargoHoldFloor | null {
		return this.getRelation('cargoHoldFloorType')
	}

	public setCargoHoldFloorType(floor: CargoHoldFloor | null): void {
		this.setRelation('cargoHoldFloorType', floor)
	}

	private cargoTypeLastTransported(): ToOneRelation {
		return this.hasOne(CargoType, 'cargoTypeLastTransported')
	}

	public getCargoTypeLastTransported(): CargoType | null {
		return this.getRelation('cargoTypeLastTransported')
	}

	public setCargoTypeLastTransported(type: CargoType | null) {
		this.setRelation('cargoTypeLastTransported', type)
	}

	private cargoTypeSecondLastTransported(): ToOneRelation {
		return this.hasOne(CargoType, 'cargoTypeSecondLastTransported')
	}

	public getCargoTypeSecondLastTransported(): CargoType | null {
		return this.getRelation('cargoTypeSecondLastTransported')
	}

	public setCargoTypeSecondLastTransported(type: CargoType | null) {
		this.setRelation('cargoTypeSecondLastTransported', type)
	}

	private cargoTypeThirdLastTransported(): ToOneRelation {
		return this.hasOne(CargoType, 'cargoTypeThirdLastTransported')
	}

	public getCargoTypeThirdLastTransported(): CargoType | null {
		return this.getRelation('cargoTypeThirdLastTransported')
	}

	public setCargoTypeThirdLastTransported(type: CargoType | null) {
		this.setRelation('cargoTypeThirdLastTransported', type)
	}

	private additionRow(): ToOneRelation {
		return this.hasOne(AdditionRow, 'additionRow')
	}

	public getAdditionRow(): AdditionRow | null {
		return this.getRelation('additionRow')
	}

	public setAdditionRow(row: AdditionRow | null) {
		this.setRelation('additionRow', row)
	}

	private legalBasis(): ToOneRelation {
		return this.hasOne(CargoCondition, 'legalBasis')
	}

	public getLegalBasis(): CargoCondition | null {
		return this.getRelation('legalBasis')
	}

	public setLegalBasis(condition: CargoCondition | null): void {
		this.setRelation('legalBasis', condition)
	}

	get hasLastTransportedCargos(): boolean {
		return !!(
			this.getCargoTypeLastTransported() ||
			this.customProductLastTransported ||
			this.getCargoTypeSecondLastTransported() ||
			this.customProductSecondLastTransported ||
			this.getCargoTypeThirdLastTransported() ||
			this.customProductThirdLastTransported ||
			this.holdCleaningCode
		)
	}
}
