import { MessagesState } from '@/interfaces/State/MessagesState'

export const state: MessagesState = {
	newCount: 0,
	newTransportsMessagesCount: 0,
	newOffersMessagesCount: 0,
	newFleetInvitationsCount: 0,
	messages: [],
	conversations: []
}
