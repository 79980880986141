import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class BidAdditionalInfo extends Model {
	protected jsonApiType = 'bidAdditionalInfos'

	@Property()
	public createdByLogisticServiceProvider!: boolean

	@Property('expectedMinAvailableWeight')
	public minExpected!: number

	@Property('expectedMaxAvailableWeight')
	public maxExpected!: number

	@Property()
	public sizeUnit!: string

	@Property('expectedLoadDateTime')
	public expectedAvailability!: string

	@Property()
	public remark!: string
}
