import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import User from '@/models/User'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { Company } from './Company'

export class ClientGroup extends Model {
	protected jsonApiType: string = 'clientGroups'

	@Property()
	public name!: string

	@Property()
	public totalClients!: number

	public getOwner(): User {
		return this.getRelation('owner')
	}

	public owner(): ToOneRelation {
		return this.hasOne(User, 'owner')
	}

	public clients(): ToManyRelation {
		return this.hasMany(Company, 'clients')
	}

	public getClients(): Array<Company> {
		return this.getRelation('clients') || []
	}
}
