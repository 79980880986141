import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class NetworkInformationLocks extends Model {
	protected jsonApiType = 'networkInformationLocks'

	@Property('numberOfChambers')
	public numberOfChambers?: number

	@Property('phoneNumber')
	public phoneNumber?: string
}
