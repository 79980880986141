import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import moment from 'moment'

export default class FinancialConnection extends Model {
	protected jsonApiType = 'accounting/financialConnections'

	@Property()
	public connection!: string

	@Property()
	public expires!: string

	@Property()
	public forwardEmail!: string

	public isExpired(): boolean {
		return moment(this.expires).isBefore(moment())
	}

	public isExact(): boolean {
		return this.connection === 'exact-online'
	}
}
