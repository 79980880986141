import { Bid } from '@/models/Bid'
import { Cargo } from '@/models/Cargo'
import { CargoProject } from './CargoProject'
import { Charter } from '@/models/Charter'
import { Company } from '@/models/Company'
import { Conversation } from '@/models/Conversation'
import { ConversationContext } from '@/models/ConversationContext'
import { FavouriteGroup } from '@/models/FavouriteGroup'
import { Message } from '@/models/Message'
import { Model } from '@/models/Model'
import { OfferInterest } from '@/models/OfferInterest'
import { OfferReply } from '@/models/OfferReply'
import OfferType from '@/enums/OfferType'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'
import TransportationType from '@/enums/TransportationType'
import User from '@/models/User'
import { Vessel } from '@/models/Vessel'
import i18n from '@/i18n/index'
import moment from 'moment'

export class Offer extends Model {
	protected jsonApiType: string = 'offers'
	protected static pageSize = 10

	private favouriteGroups(): ToManyRelation {
		return this.hasMany(FavouriteGroup, 'favouriteGroups')
	}

	private cargo(): ToOneRelation {
		return this.hasOne(Cargo, 'cargo')
	}

	public getCargo(): Cargo {
		return this.getRelation('cargo')
	}

	public setCargo(cargo: Cargo): void {
		this.setRelation('cargos', cargo)
	}

	private cargoProject(): ToOneRelation {
		return this.hasOne(CargoProject, 'cargoProject')
	}

	public getCargoProject(): CargoProject {
		return this.getRelation('cargoProject')
	}

	private externalOffer(): ToOneRelation {
		return this.hasOne(Offer, 'externalOffer')
	}

	public getExternalOffer(): Offer {
		return this.getRelation('externalOffer')
	}

	private ownOffer(): ToOneRelation {
		return this.hasOne(Offer, 'ownOffer')
	}

	public getOwnOffer(): Offer {
		return this.getRelation('ownOffer')
	}

	private client(): ToOneRelation {
		return this.hasOne(Company, 'client')
	}

	public getClient(): Company {
		return this.getRelation('client')
	}

	public setVessel(vessel: Vessel): void {
		this.setRelation('vessels', vessel)
	}

	public setUnregisteredVessel(unregisteredVessel: Vessel): void {
		this.setRelation('unregisteredVessel', unregisteredVessel)
	}

	public viaBroker(): ToOneRelation {
		return this.hasOne(Company, 'viaBroker')
	}

	public getViaBroker(): Company | null {
		return this.getRelation('viaBroker')
	}

	public setViaBroker(broker: Company) {
		this.setRelation('viaBroker', broker)
	}

	public setBroker(company: Company): void {
		this.setRelation('logisticServiceProviders', [company])
	}

	public getCreator(): User {
		return this.getRelation('creator')
	}

	public creator(): ToOneRelation {
		return this.hasOne(User, 'creator')
	}

	public bids(): ToManyRelation {
		return this.hasMany(Bid, 'bids')
	}

	public getBids(): Array<Bid> {
		return this.getRelation('bids') || []
	}

	private bidMadeByOfferOwner(): ToOneRelation {
		return this.hasOne(Bid, 'bidMadeByOfferOwner')
	}

	public bidByLoggedInUser(): ToOneRelation {
		return this.hasOne(Bid, 'bidsByLoggedInUser')
	}

	public getBidByLoggedInUser(): Bid {
		return this.getRelation('bidByLoggedInUser')
	}

	public getChosenBid(): Bid {
		return this.getRelation('chosenBid')
	}

	public chosenBid(): ToOneRelation {
		return this.hasOne(Bid, 'chosenBid')
	}

	public getBidMadeByOfferOwner(): Bid {
		return this.getRelation('bidMadeByOfferOwner')
	}

	public allReplies(): ToManyRelation {
		return this.hasMany(OfferReply, 'allReplies')
	}

	public getAllReplies(): Array<OfferReply> {
		return this.getRelation('allReplies') || []
	}

	public allRepliesByLoggedInUser(): ToManyRelation {
		return this.hasMany(OfferReply, 'allRepliesByLoggedInUser')
	}

	public lowestBid(): ToOneRelation {
		return this.hasOne(Bid, 'lowestBid')
	}

	public getLowestBid(): Bid | null {
		return this.getRelation('lowestBid') || null
	}

	public messages(): ToManyRelation {
		return this.hasMany(Message, 'messages')
	}

	public offerInterests(): ToManyRelation {
		return this.hasMany(OfferInterest, 'offerInterests')
	}

	public charter(): ToOneRelation {
		return this.hasOne(Charter, 'charter')
	}

	public getCharter(): Charter {
		return this.getRelation('charter')
	}

	public logisticServiceProviders(): ToManyRelation {
		return this.hasMany(Company, 'logisticServiceProviders')
	}

	public getLogisticServiceProviders(): Array<Company> {
		return this.getRelation('logisticServiceProviders') || []
	}

	public transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport {
		return this.getRelation('transport')
	}

	public conversationContext(): ToOneRelation {
		return this.hasOne(ConversationContext, 'conversationContext')
	}

	public getConversationContext(): ConversationContext {
		return this.getRelation('conversationContext')
	}

	public conversations(): ToManyRelation {
		return this.hasMany(Conversation, 'conversations')
	}

	public getConversations(): Array<Conversation> {
		return this.getRelation('conversations') || []
	}

	public calculateProgress(): void {
		const maxTime = 48 * 60 * 60 * 1000

		if (this.timeLeftInMs > maxTime) {
			this.progress = 0

			return
		} else if (this.timeLeftInMs <= 0) {
			this.progress = 100

			return
		}

		this.progress = 100 - Math.round((this.timeLeftInMs / maxTime) * 100)
	}

	public calculateTimeLeft(): void {
		this.timeLeftInMs = moment(this.cargoOfferUntilDate).diff(moment())
	}

	public formattedTimeLeft(): string {
		if (!this.timeLeftInMs) {
			return ''
		}
		const duration = moment.duration(this.timeLeftInMs, 'ms')

		const days = Math.floor(duration.asDays())
		const hours = Math.floor(duration.hours())
		const minutes = Math.floor(duration.minutes())

		const parts: Array<string> = []

		if (days) {
			parts.push(`<strong>${days}</strong>${i18n.t('base.suffix_days_short')}`)
		}

		parts.push(`<strong>${hours}</strong>${i18n.t('base.suffix_hours_short')}`)
		parts.push(`<strong>${minutes}</strong>${i18n.t('base.suffix_minutes_short')}`)

		return parts.join(' ')
	}

	public progress: number = 0

	public timeLeftInMs: number = 0

	@Property()
	public transportationType!: TransportationType

	@Property()
	public newOfferRelatedMessages!: number

	@Property()
	public bidReviewNotRequired!: boolean

	@Property()
	public offerCargoToSkippers!: string

	@Property()
	public cargoOfferUntilDate!: string

	@Property()
	public showPhoneNumberToInterested!: boolean

	@Property()
	public loggedInUserReply!: string

	@Property()
	public distanceFromVessel!: string

	@Property()
	public uniqueUserReplies!: string

	@Property()
	public uniqueViews!: string

	@Property()
	public offerIsHistoric!: boolean

	@Property()
	public charterStatus!: string

	@Property()
	public highestRankedReply!: string

	@Property()
	public endDateOnly: boolean | undefined

	@Property()
	public hasTransport: boolean | undefined

	public isTender(company: Company): boolean {
		return !this.isPlanned(company)
	}

	public isPlanned(company: Company): boolean {
		return this.isExclusiveSpecificVessel() || this.isExclusiveSpecificBroker(company) || this.isToFleet()
	}

	public isToFleet(): boolean {
		return this.offerCargoToSkippers === OfferType.FLEET
	}

	public isExclusiveFavouriteSkippers(): boolean {
		return this.offerCargoToSkippers === OfferType.FAVOURITE_SKIPPERS
	}

	public isExclusiveSpecificVessel(): boolean {
		return this.offerCargoToSkippers === OfferType.SPECIFIC_VESSEL
	}

	public isExclusiveSpecificBroker(currentCompany: Company): boolean {
		return (
			this.offerCargoToSkippers === OfferType.LOGISTIC_SERVICE_PROVIDERS &&
			!!currentCompany &&
			this.getLogisticServiceProviders().some((company) => company.getApiId() === currentCompany.getApiId())
		)
	}

	public isExclusiveFavouriteBrokers(): boolean {
		return this.offerCargoToSkippers === OfferType.FAVOURITE_BROKERS
	}
}
