import { MetaObject, Model } from '@/models/Model'

import { Bid } from '@/models/Bid'
import { Cargo } from '@/models/Cargo'
import { ConversationContext } from '@/models/ConversationContext'
import { ConversationParticipant } from '@/models/ConversationParticipant'
import { Message } from '@/models/Message'
import { Offer } from '@/models/Offer'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'
import Property from '@/decorators/Property'
import { SingularResponse } from 'coloquent/dist/response/SingularResponse'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import axios from 'axios'

export class Conversation extends Model {
	protected jsonApiType = 'conversations'
	protected static pageSize = 25

	@Property()
	public newMessages: number = 0

	public messages(): ToManyRelation {
		return this.hasMany(Message, 'messages')
	}

	public context(): ToOneRelation {
		return this.hasOne(ConversationContext, 'context')
	}

	public getContext(): ConversationContext | null {
		return this.getRelation('context') || null
	}

	public setContext(context: ConversationContext) {
		this.setRelation('context', context)
	}

	public lastMessage(): ToOneRelation {
		return this.hasOne(Message, 'lastMessage')
	}

	public getLastMessage(): Message | null {
		return this.getRelation('lastMessage') || null
	}

	public setLastMessage(message: Message) {
		this.setRelation('lastMessage', message)
	}

	public participants(): ToManyRelation {
		return this.hasMany(ConversationParticipant, 'participants')
	}

	public getParticipants(): Array<ConversationParticipant> {
		return this.getRelation('participants') || []
	}

	public addParticipant(participant: ConversationParticipant) {
		this.setRelation('participants', [...this.getParticipants(), participant])
	}

	public static async fetchLazy(id): Promise<Conversation> {
		const store = (await import('@/store/index')).store
		if (!store.getters['conversations/hasCached'](id)) {
			await store.dispatch('conversations/findRecord', id)
		}

		return store.getters['conversations/peekRecord'](id)
	}

	public static async refreshConversations(pageNumber?: number): Promise<MetaObject> {
		const store = (await import('@/store/index')).store

		const fetched = (await Conversation.get(pageNumber)) as PluralResponse
		const meta = fetched.getHttpClientResponse().getData().meta
		const conversations = fetched.getData() as Array<Conversation>
		store.commit('conversations/cache', conversations)
		conversations.forEach((conversation) => {
			const lastMessage = conversation.getLastMessage()
			if (lastMessage) {
				lastMessage.setConversation(conversation)
				store.commit('messages/cacheMessage', conversation.getLastMessage())
			}
		})
		return meta
	}

	public static async createFromContext(
		cargo?: Cargo | null,
		offer?: Offer | null,
		bid?: Bid | null
	): Promise<Conversation> {
		const formData = {
			relationships: {} as { offer?; cargo?; bid? }
		}
		if (offer) {
			formData.relationships.offer = {
				data: {
					type: 'offers',
					id: offer.getApiId()
				}
			}
		}
		if (cargo) {
			formData.relationships.cargo = {
				data: {
					type: 'cargos',
					id: cargo.getApiId()
				}
			}
		}
		if (bid) {
			formData.relationships.bid = {
				data: {
					type: 'bids',
					id: bid.getApiId()
				}
			}
		}

		const response = await axios.post(
			`${Model.baseUrl()}conversations/makeFromContext`,
			{ data: formData },
			{
				headers: {
					Accept: 'application/vnd.api+json'
				}
			}
		)
		const parsed = new SingularResponse(
			// @ts-ignore
			undefined,
			response,
			Conversation,
			response.data
		)
		return parsed.getData() as Conversation
	}
}
