
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import { Vessel } from '@/models/Vessel'
import { Attribute } from '@/models/Attribute'
import { Company } from '@/models/Company'
import { formatPhoneNumber } from '@/utils/phoneNumber'
import AttributeType from '@/enums/AttributeType'
import AttributeGroup from '@/enums/AttributeGroup'
import AttributeCategory from '@/enums/AttributeCategory'
import { namespace } from 'vuex-class'
import { VesselDocument } from '@/interfaces/VesselDocument'
import FileDownloadLink from '@/components/FileDownloadLink.vue'

const AttributesStore = namespace('attributes')

@Component({
	computed: {
		AttributeType() {
			return AttributeType
		}
	},
	components: {
		Icon,
		FileDownloadLink
	}
})
export default class VesselContactCard extends Vue {
	@Prop({ required: true })
	vesselId!: string

	@Prop({ required: false, default: true })
	showActions!: boolean

	@AttributesStore.Action('loadAttributes')
	triggerLoad

	@AttributesStore.State('attributes')
	loadedAttributes!: Array<any>

	vessel: Vessel | null = null
	company: Company | null = null
	tab = 'vessel'

	isLoading = true
	isMenuOpen = false

	languages: Array<string> = ['nl', 'en', 'de', 'fr']
	selectedLanguage: string | null = null

	get attributes() {
		return (
			this.loadedAttributes
				// currently don't show uploads
				.filter((attribute) =>
					[
						AttributeType.TEXT,
						AttributeType.DROPDOWN,
						AttributeType.DATETIME,
						AttributeType.NUMBER,
						AttributeType.LONGTEXT
					].includes(attribute.attributeType)
				)
		)
	}

	async created() {
		this.selectedLanguage = this.$i18n?.locale ?? 'nl'
		// load vessel including the vessel attributes
		const vesselQuery = Vessel.option('include', 'vesselAttributes').find(this.vesselId)
		// load all possible attributes for a ship
		const attributesQuery = this.triggerLoad()

		this.vessel = (await vesselQuery).getData() as Vessel
		// load company including relationships
		const companyQuery = this.vessel.company().get()
		await attributesQuery

		this.company = (await companyQuery).getData() as Company
		this.isLoading = false
	}

	get vesselDocument() {
		return new VesselDocument({ data: { vessel: this.vessel!, language: this.selectedLanguage ?? 'nl' } })
	}

	async langSelected(lang: string) {
		this.isMenuOpen = false
		this.selectedLanguage = lang

		await this.$nextTick()
		;(this.$refs['download_' + lang] as FileDownloadLink)[0].$el.click()
	}

	get isDownloading() {
		return this.vesselDocument.isDownloading
	}

	@Watch('vesselDocument', { deep: true })
	vesselDocumentChanged() {
		if (this.vesselDocument.downloadedFile) this.$emit('close')
	}

	getAttributeValue(attribute: Attribute) {
		const filledAttribute = (this.vessel?.getVesselAttributes() ?? []).find(
			(filledAttribute) => filledAttribute.slug === attribute.slug
		)
		let returnValue: string | null = null
		let unit
		if (filledAttribute) {
			if (attribute.attributeType === AttributeType.DROPDOWN) {
				returnValue =
					attribute.getAttributeOptions().find((option) => option.slug === filledAttribute.value)?.value ??
					null
			} else {
				returnValue = (filledAttribute.value ?? null) as string | null
				if (attribute.unit) {
					unit = attribute.unit
				}
			}
		}
		return { value: returnValue, unit: unit }
	}

	findAttribute(slug: string) {
		return this.attributes.find((attribute) => attribute.slug === slug)
	}

	get generalVesselAttributes() {
		const blackList = ['hold_dimensions_in_cubic_meter', 'draught_when_empty']
		return this.attributes
			.filter((attribute) => attribute.group === AttributeGroup.GENERAL)
			.filter((attribute) => !blackList.includes(attribute.slug))
	}

	get holdAttributes() {
		const blackList = ['hold_dimensions_in_cubic_meter', 'hold_dimensions_in_cubic_meter_uncovered']
		return this.attributes
			.filter((attribute) => attribute.group === AttributeGroup.HOLD)
			.filter((attribute) => !blackList.includes(attribute.slug))
	}

	get sustainabilityAttributes() {
		return this.attributes.filter((attribute) => attribute.group === AttributeGroup.SUSTAINABILITY)
	}

	get certificateAttributes() {
		const blackList = ['insurance_valid_until']

		return this.attributes
			.filter((attribute) => attribute.group === AttributeCategory.CERTIFICATE)
			.filter((attribute) => !blackList.includes(attribute.slug))
	}

	get preferencesAttributes() {
		return this.attributes.filter((attribute) => attribute.subCategory === AttributeCategory.PREFERENCES)
	}

	get primaryPhoneNumber() {
		return formatPhoneNumber(this.company?.phoneNumber, this.company?.getPrimaryPhoneCountryCode())
	}
}
