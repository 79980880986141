import Amplify from '@aws-amplify/core'
import { Capacitor } from '@capacitor/core'

export default function (config) {
	Amplify.configure({
		aws_user_pools_id: config.VUE_APP_AWS_USER_POOL_ID,
		aws_user_pools_web_client_id: Capacitor.isNativePlatform()
			? config.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID_MOBILE
			: config.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID,
		Auth: {
			authenticationFlowType: 'USER_PASSWORD_AUTH'
		}
	})
}
