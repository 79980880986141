import { BillOfLadingProduct } from '@/models/BillOfLadingProduct'
import { CargoType } from '@/models/CargoType'
import { CertificationClaimType } from '@/models/CertificationClaimType'
import { Company } from '@/models/Company'
import { CustomLocation } from '@/models/CustomLocation'
import { Downloadable } from '@/interfaces/Downloadable'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { Terminal } from '@/models/Terminal'
import { TerminalVisit } from '@/models/TerminalVisit'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'
import TransshipmentDirection from '@/types/TransshipmentDirection'
import User from '@/models/User'
import { Vessel } from '@/models/Vessel'
import axios from 'axios'

export class BillOfLading extends Model implements Downloadable {
	protected jsonApiType = 'billOfLadings'
	protected static pageSize = 25

	@Property()
	public receiptNumber!: string

	@Property()
	public generatedAt!: string

	@Property()
	public issueLocation!: string

	@Property()
	public extractionArea!: string

	@Property()
	public deliveryMethod!: string

	@Property()
	public extractor!: string

	@Property()
	public loadingPeriodFrom!: string

	@Property()
	public loadingPeriodUntil!: string

	@Property()
	public holdIsOpen!: boolean

	@Property()
	public holdDimensionsInCubicMeter!: string

	@Property()
	public dryPumpUsed!: boolean | null

	@Property()
	public dryPumpHours!: number | null

	@Property()
	public sandIsRinsed!: boolean | null

	@Property()
	public sandRinsedFrom!: string

	@Property()
	public sandRinsedUntil!: string

	@Property()
	public desalinationMethod!: string

	@Property()
	public desalinationWaterUsedInCubicMeter!: string

	@Property()
	public measuredSaltContentFrontHopper!: number | null

	@Property()
	public measuredSaltContentRearHopper!: number | null

	@Property()
	public licenseHolder!: string

	@Property()
	public deedNumber!: string

	@Property()
	public certificateNumber!: string

	@Property()
	public certificateHolder!: string

	@Property()
	public certificationIsBenor!: boolean

	@Property()
	public certificationBenorRevision!: string

	@Property()
	public certificationBenorMarking: string | null = null

	@Property()
	public certificationBenorCertificateNumber: number | null = null

	@Property()
	public certificationIsBSB!: boolean

	@Property()
	public certificationBSBMarking: string | null = null

	@Property()
	public certificationIsCE!: boolean

	@Property()
	public certificationCEMarking!: string

	@Property()
	public certificationIsDOP!: boolean

	@Property()
	public certificationDOPMarking!: string

	@Property()
	public certificationIsBRL!: boolean

	@Property()
	public certificationBRLMarking!: string

	@Property()
	public skipperDeclareCleanHold!: boolean

	@Property()
	public environmentalQuality!: string

	@Property()
	public remark!: string

	@Property()
	public quantity: number | null = null

	@Property()
	public quantityUnit!: string

	@Property()
	public documentType!: TransshipmentDirection

	@Property()
	public skipperName: string | null = null

	@Property()
	public loadingBillReceiptNumber: string | null = null

	@Property()
	public customDestinationLocationGeo!: string | GeoJSON.Point | null

	@Property()
	public customDestinationLocationCountryCode!: string | null

	@Property()
	public customDestinationLocationPlace!: string | null

	@Property()
	public readonly pdfPath!: string | null

	@Property()
	public readonly previewPath!: string

	@Property()
	public readonly createdAt!: Date

	private customDestinationLocation(): ToOneRelation {
		return this.hasOne(CustomLocation, 'customDestinationLocation')
	}

	public getCustomDestinationLocation(): CustomLocation {
		return this.getRelation('customDestinationLocation')
	}

	public setCustomDestinationLocation(customLocation: CustomLocation | null): void {
		return this.setRelation('customDestinationLocation', customLocation)
	}

	private transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport {
		return this.getRelation('transport')
	}

	public setTransport(transport: Transport): void {
		this.setRelation('transport', transport)
	}

	private creator(): ToOneRelation {
		return this.hasOne(User, 'creator')
	}

	public getCreator(): User {
		return this.getRelation('creator')
	}

	private receiver(): ToOneRelation {
		return this.hasOne(Company, 'receiver')
	}

	public getReceiver(): Company {
		return this.getRelation('receiver')
	}

	public setReceiver(receiver: Company) {
		this.setRelation('receiver', receiver)
	}

	private vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.getRelation('vessel')
	}

	public getActiveVessel(): Vessel {
		if (this.getVessel()) return this.getVessel()
		return this.getUnregisteredVessel()
	}

	public setVessel(vessel: Vessel) {
		this.setRelation('vessel', vessel)
	}

	private unregisteredVessel(): ToOneRelation {
		return this.hasOne(Vessel, 'unregisteredVessel')
	}

	public getUnregisteredVessel(): Vessel {
		return this.getRelation('unregisteredVessel')
	}

	public setUnregisteredVessel(vessel: Vessel) {
		this.setRelation('unregisteredVessel', vessel)
	}

	private startLocation(): ToOneRelation {
		return this.hasOne(Terminal, 'startLocation')
	}

	public getStartLocation(): Terminal {
		return this.getRelation('startLocation')
	}

	public setStartLocation(terminal: Terminal) {
		this.setRelation('startLocation', terminal)
	}

	private cargoType(): ToOneRelation {
		return this.hasOne(CargoType, 'cargoType')
	}

	public getCargoType(): CargoType {
		return this.getRelation('cargoType')
	}

	public setCargoType(cargoType: CargoType) {
		this.setRelation('cargoType', cargoType)
	}

	private endLocation(): ToOneRelation {
		return this.hasOne(Terminal, 'endLocation')
	}

	public getEndLocation(): Terminal {
		return this.getRelation('endLocation')
	}

	public setEndLocation(terminal: Terminal) {
		this.setRelation('endLocation', terminal)
	}

	private applicabilityClaim(): ToOneRelation {
		return this.hasOne(CertificationClaimType, 'applicabilityClaim')
	}

	public getApplicabilityClaim(): CertificationClaimType {
		return this.getRelation('applicabilityClaim')
	}

	public setApplicabilityClaim(claim: CertificationClaimType) {
		this.setRelation('applicabilityClaim', claim)
	}

	private billOfLadingProduct(): ToOneRelation {
		return this.hasOne(BillOfLadingProduct, 'billOfLadingProduct')
	}

	public getBillOfLadingProduct(): BillOfLadingProduct {
		return this.getRelation('billOfLadingProduct')
	}

	private terminalVisit(): ToOneRelation {
		return this.hasOne(TerminalVisit, 'terminalVisit')
	}

	public setTerminalVisit(terminalVisit: TerminalVisit): void {
		return this.setRelation('terminalVisit', terminalVisit)
	}

	public isLoading: boolean = false
	public pdfContents: Blob | null = null
	public html: string | null = null
	isDownloading: boolean = false

	setIsDownloading(value: boolean) {
		this.isDownloading = value
	}

	get downloadName() {
		return this.receiptNumber
	}

	get downloadedFile(): Blob | null {
		return this.pdfContents
	}

	private static _maxRetries = 3

	public async triggerDownload(tryNumber: number = 1) {
		// Check if the current tryNumber exceeds the maximum retries per run
		if (tryNumber > BillOfLading._maxRetries) {
			throw new Error('Maximum number of retries exceeded.')
		}

		try {
			const receivedUrl = await this.getSignedUrl()

			if (!receivedUrl) return

			// call the received url to download the document
			const response = await axios.get(receivedUrl, {
				transformRequest: [
					(data, headers) => {
						delete headers.Authorization
						return data
					}
				],
				responseType: 'blob'
			})

			this.pdfContents = response.data as Blob
		} catch (err: any) {
			// retry to get another url when it expired
			if (err.response?.status === 403) {
				await this.triggerDownload(tryNumber++)
			} else {
				throw err
			}
		}
	}

	// call download endpoint to get the url
	private async getSignedUrl() {
		if (!this.pdfPath) return
		const response = await axios.get(this.pdfPath)
		return response.data['signed-uri']
	}

	async fetchPreview() {
		this.isLoading = true
		try {
			const response = await axios.get(this.previewPath, {
				responseType: 'text'
			})
			this.html = response.data as string
		} finally {
			this.isLoading = false
		}
	}
}
