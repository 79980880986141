import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class LockChamber extends Model {
	protected jsonApiType = 'lockChambers'

	@Property('name')
	public name?: string

	@Property('number')
	public number?: number

	@Property('chamberLength')
	public length?: number

	@Property('chamberWidth')
	public width?: number

	@Property('chamberHeight')
	public height?: number

	@Property('maxShipLengthHighTide')
	public maxShipLengthHighTide?: number

	@Property('maxShipLengthLowTide')
	public maxShipLengthLowTide?: number

	@Property('sillDepthBeBu')
	public sillDepthBeBu?: number

	@Property('sillDepthBoBi')
	public sillDepthBoBi?: number

	@Property('note')
	public note?: string

	@Property('referenceLevelInner')
	public referenceLevelInner?: string

	@Property('location')
	public location?: string

	public get surfaceArea(): number {
		return (this.width || 0) * (this.length || 0)
	}
}
