import CachedResourceStore from '@/store/modules/CachedResourceStore'
import { Conversation } from '@/models/Conversation'
import { ResourceLinkObject } from '@/models/Model'

export default new CachedResourceStore<Conversation>(Conversation, {
	namespaced: true,
	mutations: {
		updateUnreadMessages(state, summary: Array<{ amountUnreadMessages; data: ResourceLinkObject }>) {
			state.cache.forEach((cached) => {
				const foundIndex = summary.findIndex(
					(conversationLink) => cached.id === String(conversationLink.data.id)
				)
				if (foundIndex > -1) {
					;(cached.record as Conversation).newMessages = summary[foundIndex].amountUnreadMessages
				} else {
					// if not passed, it is 0
					;(cached.record as Conversation).newMessages = 0
				}
			})
		}
	},
	actions: {
		async lazyLoad({ commit, getters }, recordIds: Array<string>) {
			const toLoad = recordIds.filter((id) => !getters.peekRecord(id))
			const queries = toLoad.map((id) => Conversation.find(id))
			const records = (await Promise.all(queries)).map((singluar) => singluar.getData()) as Array<Conversation>
			records.forEach((record) => commit('cacheOne', { record, cacheKey: record.getApiId() }))
		}
	}
})
