import './plugins/veevalidate'
import './plugins/vuetify'
import './plugins/vue-gtm'
import 'moment-duration-format'

import globalAxios, { AxiosError, AxiosRequestConfig } from 'axios'

import App from './App.vue'
import { Auth } from '@aws-amplify/auth'
import { AxiosHttpClient } from 'coloquent/dist/httpclient/axios/AxiosHttpClient'
import { Model } from 'coloquent'
import Notification from '@/classes/Notification'
import Vue from 'vue'
import i18n from './i18n'
import initializeAmplify from './plugins/amplify'
import initializeCloudFrontLogger from './plugins/cloudfront-logger'
// @ts-ignore
import localization from 'moment/locale/nl'
import moment from 'moment'
import router from './router'
import { store } from '@/store'
import { v4 as uuidv4 } from 'uuid'

moment.locale('nl', localization)

initializeAmplify(process.env)
let logger
if (!window.localStorage.getItem('isTesting')) {
	logger = initializeCloudFrontLogger(process.env)
}
Vue.config.productionTip = false

const axiosClient = new AxiosHttpClient()
const modelAxios = axiosClient.getImplementingClient()

modelAxios.interceptors.request.use(addTraceId)
globalAxios.interceptors.request.use(addTraceId)
modelAxios.interceptors.request.use(addAuthHeader)
globalAxios.interceptors.request.use(addAuthHeader)
modelAxios.interceptors.response.use((response) => response, handleAxiosErrors)
modelAxios.interceptors.response.use((response) => response, refreshToken)
globalAxios.interceptors.response.use((response) => response, handleAxiosErrors)
globalAxios.interceptors.response.use((response) => response, refreshToken)

modelAxios.defaults.headers['Accept-Language'] = i18n.locale

async function addAuthHeader(config) {
	try {
		const currentSession = await Auth.currentSession()
		config.headers.Authorization = `Bearer ${currentSession.getAccessToken().getJwtToken()}`

		return config
	} catch (e) {
		// skip two cognito exceptions
		if (e !== 'No current user' && (e as any).code !== 'NotAuthorizedException') {
			throw e
		}
		return config
	}
}
async function addTraceId(req) {
	if (!req) return req
	req.headers['X-4S-Trace-Id'] = uuidv4()

	return req
}
async function refreshToken(error: AxiosError): Promise<any> {
	const originalRequest = error.config

	if (
		!error.response ||
		error.response.status !== 401 ||
		// @ts-ignore
		originalRequest._retry
	) {
		if (error.response?.status === 401) {
			// after retry still 401, logged out by cognito, redirect to login page
			await router.push({ name: 'login', query: { url: window.location.pathname } })
		}
		return Promise.reject(error)
	}

	// @ts-ignore
	originalRequest._retry = true

	const currentSession = await Auth.currentSession()

	if (currentSession.getAccessToken() && currentSession.getAccessToken().getJwtToken()) {
		Model.getHttpClient().getImplementingClient().defaults.headers.Authorization = `Bearer ${currentSession
			.getAccessToken()
			.getJwtToken()}`
		// @ts-ignore
		originalRequest.headers = originalRequest.headers.toJSON()
		// @ts-ignore
		globalAxios.defaults.headers.Authorization = `Bearer ${currentSession.getAccessToken().getJwtToken()}`
		// @ts-ignore
		originalRequest.headers.Authorization = `Bearer ${currentSession.getAccessToken().getJwtToken()}`

		return globalAxios(originalRequest as AxiosRequestConfig)
	}

	await Notification.Error(vue.$t('errors.unknown').toString())
	return Promise.reject(error)
}

async function handleAxiosErrors(error: AxiosError): Promise<AxiosError> {
	if (!error.response) {
		await Notification.Error(vue.$t('errors.generic').toString())
	} else if (error.response.status === 500) {
		await Notification.Error(vue.$t('errors.unknown').toString())
	} else if (error.response.status === 400) {
		let errorData = error.response.data as any

		if (errorData instanceof Blob) {
			errorData = JSON.parse(await errorData.text())
		}

		// Temporary fix for cognito errors; should be reformatted in backend
		if (
			Object.prototype.hasOwnProperty.call(errorData, 'data') &&
			!Object.prototype.hasOwnProperty.call(errorData, 'errors')
		) {
			errorData = errorData.data
		}

		if (errorData?.errors?.[0].length) {
			await Notification.Error(
				`<p>${i18n.t('errors.form_errors')}</p><ul><li>${errorData.errors[0]
					.map((error) => error.title)
					.join('</li><li>')}</li></ul>`
			)
		}
	} else if (error.response.status === 403) {
		await router.replace({ name: 'forbidden' })
	} else if (error.response.status === 498) {
		// TODO: handle 498 (Unauthorized from remote API like exact-online)
	}
	logErrorToCloudWatch(error)
	return Promise.reject(error)
}

function logErrorToCloudWatch(error: AxiosError) {
	// skip 401 because these can be ignored in logs
	if (error.response?.status === 401) {
		return
	}
	// prevent sending authorization headers to logging services
	if (error.config) {
		error.config.headers!.Authorization = '~~~ REDACTED ~~~'
	}
	logger?.onError(new Error('Non 2xx response from backend'), {
		traceId: error.config?.headers!['X-4S-Trace-Id'],
		error: {
			status: error.response?.status,
			message: error.message,
			name: error.name,
			stack: error.stack,
			config: {
				headers: error.config?.headers,
				baseUrl: error.config?.baseURL,
				method: error.config?.method,
				url: error.config?.url
			}
		}
	})
}

Model.setHttpClient(axiosClient)

const vue = new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')

Vue.config.errorHandler = (error, vm, info) => {
	// @ts-ignore
	if (window.Cypress && window.top) {
		// @ts-ignore
		window.top.console.error(error)
	}

	if (!window.localStorage.getItem('isTesting')) {
		// eslint-disable-next-line
		console.error(error)
		logger.onError(error)
	}

	if (error.message?.includes('ChunkLoadError')) {
		const reload = window.confirm(i18n.t('errors.chunkLoadError').toString())
		if (reload) {
			window.location.reload()
		}
	}
	Notification.Error(vue.$t('errors.generic', { error: error.message }).toString())
}

window.onerror = function (msg, file, line, col, e) {
	if (!window.localStorage.getItem('isTesting')) {
		logger.onError(e)
	}
}

window.onresize = () => {
	store.commit('viewport/setWidth', window.innerWidth)
}

if (!window.localStorage.getItem('isTesting')) {
	// @ts-ignore
	const OneSignal = window.OneSignal || []
	// eslint-disable-next-line no-unused-expressions
	if (OneSignal.push) {
		OneSignal.push(function () {
			OneSignal.init({
				appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
				notifyButton: {
					enable: false
				},
				allowLocalhostAsSecureOrigin: true
			})
		})
	}
}
