import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class FacilityAdministration extends Model {
	protected jsonApiType = 'facilityAdministrations'

	@Property('name')
	public name!: string

	@Property('visitingAddress')
	public address!: string

	@Property('visitingCity')
	public city!: string

	@Property('phoneNumber')
	public phoneNumber!: string
}
