
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavigationItem } from '@/interfaces/NavigationItem'
import Icon from '@/components/Icon.vue'
import NewMessagesBadge from '@/components/NewMessagesBadge.vue'

@Component({
	components: {
		Icon,
		NewMessagesBadge
	}
})
export default class MobileNavigationItem extends Vue {
	@Prop({ required: true })
	navigationItem!: NavigationItem
}
