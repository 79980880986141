import { Model } from '@/models/Model'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'
import moment from 'moment'

export class AisVessel extends Model implements MappableModel {
	protected jsonApiType = 'aisVessels'

	public getLocation(): GeoJSON.Geometry {
		return this.geometry
	}

	public getName(): string {
		return this.name
	}

	public getLastSeen(): string {
		return moment(this.dateTimeLastSeen).format('dd DD MMM - HH:mm')
	}

	@Property()
	public name!: string

	@Property()
	public geometry!: GeoJSON.Geometry

	@Property()
	public eni!: string

	@Property()
	public lengthInMeters!: number

	@Property()
	public speedInKilometers!: number

	@Property()
	public vesselTypeDetailed!: string

	@Property()
	public vesselTypeGeneric!: string

	@Property()
	public widthInMeters!: number

	@Property()
	public dateTimeLastSeen!: string
}
